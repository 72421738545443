import React from "react";

import { Row, Col, Card, Button } from "antd";
import ManageModal from "./ManageModal";
import LineTable from "./Datatable";
import { toast } from "react-toastify";
import { lineService } from "../../../../Service/line.services";
import DeleteModal from "../../../../Component/Modal/DeleteModal";
import { wagService } from "../../../../Service/wag.services";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalDelete = React.createRef();
  }
  state = {
    data: null,
    reload: false,
    isLoading: false,
  };

  toggleModalManage = (data) => {
    if (data) {
      this.setState({ data: data, reload: false });
    } else {
      this.setState({ data: null, reload: false });
    }

    this._modalManage.current.toggleModal();
  };

  toggleModalDelete = () => {
    this._modalDelete.current.toggleModal();
  };

  edit = (data) => {
    this.toggleModalManage(data);
  };

  delete = (data) => {
    this.setState({ data: data, reload: false });
    this.toggleModalDelete();
  };

  deleteSchedule = (id) => {
    wagService.deleteLinks(id).then(
      (resp) => {
        this.toggleModalDelete();
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
        this.setState({ data: null, reload: true, isLoading: false });
      },
      (err) => {
        this.toggleModalDelete();
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };
  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({ data: null, reload: true });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };
  render() {
    return (
      <React.Fragment>
        <Card
          style={{
            
            height: "100%",
            marginTop: "2%",
          }}
        >
          <Row gutter={[16]}>
            <Col style={{ marginTop: 16 }} xs={24} sm={24} md={19} lg={19}>
              <p className="font-medium-1 text-bold-700">Grup WhatsApp</p>
            </Col>
            <Col style={{ marginTop: 16 }} xs={24} sm={24} md={5} lg={5}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={(e) => this.toggleModalManage(null)}
              >
                Tambah Grup
              </Button>
            </Col>
          </Row>
          <LineTable
            edit={this.edit}
            reload={this.state.reload}
            delete={this.delete}
          />
        </Card>
        <ManageModal
          data={this.state.data}
          ref={this._modalManage}
          onUpdated={this.onUpdated}
        />

        <DeleteModal
          isLoading={this.state.isLoading}
          ref={this._modalDelete}
          id={this.state.data ? this.state.data.id : -1}
          name={this.state.data ? this.state.data.link : ""}
          onDeleted={this.deleteSchedule}
        />
      </React.Fragment>
    );
  }
}

export default Index;
