import React from "react";

import { Menu, Dropdown, Space } from "antd";
import {
  DownOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { history } from "../../../../History";

class ActionParticipants extends React.Component {
  state = {
    user: this.props.user,
  };

  deleteUser = () => {
    this.props.delete(this.state.user);
  };

  editUser = () => {
    history.push({
      pathname: "/profile/admin/participant/edit",
      state: {
        data: this.state.user,
      },
    });
  };

  downloadFile = () => {
    this.props.download(this.state.user);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }
  }

  render() {
    return (
      <Space wrap>
        <Dropdown.Button
          onClick={(e) => this.editUser(e)}
          placement="bottomCenter"
          icon={<DownOutlined />}
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                icon={<DeleteOutlined />}
                onClick={(e) => this.deleteUser(e)}
              >
                Hapus
              </Menu.Item>
            </Menu>
          }
        >
          Lihat Detail
        </Dropdown.Button>
      </Space>
    );
  }
}

export default ActionParticipants;
