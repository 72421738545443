import React from "react";
import {
  Card,
  Row,
  Col,
  Button,
  DatePicker,
  Input,
  Image,
  Tooltip,
  Divider,
  Typography,
} from "antd";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import ImageDropzone from "../../../Component/File/ImageDropzone";
import moment from "moment";
import "moment/locale/id";
import ReactToPrint from "react-to-print";
import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { participantService } from "../../../Service/participant.service";
import { paymentService } from "../../../Service/payment.service";
import { SettingService } from "../../../Service/setting.services";
import { mainConfig } from "../../../Config/mainConfig";
import "../../../App.css";
import { NumericFormat } from "react-number-format";
import QRCode from "react-qr-code";
import dayjs from "dayjs";

class PrintComponent extends React.Component {
  constructor(props) {
    super(props);
    this._infoModal = React.createRef();
  }

  state = {
    user_id: 0,
    data: [],
    year: "",
  };

  componentDidMount() {
    var date = new Date();
    var convert = moment(date, "DD/MM/YYYY").year();
    this.setState({ year: convert });
    window.scrollTo(0, 0);
    let userData = localStorage.getItem("USER_DATA");
    userData = JSON.parse(userData);
    this.setState({ user_id: userData.id });

    paymentService.getPaymentsByUser(userData.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            data: resp.data,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="print-source">
          <Card
          // style={{
          //   width: "794px",
          //   height: "100%",
          //   marginLeft: "1%",
          //   marginRight: "1%",
          //   marginTop: "1%",
          //   marginBottom: "1%",
          //   paddingBottom: 0,
          // }}
          >
            <Row align="middle" gutter={[16]}>
              <Col span={4} style={{ textAlign: "right", marginBottom: 16 }}>
                <img
                  src={
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    "/logomts.png"
                  }
                  style={{ width: "45%" }}
                  alt="Logo Matsanela"
                />
              </Col>
              <Col span={14}>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "9pt",
                    fontWeight: "bold",
                    marginLeft: "3%",
                  }}
                >
                  Bukti Pembayaran PPDB <br />
                  MTs Negeri 1 Lamongan <br />
                  {this.state.year}
                </p>
              </Col>
              {/* <Col span={6} style={{ textAlign: "left" }}>
                <Barcode
                  width={1}
                  height={40}
                  displayValue={false}
                  value={this.state.data && this.state.data.registration_number}
                />
              </Col> */}
            </Row>
            <Divider
              style={{
                borderWidth: "3px",
                borderColor: "#000000",
                margin: 0,
              }}
            ></Divider>
            <Row style={{ textAlign: "left", marginTop: "4%" }}>
              <Col span={6}>
                <label>NISN</label>
              </Col>
              <Col span={18}>
                <Input value={this.state && this.state.data.nisn} />
              </Col>
            </Row>
            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={6}>
                <label>Nama</label>
              </Col>
              <Col span={18}>
                <Input value={this.state && this.state.data.user_name} />
              </Col>
            </Row>
            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={6}>
                <label>Tanggal Pembayaran</label>
              </Col>
              <Col span={18}>
                <Input value={this.state && this.state.data.pay_date} />
              </Col>
            </Row>
            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={6}>
                <label>Jumlah Dibayar</label>
              </Col>
              <Col span={18}>
                <Input
                  value={
                    this.state &&
                    parseInt(this.state.data.amount).toLocaleString()
                  }
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={6}>
                <label>Nama Pengirim</label>
              </Col>
              <Col span={18}>
                <Input value={this.state && this.state.data.name} />
              </Col>
            </Row>
            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={6}>
                <label>Nomor Referensi</label>
              </Col>
              <Col span={18}>
                <Input value={this.state && this.state.data.ref_no} />
              </Col>
            </Row>
            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={6}>
                <label>Sisa Pembayaran</label>
              </Col>
              <Col span={18}>
                <Input
                  value={
                    this.state && parseInt(this.state.data.due) === 0
                      ? "Lunas"
                      : this.state.data.due
                        ? parseInt(this.state.data.due).toLocaleString()
                        : "Belum Lunas"
                  }
                />
              </Col>
            </Row>
            {/* <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={6}>
                <label>Link Grup Whatsapp</label>
              </Col>
              <Col span={18}>
                <QRCode size={160} value={this.state.schoolStudentLink} />
                <br />
                <Link className="font-normal" href={this.state.schoolStudentLink}>
                  {this.state.schoolStudentLink}
                </Link>
              </Col>
            </Row> */}
            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={6}>
                <label>Catatan</label>
              </Col>
              <Col span={18}>
                <Input
                  value={this.state.data.note ?? ""}
                />
              </Col>
            </Row>

            <Row justify="center">
              <Col style={{ marginTop: "4%" }}>
                <img
                  style={{
                    width: "400px",
                    height: "400px",
                  }}
                  src={`${mainConfig.apiUrl}/payment/image/${this.state.data?.id}`}
                  alt={this.state?.data?.offline_approve ? "Diverifikas Offline" : "Bukti Pembayaran"}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const { Link } = Typography;
class Register extends React.Component {
  constructor(props) {
    super(props);
    this._infoModal = React.createRef();
  }

  state = {
    pay_date: "",
    amount: "",
    checked: false,
    downloaded: false,
    name: "",
    noRef: "",
    body: {
      image: "",
      user_id: 0,
    },
    payment_status: "",
    payment_description: "",
    payment: [],
    image: [],
    settings: [],
    isLoading: false,
    user: [],
    progress: 1,
    due: "",
    note: null,
    schoolStudentLink: "",
    mahadStudentLink: "",
    virtualAccount: "",
  };

  handleClick = (e) => {
    window.open(this.state.settings.payment_document ?? "");
    this.setState({ downloaded: true });
  };

  copyVA = () => {
    copy(this.state?.virtualAccount ?? '');
    toast.success("Nomor VA berhasil disalin!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  copyRekening = () => {
    copy(this.state?.settings?.payment_account ?? '');
    toast.success("Nomor Rekening berhasil disalin!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };
  onChange(date, dateString) {
    let startDate = dateString;
    this.setState({ pay_date: startDate });
  }

  dropImage = (files) => {
    let body = this.state.body;
    body.image = files[0];
    this.setState({ body });
  };
  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    let user_id = this.state.user_id;
    let body = this.state.body;
    body["user_id"] = user_id;
    body["pay_date"] = this.state.pay_date;
    body["name"] = this.state.name;
    body["amount"] = this.state.amount;
    body["ref_no"] = this.state.noRef;
    e.preventDefault();
    paymentService.createPayment(body).then(
      (resp) => {
        this.setState({ description: "", title: "", isLoading: false });
        toast.success(
          "Pembayaran berhasil ditambahkan, harap cek kembali status pembayaran 1x24 jam, jika terkonfirmasi kamu bisa cetak bukti pembayaran disini",
          {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 7000,
          }
        );
        setTimeout(function () {
          window.location.reload();
        }, 7000);
      },
      (error) => {
        this.setState({ description: "", title: "", isLoading: false });
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };

  componentDidMount() {
    let userData = localStorage.getItem("USER_DATA");
    userData = JSON.parse(userData);
    this.setState({ user_id: userData.id });

    paymentService.getPaymentsByUser(userData.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            payment: resp.data,
            payment_status: resp.data.payment_status,
            name: resp.data.name ?? "",
            amount: resp.data.amount,
            noRef: resp.data.ref_no ?? "",
            pay_date: resp.data.pay_date,
            payment_description: resp.data.payment_description,
            due: resp.data.due,
            note: resp.data.note,
            isLoading: false,
            virtualAccount: resp.data.virtual_account,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );

    SettingService.getSetting().then(
      (resp) => {
        if (resp.status) {
          this.setState({
            settings: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );

    participantService.getUser(userData.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            user: resp.data,
            total: resp.total,
            schoolStudentLink: resp.data.school_student_group_link ?? "",
            mahadStudentLink: resp.data.mahad_student_group_link ?? "",
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );

    participantService.getProgress(userData.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            progress: resp.data.progress,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }
  changeCheck(e) {
    this.setState({ checked: e.target.checked });
  }

  render() {
    if (this.state.progress <= 3) {
      return (
        <React.Fragment>
          <div
            style={{
              textAlign: "center",
              marginTop: 32,
            }}
          >
            <p className="font-normal">
              Halaman akan terbuka ketika kamu diterima
            </p>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Row gutter={[24]}>
            <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
              <Card
                className="font-medium-1"
                style={{
                  borderRadius: "5px",
                  borderColor:
                    this.state.payment_status === "due" ||
                      !this.state.payment_status
                      ? "#ec4646"
                      : "",
                  color:
                    this.state.payment_status === "due" ||
                      !this.state.payment_status
                      ? "#ec4646"
                      : "black",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  padding: "4% 10% 4% 10%",
                }}
              >
                Belum Dibayar
                <CloseCircleOutlined
                  style={{ marginLeft: "6%", verticalAlign: "middle" }}
                />
              </Card>
            </Col>
            <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
              <Card
                className="font-medium-1"
                style={{
                  borderRadius: "5px",
                  borderColor:
                    this.state.payment_status === "waiting" ? "#ffcc29" : "",
                  color:
                    this.state.payment_status === "waiting"
                      ? "#ffcc29"
                      : "black",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  padding: "4% 10% 4% 10%",
                }}
              >
                Menunggu Konfirmasi
                <InfoCircleOutlined
                  style={{ marginLeft: "6%", verticalAlign: "middle" }}
                />
              </Card>
            </Col>
            <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
              <Card
                className="font-medium-1"
                style={{
                  borderRadius: "5px",
                  borderColor:
                    this.state.payment_status === "paid" ? "#00af91" : "",
                  color:
                    this.state.payment_status === "paid" ? "#00af91" : "black",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  padding: "4% 12% 4% 10%",
                }}
              >
                Terbayar
                <CheckCircleOutlined
                  style={{ marginLeft: "6%", verticalAlign: "middle" }}
                />
              </Card>
            </Col>
          </Row>

          <div>
            <Card
              style={{
                marginTop: 24,
                borderRadius: "5px",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Row style={{ marginTop: 16, display: this.state.schoolStudentLink ? "flex" : "none" }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Grup Whatsapp Diterima PPDB</p>
                </Col>
                <Col>
                  <p className="font-normal">
                    Scan Untuk Bergabung Grup Whatsapp Diterima Seleksi PPDB
                  </p>
                  <QRCode size={160} value={this.state.schoolStudentLink} />
                  <br />
                  <span className="font-normal">Link: </span>{" "}
                  <Link
                    className="font-normal"
                    href={this.state.schoolStudentLink}
                  >
                    {this.state.schoolStudentLink}
                  </Link>
                  <Divider style={{ borderColor: "#bebebe" }} />
                </Col>
              </Row>
              <Row style={{ marginTop: 16, display: this.state.mahadStudentLink ? "flex" : "none" }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Grup Whatsapp Diterima Mahad</p>
                </Col>
                <Col >
                  <p className="font-normal">
                    Scan Untuk Bergabung Grup Whatsapp Diterima Seleksi Mahad
                  </p>
                  <QRCode size={160} value={this.state.mahadStudentLink} />
                  <br />
                  <span className="font-normal">Link: </span>{" "}
                  <Link
                    className="font-normal"
                    href={this.state.mahadStudentLink}
                  >
                    {this.state.mahadStudentLink}
                  </Link>
                  <Divider style={{ borderColor: "#bebebe" }} />
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Petunjuk Pembayaran </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <p className="font-normal">
                    {this.state.settings.payment_description ?? "-"}
                  </p>
                </Col>
              </Row>

              <Row style={{ marginTop: 16, display: this.state.settings.payment_account ? "flex" : "none" }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Transfer ke </p>
                </Col>
                <Col xs={16} sm={12} md={12} lg={6} style={{display: "flex", alignItems:"center"}}>
                  <p
                    className="font-normal text-bold-700"
                    style={{ display: "inline-block", textAlign: "left" }}
                  >
                    {(this.state.settings.payment_bank ?? "-") +
                      "  " +
                      (this.state.settings.payment_account ?? "-")}
                    <br />{" "}
                    {"atas nama " + (this.state.settings.payment_name ?? "-")}
                  </p>
                </Col>
                <Col xs={8} sm={6} md={6} lg={12} style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    onClick={this.copyRekening}
                    type="primary"
                    style={{ display: this.state?.settings?.payment_account ? 'flex' : "none" }}
                  >
                    Salin
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: 16, display: this.state.virtualAccount ? "flex" : "none" }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">Virtual Account</p>
                </Col>
                <Col xs={20} sm={6} md={6} lg={6} style={{display: "flex", alignItems:"center"}}>
                  <p className="font-normal">{this.state.virtualAccount}</p>
                </Col>
                <Col xs={4} sm={6} md={6} lg={6} style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    onClick={this.copyVA}
                    type="primary"
                    style={{ display: this.state?.virtualAccount ? 'block' : "none" }}
                  >
                    Salin
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Tanggal Pembayaran <span style={{ color: "red" }}>
                      {" "}
                      *
                    </span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} style={{display: "flex", alignItems:"center"}}>
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Tanggal Pembayaran"
                    onChange={(date, dateString) =>
                      this.onChange(date, dateString)
                    }
                    value={
                      this.state.pay_date ? dayjs(this.state.pay_date) : ""
                    }
                    disabled={
                      this.state.payment_status === "due" ||
                        !this.state.payment_status
                        ? false
                        : true
                    }
                  ></DatePicker>
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Jumlah Dibayar<span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} style={{display: "flex", alignItems:"center"}}>
                  <NumericFormat
                    placeholder="Jumlah Dibayar"
                    thousandSeparator={true}
                    onValueChange={(e) =>
                      this.setState({
                        amount: e.floatValue ? e.floatValue : 0,
                      })
                    }
                    value={this.state.amount}
                    customInput={Input}
                    disabled={
                      this.state.payment_status === "due" ||
                        !this.state.payment_status
                        ? false
                        : true
                    }
                  />
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: 16,
                  display: !this.state.due ? "none" : "flex",
                }}
                gutter={[16]}
              >
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Sisa Pembayaran </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <p className="font-normal">
                    {this.state && parseInt(this.state.due) === 0
                      ? "Lunas"
                      : parseInt(this.state.due).toLocaleString()}
                  </p>
                </Col>
              </Row>

              <Row
                style={{
                  marginTop: 16,
                  display: !this.state.note ? "none" : "flex",
                }}
                gutter={[16]}
              >
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">Catatan</p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                <p className="font-normal">{this.state.note ?? ""}</p>
                </Col>
              </Row>
              
              

              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Nama Pengirim</p>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Nama Pengirim"
                    onChange={(e) =>
                      this.setState({ name: e.target.value ?? "" })
                    }
                    value={this.state.name ?? ""}
                    disabled={
                      this.state.payment_status === "due" ||
                        !this.state.payment_status
                        ? false
                        : true
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Nomor Referensi</p>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    name="noRef"
                    type="text"
                    placeholder="Nomor Referensi"
                    onChange={(e) =>
                      this.setState({ noRef: e.target.value ?? "" })
                    }
                    value={this.state.noRef ?? ""}
                    disabled={
                      this.state.payment_status === "due" ||
                        !this.state.payment_status
                        ? false
                        : true
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Bukti Pembayaran <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col >
                {this.state.payment_status === "due" ||
                  !this.state.payment_status ? (
                  <>
                    <Col>
                      <ImageDropzone onDropped={this.dropImage} />
                      <p
                        className="font-small-2"
                        style={{
                          color: "red",
                          marginLeft: "2%",
                        }}
                      >
                        Ukuran Foto maks 2 MB
                      </p>
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Image
                      style={{ width: 160, height: 160 }}
                      src={`${mainConfig.apiUrl}/payment/image/${this.state.payment.id}`}
                      alt={this.state?.payment?.offline_approve ? "Diverifikas Offline" : "Bukti Pembayaran"}
                    />
                  </Col>
                )}
              </Row>
              {/* <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Dokumen Pernyataan </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18}>
                  <Button onClick={this.handleClick} type="primary">
                    Unduh Dokumen Pernyataan
                  </Button>
                </Col>
              </Row> */}

              {/* <Row gutter={[16]} style={{ marginTop: "12px" }}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Checkbox
                    disabled={
                      this.payment_status === "due" || !this.payment_status
                        ? this.state.downloaded
                          ? false
                          : true
                        : true
                    }
                    className="font-normal"
                    style={{
                      color: this.state.checked === false ? "red" : "black",
                    }}
                    onChange={(e) => this.changeCheck(e)}
                  >
                    <span>
                      Dengan ini, saya telah membaca dan setuju dengan ketentuan
                      di atas
                    </span>
                  </Checkbox>
                </Col>
              </Row> */}
              <Row style={{ marginTop: 16, float: "right" }} gutter={[16, 16]}>
                <Col>
                  {this.state.payment_status !== "paid" ? (
                    <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                      <Button
                        type="primary"
                        disabled={this.state.payment_status !== "paid"}
                        style={{
                          marginLeft: "2%",
                          borderRadius: "5%",
                        }}
                      >
                        Cetak Bukti Pembayaran
                      </Button>
                    </Tooltip>
                  ) : (
                    <>
                      <ReactToPrint
                        pageStyle="
                      @page {
                        size: a5;
                        margin: 0;
                      }
                      "
                        trigger={
                          () => (
                            <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                              <Button
                                type="primary"
                                disabled={this.state.payment_status !== "paid"}
                                style={{
                                  marginLeft: "2%",
                                  borderRadius: "5%",
                                }}
                              >
                                Cetak Bukti Pembayaran
                              </Button>
                            </Tooltip>
                          )}
                        content={() => this.componentRef}
                      />
                      <PrintComponent ref={(el) => (this.componentRef = el)} />
                    </>
                  )}
                </Col>
                <Col>
                  <Tooltip
                    title={
                      this.state.progress <= 3
                        ? "Kamu belum lolos!"
                        : this.state.payment_status === "paid"
                          ? "Kamu sudah melakukan pembayaran!"
                          : ""
                    }
                  >
                    <Button
                      loading={this.state.isLoading}
                      type="primary"
                      disabled={
                        this.state.payment_status === "paid" ||
                        this.state.prograse <= 3 ||
                        !this.state.body.image ||
                        !this.state.pay_date ||
                        !this.state.amount
                        // || this.state.checked === false
                      }
                      style={{
                        marginLeft: "2%",
                        borderRadius: "5%",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Konfirmasi Pembayaran
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Card>
          </div>
        </React.Fragment>
      );
    }
  }
}
export default Register;
