import { mainConfig } from "../../../Config/mainConfig";
import * as FileSaver from 'file-saver';
import React from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  Card,
  Tooltip,
  Input,
  Divider,
} from "antd";
import { participantService } from "../../../Service/participant.service";
import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { toast } from "react-toastify";
import * as MimeDB from "mime-db";
import { history } from "../../../History";
class ManageModal extends React.Component {
  state = {
    data: this.props.status,
    isOpen: false,
    isLoading: false,
    loading: false,
    pay_date: "",
    noRef: "",
    user_name: "",
    nisn: "",
    name: "",
    school: "",
    city: "",
    district: "",
    payment_status: "",
    user_id: 0,
    line_id: 0,
    additional_text: "",
    requirement_text: "",
    file_status: "",
    file_id: 0,
    is_add_uploaded: "",
    is_req_uploaded: "",
    year: "",
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  handleConfirm = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    participantService
      .updateStatus(this.state.file_id, {
        is_confirmed: true,
      })
      .then(
        (resp) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
          });
          this.props.onUpdated(resp);
        },
        (error) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
          });
          this.props.onUpdated({
            isUpdated: true,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  handleCanceled = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    participantService
      .updateStatus(this.state.file_id, {
        is_confirmed: false,
      })
      .then(
        (resp) => {
          this.toggleModal();
          this.setState({
            loading: false,
            data: null,
          });
          this.props.onUpdated(resp);
        },
        (error) => {
          this.toggleModal();
          this.setState({
            loading: false,
            data: null,
          });
          this.props.onUpdated({
            isUpdated: true,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.status !== this.props.status) {
      participantService.getBiodata(this.props.status.user_id).then((resp) => {
        this.setState({
          school: resp.data.school,
          city: resp.data.city,
          district: resp.data.district,
        });
      });
      if (this.props.status) {
        this.setState({
          isLoading: false,
          loading: false,
          file_id: this.props.status.file_id,
          data: this.props.status,
          additional_text: this.props.status.additional_text,
          requirement_text: this.props.status.requirement_text,
          file_status: this.props.status.file_status,
          is_add_uploaded: this.props.status.is_add_uploaded,
          is_req_uploaded: this.props.status.is_req_uploaded,
          user_id: this.props.status.user_id,
          line_id: this.props.status.line_id,
        });
      } else {
        this.setState({
          loading: false,
          isLoading: false,
          data: null,
          name: "",
          content: "",
        });
      }
    }
  }

  handleDownloadReq = (e) => {
    this.setState({isLoading: true})
    participantService.downloadFile(this.state.data.user_id, this.state.data.line_id, 'requirement_file').then(
      async (resp) => {
        FileSaver.saveAs(resp, `Berkas ${this.state.data.user_name}.${MimeDB[resp.type]?.extensions?.[0]}`);
        this.setState({
          isLoading: false
        });
      },
      (error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: true,
          autoClose: 2000,
        });
        this.setState({
          isLoading: false
        });
      }
    );
  };

  handlePrintCard = () => {
    history.push({
      pathname: "/profile/card",
      state: {
        user_id: this.state.data.user_id,
      }
    });
  }

  componentDidMount() {
    var date = new Date();
    var convert = moment(date, "DD/MM/YYYY").year();
    this.setState({ year: convert });
  }
  render() {
    let classTypes = {
      reguler: 'Reguler',
      superior: 'Unggulan',
      excelent: 'Excelent (Tahfidz)'
    }

    return (
      <Modal
        title="Konfirmasi Berkas"
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        width={800}
        footer={[
          <>
            <Row gutter={[4, 16]}>
              <Col xs={24} sm={8} md={8} lg={8}>
                <Button
                  type="primary"
                  danger
                  key="back"
                  onClick={this.handleCanceled}
                  disabled={this.state.file_status === "not-uploaded"}
                  loading={this.state.loading}
                  style={{ width: "100%" }}
                >
                  Tolak Berkas
                </Button>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8}>
                <Button
                  key="submit"
                  type="primary"
                  disabled={this.state.file_status !== "waiting"}
                  onClick={this.handleConfirm}
                  style={{ width: "100%" }}
                >
                  Konfirmasi Berkas
                </Button>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8}>
                <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                  <Button
                    type="primary"
                    disabled={this.state.file_status !== "verified"}
                    onClick={this.handlePrintCard}
                    style={{ width: "100%" }}
                  >
                    Cetak Kartu Peserta
                  </Button>
                </Tooltip>
                {/* <ReactToPrint
                pageStyle="
                @page {
                  size: a5;
                  margin: 0;
                }
                "
                  trigger={() => (
                    <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                      <Button
                        disabled={this.state.file_status === "not-uploaded"}
                        type="primary"
                        style={{
                          width: "100%",
                        }}
                      >
                        Cetak Kartu Peserta
                      </Button>
                    </Tooltip>
                  )}
                  content={() => this.componentRef}
                />
                <div
                  className="print-source"
                  ref={(el) => (this.componentRef = el)}
                >
                  <React.Fragment>
                    <div className="print-source">
                      <Card
                      >
                        <Row align="middle" gutter={[16]}>
                          <Col
                            span={4}
                            style={{ textAlign: "right", marginBottom: 16 }}
                          >
                            <img
                              src={
                                window.location.protocol +
                                "//" +
                                window.location.host +
                                "/logomts.png"
                              }
                              style={{ width: "45%" }}
                              alt=""
                            />
                          </Col>
                          <Col span={14}>
                            <p
                              style={{
                                textAlign: "left",
                                fontSize: "9pt",
                                fontWeight: "bold",
                                marginLeft: "3%",
                              }}
                            >
                              KARTU PESERTA PPDB <br />
                              MTs Negeri 1 Lamongan <br />
                              {this.state.year}
                            </p>
                          </Col>
                        </Row>
                        <Divider
                          style={{
                            borderWidth: "3px",
                            borderColor: "#000000",
                            margin: 0,
                          }}
                        ></Divider>
                        <Row>
                          <Col
                            span={20}
                            style={{
                              textAlign: "left",
                              marginTop: "48px",
                            }}
                          >
                            <Row>
                              <Col span={6}>
                                <label>NISN</label>
                              </Col>
                              <Col span={17}>
                                <Input
                                  value={
                                    this.state.data && this.state.data.nisn
                                  }
                                />
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "2%" }}>
                              <Col span={6}>
                                <label>Nama</label>
                              </Col>
                              <Col span={17}>
                                <Input
                                  value={
                                    this.state.data && this.state.data.user_name
                                  }
                                />
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "2%" }}>
                              <Col span={6}>
                                <label>Nomor Peserta</label>
                              </Col>
                              <Col span={17}>
                                <Input
                                  value={
                                    this.state.data &&
                                    this.state.data.registration_number
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col span={3} style={{ marginTop: "4%" }}>
                            <img
                              style={{
                                width: "113.38582677px",
                                height: "113.38582677px",
                              }}
                              src={`${mainConfig.apiUrl}/user/image/${this.state.user_id}`}
                              alt="Foto Profil Peserta"
                            />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "2%", textAlign: "left" }}>
                          <Col span={5}>
                            <label>Asal Sekolah</label>
                          </Col>
                          <Col span={19}>
                            <Input value={this.state && this.state.school} />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "2%", textAlign: "left" }}>
                          <Col span={5}>
                            <label>Jalur</label>
                          </Col>
                          <Col span={19}>
                            <Input
                              value={`${this.state?.data?.line} (${this.state?.data?.line_category})`}
                            />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "2%", textAlign: "left" }}>
                          <Col span={5}>
                            <label>Pilihan Kelas</label>
                          </Col>
                          <Col span={19}>
                            <Input value={classTypes[this.state?.data?.class_type]} />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "2%", textAlign: "left" }}>
                          <Col span={5}>
                            <label>Pilihan Mahad</label>
                          </Col>
                          <Col span={19}>
                            <Input value={this.state?.data?.is_mahad ? 'Ya' : 'Tidak'} />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "2%", textAlign: "left" }}>
                          <Col span={5}>
                            <label>Alamat</label>
                          </Col>
                          <Col span={19}>
                            <Input
                              value={
                                this.state &&
                                this.state.district + ", " + this.state.city
                              }
                            />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "4%", textAlign: "left" }}>
                          <Col>
                            <p className="font-small-2" padding="0" margin="0">
                              Gunakan username{" "}
                              <b>
                                CBT{this.state.data && this.state.data.nisn}
                              </b>{" "}
                              dan password{" "}
                              <b>
                                {this.state.data &&
                                  this.state.data.registration_number}
                              </b>{" "}
                              untuk login CBT.
                            </p>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </React.Fragment>
                </div> */}
              </Col>
            </Row>
          </>,
        ]}
      >
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
            <Card
              className="font-medium-1"
              style={{
                
                borderColor:
                  this.state.file_status === "not-uploaded" ? "#ec4646" : "",
                color:
                  this.state.file_status === "not-uploaded"
                    ? "#ec4646"
                    : "black",
                textAlign: "center",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                padding: "4% 10% 4% 10%",
              }}
            >
              Belum Diunggah
              <CloseCircleOutlined
                style={{ marginLeft: "6%", verticalAlign: "middle" }}
              />
            </Card>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
            <Card
              className="font-medium-1"
              style={{
                
                borderColor:
                  this.state.file_status === "waiting" ? "#ffcc29" : "",
                color:
                  this.state.file_status === "waiting" ? "#ffcc29" : "black",
                textAlign: "center",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                padding: "4% 10% 4% 10%",
              }}
            >
              Menunggu Verifikasi
              <InfoCircleOutlined
                style={{ marginLeft: "6%", verticalAlign: "middle" }}
              />
            </Card>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
            <Card
              className="font-medium-1"
              style={{
                
                borderColor:
                  this.state.file_status === "verified" ? "#00af91" : "",
                color:
                  this.state.file_status === "verified" ? "#00af91" : "black",
                textAlign: "center",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                padding: "4% 12% 4% 10%",
              }}
            >
              Terverifikasi
              <CheckCircleOutlined
                style={{ marginLeft: "6%", verticalAlign: "middle" }}
              />
            </Card>
          </Col>
        </Row>

        <Form
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          style={{ marginTop: "5%" }}
        >
          <Row style={{ marginTop: 12 }} gutter={[16]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{ marginLeft: "2%", marginTop: "2%" }}
            >
              {/* <p className="font-normal text-bold-700"> Berkas Utama </p> */}
              <p className="font-normal">
                Nama : {this.state?.data?.user_name ?? ""}
              </p>
              <p className="font-normal">
                Jalur : {`${this.state?.data?.line} (${this.state?.data?.line_category})`}
              </p>
              <p className="font-normal">
                Berkas yang Diperlukan : {this.state?.data?.requirement}
              </p>
              <p className="font-normal">
                Catatan dari peserta : {this.state?.requirement_text ?? "-"}
              </p>
            </Col>
            <Col
              xs={24}
              sm={10}
              md={10}
              lg={10}
              style={{ marginTop: "2%", marginLeft: "2%" }}
            >
              <Button
                type="primary"
                // href={`${mainConfig.apiUrl}/file/download?user_id=${
                //   this.state.user_id
                // }&line_id=${this.state.line_id}&type=${"requirement_file"}`}
                onClick={this.handleDownloadReq}
                disabled={this.state.is_req_uploaded === "false"}
                loading={this.state.isLoading}
              >
                {this.state?.data?.offline_approve ? "Terverifikasi Offline" : "Unduh Berkas"}
              </Button>
            </Col>
            <Col xs={24} sm={5} md={5} lg={5} style={{ marginTop: "2%" }}></Col>
          </Row>
          {/* <Divider />
          <Row style={{ marginTop: 50 }} gutter={[4]}>
            <Col xs={24} sm={24} md={24} lg={24} style={{ marginLeft: "2%" }}>
              <p className="font-normal text-bold-700"> Berkas Tambahan </p>
              <p className="font-normal ">
                {" "}
                {this.state.data && this.state.data.additional}{" "}
              </p>
              <p className="font-normal">
                Catatan dari peserta : {this.state.additional_text ?? ""}
              </p>
            </Col>
            <Col
              xs={24}
              sm={10}
              md={10}
              lg={10}
              style={{ marginLeft: "2%", marginTop: "2%" }}
            >
              <Button
                type="primary"
                href={`${mainConfig.apiUrl}/file/download?user_id=${
                  this.state.user_id
                }&line_id=${this.state.line_id}&type=${"additional_file"}`}
                disabled={this.state.is_add_uploaded === "false"}
              >
                Unduh Berkas Tambahan
              </Button>
            </Col>
            <Col xs={24} sm={5} md={5} lg={5} style={{ marginTop: "2%" }}></Col>
          </Row> */}
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
