// import { authHeader } from '../_helpers';
import { mainConfig } from "../Config/mainConfig";
import { authHeader, handleResponse } from "./auth.header";

export const wagService = {
  getLinks,
  addLinks,
  editLinks,
  deleteLinks,
};

function getLinks(type = "") {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/wa-group?type=${type}`,
    reqOpt
  ).then(handleResponse);
}

function addLinks(data) {
  const reqOpt = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/wa-group`, reqOpt).then(handleResponse);
}
function editLinks(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/wa-group/${id}`, reqOpt).then(
    handleResponse
  );
}
function deleteLinks(id) {
  const reqOpt = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/wa-group/${id}`, reqOpt).then(
    handleResponse
  );
}
