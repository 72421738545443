import React from "react";

import { Row, Col, Pagination, Empty, Select, Table } from "antd";
import Search from "antd/lib/input/Search";
import { scoreService } from "../../../Service/score.service";
import ActionResult from "./ActionResult";
import ButtonExport from "../../../Component/File/ButtonExport";
import moment from "moment";
import LoginModal from "../../../Component/Modal/LoginModal";

const { Option } = Select;
function onBlur() {}

function onFocus() {}

function onSearch(val) {}

const types = {
  school: 'PPDB',
  mahad: 'Mahad'
}

let classTypes = {
  reguler: 'Reguler',
  superior: 'Unggulan',
  excelent: 'Excelent (Tahfidz)'
}

const CustomHeader = (props) => {
  let csvData =
    props.data &&
    props.data.map((item) => {
      let scoreResult = {
        "Nama Peserta": item.name,
        "Nomor Peserta": item.registration_number,
        "Asal Sekolah": item.school,
        Jalur: `${item.line} (${item.line_category})`,
        "Total Nilai": item.total_score,
        Peringkat: item.rank,
        Status: item.is_passed === 1 ? "Diterima" : "Tidak diterima",
        Keterangan: item.description,
      };

      let schoolScoreResult = {
        "Nama Peserta": item.name,
        "Nomor Peserta": item.registration_number,
        "Asal Sekolah": item.school,
        Jalur: `${item.line} (${item.line_category})`,
        // "Nilai Tes": item.score,
        // "Nilai Piagam": item.document_score,
        "Total Nilai": item.total_score,
        Peringkat: item.rank,
        "Diterima di Kelas": classTypes[item.class_type],
        Status: item.is_passed === 1 ? "Diterima" : "Tidak diterima",
        Keterangan: item.description,
      }

      return props.type === 'school' ? schoolScoreResult : scoreResult;
    });
  return (
    <React.Fragment>
      <div style={{ marginTop: "2%", marginBottom: "3%" }}>
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={5} lg={5}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Semua Status"
              optionFilterProp="children"
              onChange={props.onStatus}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              <Option value={-1}>Semua Status</Option>
              <Option value={1}>Diterima</Option>
              <Option value={0}>Tidak Diterima</Option>
            </Select>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={5} lg={5}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Jalur"
              optionFilterProp="children"
              onChange={props.onLine}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              {props.line.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={11} lg={11}>
            <div className="form-control-position pr-3">
              <Search
                placeholder="Cari nama peserta, nisn, atau nomor registrasi"
                allowClear
                onChange={(e) => props.handleFilter(e)}
                size="middle"
                style={{
                  color: "#555597",
                  width: "100%",
                }}
              />
            </div>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={3} lg={3}>
            <ButtonExport
              csvData={csvData}
              fileName={"Data Hasil Seleksi " + types[props.type] + " " + moment().format("LL")}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
class ResultTable extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }
  state = {
    data: [],
    lines: -1,
    status: -1,
    page: 1,
    limit: 10,
    total: 0,
    value: "",
  };
  componentDidMount() {
    this.getData(true);
  }
  showModal = () => {
    this._modalManage.current.toggleModal();
  };
  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    scoreService
      .getScore(
        this.state.page,
        this.state.limit,
        this.state.value,
        this.state.lines,
        this.state.status,
        "",
        false,
        this.props.type,
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (
      prevProps.reload !== this.props.reload ||
      prevState.page !== this.state.page ||
      prevState.limit !== this.state.limit ||
      prevState.value !== this.state.value ||
      prevState.lines !== this.state.lines ||
      prevState.status !== this.state.status
    ) {
      this.getData(false);
    }
  }
  onLine = (item) => {
    this.setState({ lines: item });
  };
  onStatus = (item) => {
    this.setState({ status: item });
  };
  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };
  onChange = (page, pageSize) => {
    if (isNaN(pageSize)) {
      this.setState({ page: page, limit: 0 });
    } else {
      this.setState({ page: page, limit: pageSize });
    }
  };
  render() {
    let { data, value } = this.state;
    let columns = [
      {
        title: "Nama",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Nomor Peserta",
        dataIndex: "registration_number",
        key: "registration_number",
      },
      {
        title: "Asal Sekolah",
        dataIndex: "school",
        key: "school",
        responsive: ["md"],
      },
      {
        title: "Jalur",
        key: "line",
        dataIndex: "line",
        responsive: ["md"],
      },
      {
        title: "Ketegori Jalur",
        key: "line_category",
        dataIndex: "line_category",
        responsive: ["md"],
      },
      {
        title: "Nilai Total",
        dataIndex: "total_score",
        key: "total_score",
      },
      {
        title: "Peringkat",
        dataIndex: "rank",
        key: "rank",
        responsive: ["md"],
      },
      {
        title: "Status",
        key: "is_passed_text",
        dataIndex: "is_passed_text",
      },
      {
        title: "Keterangan",
        key: "description",
        dataIndex: "description",
        responsive: ["md"],
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => (
          <ActionResult
            score={row}
            edit={this.props.edit}
            delete={this.props.delete}
          />
        ),
      },
    ];

    let schoolColumns = [
      {
        title: "Nama",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Nomor Peserta",
        dataIndex: "registration_number",
        key: "registration_number",
      },
      {
        title: "Asal Sekolah",
        dataIndex: "school",
        key: "school",
        responsive: ["md"],
      },
      {
        title: "Jalur",
        key: "line",
        dataIndex: "line",
        responsive: ["md"],
      },
      {
        title: "Ketegori Jalur",
        key: "line_category",
        dataIndex: "line_category",
        responsive: ["md"],
      },
      // {
      //   title: "Nilai Tes",
      //   dataIndex: "score",
      //   key: "score",
      //   responsive: ["md"],
      // },
      // {
      //   title: "Nilai Piagam",
      //   dataIndex: "document_score",
      //   key: "document_score",
      //   responsive: ["md"],
      // },
      {
        title: "Nilai Total",
        dataIndex: "total_score",
        key: "total_score",
      },
      {
        title: "Peringkat",
        dataIndex: "rank",
        key: "rank",
        responsive: ["md"],
      },
      {
        title: "Status",
        key: "is_passed_text",
        dataIndex: "is_passed_text",
      },
      {
        title: "Diterima di Kelas",
        key: "class_type",
        dataIndex: "class_type",
        render: (text) => {
          return classTypes[text];
        }
      },
      {
        title: "Keterangan",
        key: "description",
        dataIndex: "description",
        responsive: ["md"],
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => (
          <ActionResult
            score={row}
            edit={this.props.edit}
            delete={this.props.delete}
          />
        ),
      },
    ];

    let table = {
      data: data,
      total: this.state.total,
      columns: this.props.type === 'school' ? schoolColumns : columns,
      isLoading: this.state.isLoading,
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={
            <span style={{ color: "black" }}>
              Tidak ada data, Pastikan Sudah Memilih Filter Jalur
            </span>
          }
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <CustomHeader
          data={data}
          columns={table.columns}
          //Kategori
          line={this.props.lines}
          onLine={this.onLine}
          lines={this.state.lines.value}
          //Status
          onStatus={this.onStatus}
          status={this.state.status.value}
          //Search
          handleFilter={this.handleFilter}
          value={value}
          //Modal
          onClick={(e) => this.showModal()}
          type={this.props.type}
        />
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          loading={table.isLoading}
          locale={emptyData}
          scroll={{ x: true }}
        />
        {table.total !== 0 && (
          <Pagination
            responsive={true}
            style={{ textAlign: "center", marginTop: "5%", color: "black" }}
            defaultCurrent={1}
            total={table.total}
            showSizeChanger={true}
            onChange={this.onChange}
            pageSize={
              this.state.limit === 0 ? this.state.total : this.state.limit
            }
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} dari ${total} data`
            }
            pageSizeOptions={["10", "20", "50", "100", "semua"]}
          />
        )}
        <LoginModal ref={this._modalManage} />
      </React.Fragment>
    );
  }
}

export default ResultTable;
