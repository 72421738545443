import React from "react";
import { Table } from "antd";

import "react-toastify/dist/ReactToastify.css";

class ImportTable extends React.Component {
  state = {
    columns: [
      {
        title: "Nama Kolom",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Keterangan",
        dataIndex: "description",
        key: "description",
      },
    ],

    data: [
      {
        name: "Nomor Registrasi",
        description: "Dibuat sistem di template",
      },
      // {
      //   name: "Nilai Piagam",
      //   description: "Nilai hasil piagam yang dikumpulkan peserta",
      // },
      // {
      //   name: "Nilai Tes",
      //   description: "Nilai hasil tes peserta",
      // },
      {
        name: "Nilai Total",
        description: "Total nilai akhir",
      },
      {
        name: "Peringkat",
        description: "	Peringkat peserta",
      },
      {
        name: "Status",
        description: "Hasil akhir seleksi, isi 1 jika diterima, 0 jika tidak",
      },
      {
        name: "Diterima di Kelas",
        description: "Isi dengan reguler, unggulan, atau excelent dalam huruf kecil",
      },
      {
        name: "Keterangan",
        description: "Keterangan untuk hasil seleksi peserta",
      },
      {
        name: "Virtual Account",
        description: "Virtual Account Peserta",
      },
    ],
  };

  render() {
    let { data, columns } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    return (
      <React.Fragment>
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          scroll={{ x: true }}
        />
      </React.Fragment>
    );
  }
}
export default ImportTable;
