import React from "react";

import { Row, Col, Pagination, Select, Table, Empty } from "antd";
import Search from "antd/lib/input/Search";
import ActionFile from "./ActionFile";
import moment from "moment";
import "moment/locale/id";
import ButtonExport from "../../../Component/File/ButtonExport";
import { participantService } from "../../../Service/participant.service";
moment.locale("id");

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
const CustomHeader = (props) => {
  let csvData = props.data.map((item) => {
    return {
      "Nama Peserta": item.user_name,
      NISN: item.nisn,
      "No Telepon": item.phone_number,
      Jalur: `${item.line ?? ''} (${item.line_category ?? ''})`,
      Status: item.file_status_text,
      "Tanggal Konfirmasi": item.confirm_date,
    };
  });
  return (
    <React.Fragment>
      <div style={{ marginTop: "2%", marginBottom: "3%" }}>
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={8} lg={5}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Status"
              optionFilterProp="children"
              onChange={props.onStatus}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              <Option value="">Semua Status</Option>
              <Option value="not-uploaded">Belum Diunggah</Option>
              <Option value="waiting">Menunggu Verifikasi</Option>
              <Option value="verified">Terverifikasi</Option>
            </Select>
          </Col>

          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={8} lg={5}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Semua Jalur"
              optionFilterProp="children"
              onChange={props.onLine}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              {props.line.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={12} lg={11}>
            <div className="form-control-position pr-3">
              <Search
                placeholder="Cari nama peserta, nisn, atau nomor registrasi"
                allowClear
                size="middle"
                onChange={(e) => props.handleFilter(e)}
                style={{
                  color: "#555597",
                  height: 30,
                  width: "100%",
                }}
              />
            </div>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={24} md={12} lg={3}>
            <ButtonExport
              csvData={csvData}
              style={{ width: "100%" }}
              fileName={"Data Pemberkasan " + moment().format("LL")}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
class PaymentTable extends React.Component {
  state = {
    columns: [
      {
        title: "Nama",
        dataIndex: "user_name",
        key: "user_name",
      },
      {
        title: "NISN",
        dataIndex: "nisn",
        key: "nisn",
        responsive: ["md"],
      },
      {
        title: "Status",
        dataIndex: "file_status_text",
        key: "file_status_text",
        responsive: ["md"],
      },
      {
        title: "Jalur",
        dataIndex: "line",
        key: "line",
      },
      {
        title: "Ketegori Jalur",
        dataIndex: "line_category",
        key: "line_category",
        responsive: ["md"],
      },
      {
        title: "Tanggal Konfirmasi",
        key: "confirm_date",
        dataIndex: "confirm_date",
        responsive: ["md"],
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => <ActionFile status={row} edit={this.props.edit} />,
      },
    ],
    data: [],
    startDate: "",
    endDate: "",
    lines: -1,
    status: "",
    page: 1,
    limit: 10,
    total: 0,
    value: "",
  };

  componentDidMount() {
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    participantService
      .getListFile(
        this.state.page,
        this.state.limit,
        this.state.status,
        this.state.lines,
        this.state.value
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (
      prevProps.reload !== this.props.reload ||
      prevState.lines !== this.state.lines ||
      prevState.page !== this.state.page ||
      prevState.value !== this.state.value ||
      prevState.status !== this.state.status ||
      prevState.limit !== this.state.limit
    ) {
      this.getData(false);
    }
  }
  changeDate = (dates, type) => {
    if (type === "startDate") {
      let startDate = moment(dates._d).format("YYYY-MM-DD 00:01");
      this.setState({ startDate });
    } else {
      let endDate = moment(dates._d).format("YYYY-MM-DD 23:59");
      this.setState({ endDate });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };

  onLine = (item) => {
    this.setState({ lines: item });
  };

  onStatus = (item) => {
    this.setState({ status: item });
  };

  onChange = (page, pageSize) => {
    if (isNaN(pageSize)) {
      this.setState({ page: page, limit: 0 });
    } else {
      this.setState({ page: page, limit: pageSize });
    }
  };

  onPayment = (item) => {
    this.setState({ paymentStatus: item });
  };

  render() {
    let { data, columns, value } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };

    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <CustomHeader
          data={data}
          columns={table.columns}
          //Search
          handleFilter={this.handleFilter}
          value={value}
          //Lines
          line={this.props.lines}
          onLine={this.onLine}
          lines={this.state.lines.value}
          //Status
          onStatus={this.onStatus}
        />
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          loading={table.isLoading}
          locale={emptyData}
          scroll={{ x: true }}
        />
        {table.total > 0 && (
          <Pagination
            responsive={true}
            style={{ textAlign: "center", marginTop: "5%", color: "black" }}
            defaultCurrent={1}
            total={table.total}
            showSizeChanger={true}
            onChange={this.onChange}
            pageSize={
              this.state.limit === 0 ? this.state.total : this.state.limit
            }
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} dari ${total} data`
            }
            pageSizeOptions={["10", "20", "50", "100", "semua"]}
          />
        )}
      </React.Fragment>
    );
  }
}

export default PaymentTable;
