import React from "react";

import moment from "moment";
import { Row, Col, Button, Card, BackTop, Divider } from "antd";
import CarouselDashboard from "./Carousel";
import { phaseService } from "../../Service/phase.services";
import { contactService } from "../../Service/contact.service";
import { lineService } from "../../Service/line.services";
import Iframe from "react-iframe";
import { SettingService } from "../../Service/setting.services";
import { ArrowUpOutlined } from "@ant-design/icons";
import { Parser } from "html-to-react";

class CardDashboard extends React.Component {
  state = {
    categories: [],
    contact: [],
    phase: [],
    settings: [],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    phaseService.getSchedule(1, 999999999).then(
      (resp) => {
        if (resp.status) {
          this.setState({ phase: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    contactService.getContact().then(
      (resp) => {
        if (resp.status) {
          this.setState({ contact: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    lineService.getLine(1).then(
      (resp) => {
        if (resp.status) {
          this.setState({ categories: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    SettingService.getSetting().then(
      (resp) => {
        if (resp.status) {
          this.setState({
            settings: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }
  handleClick = (e) => {
    window.open(this.state.settings.technical_document);
  };
  render() {
    return (
      <React.Fragment>
        <CarouselDashboard data={this.state.settings} />
        <Card
          className="card"
          style={{
            backgroundColor: "white",
            paddingTop: "48px",
            paddingBottom: "48px",
          }}
        >
          <div style={{display: "flex", alignItems: "center", flexDirection:"column"}}>
            <p className="font-large-1" style={{ textAlign: "center" }}>
              {this.state.settings.about_title}
            </p>
            <div style={{fontSize: "14pt",}}>
              {Parser().parse(this.state.settings.about_description)}
            </div>
          </div>
        </Card>
        <Card
          className="cardSchedule"
          style={{
            backgroundColor: "#aedebe",
            paddingTop: "48px",
            paddingBottom: "48px",
          }}
        >
          <div>
            <p className="font-large-1" style={{ textAlign: "center" }}>
              Jadwal Pelaksanaan{" "}
            </p>
            <div style={{ textAlign: "center" }}>
              <Row gutter={[12]} justify="center">
                <Col xs={0} sm={0} md={2} lg={2}></Col>
                <Col xs={0} sm={0} md={6} lg={6}>
                  <p style={{ textAlign: "center", fontSize: "14pt" }}>
                    Kegiatan
                  </p>
                </Col>
                <Col xs={0} sm={0} md={4} lg={4}>
                  <p style={{ textAlign: "center", fontSize: "14pt" }}>
                    Kategori Jalur
                  </p>
                </Col>
                <Col xs={0} sm={0} md={6} lg={6}>
                  <p style={{ textAlign: "center", fontSize: "14pt" }}>
                    Tanggal
                  </p>
                </Col>

                <Col xs={0} sm={0} md={2} lg={2}></Col>
              </Row>
              {this.state.phase.map((item) => (
                <>
                  <Divider
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  />
                  <Row gutter={[12]} justify="center">
                    <Col xs={0} sm={0} md={2} lg={2}></Col>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p style={{ textAlign: "center", fontSize: "14pt" }}>
                        {item.name}
                      </p>
                    </Col>
                    <Col xs={24} sm={6} md={4} lg={4}>
                      <p style={{ textAlign: "center", fontSize: "14pt" }}>
                        {item.line_category_name}
                      </p>
                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p style={{ textAlign: "center", fontSize: "14pt" }}>
                        {moment(item.start_date).isSame(moment(item.end_date))
                          ? moment(item.start_date).format("ll") +
                            " " +
                            moment(item.start_date).format("LT")
                          : moment(item.start_date).format("ll") +
                            " " +
                            moment(item.start_date).format("LT") +
                            " - " +
                            moment(item.end_date).format("ll") +
                            " " +
                            moment(item.end_date).format("LT")}
                      </p>
                    </Col>

                    <Col xs={0} sm={0} md={2} lg={2}></Col>
                  </Row>
                </>
              ))}
            </div>
          </div>
        </Card>

        <Card
          className="card"
          style={{
            backgroundColor: "#edf1f5",
            paddingTop: "48px",
            paddingBottom: "48px",
          }}
        >
          <p className="font-large-1" style={{ textAlign: "center" }}>
            Jalur Pendaftaran
          </p>
          <div className="site-card-wrapper">
            <Row gutter={[16]} justify="center">
              {this.state.categories.map((item) => (
                <Col xs={24} sm={12} md={8} lg={8} style={{ marginTop: 16 }}>
                  <Card
                    style={{ textAlign: "center" }}
                    title={item.line_category_name}
                    className="font-medium-1"
                  >
                    <p>{item.name}</p>
                    <p>{item.description}</p>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Card>

        <Card
          className="card"
          style={{
            backgroundColor: "#white",
            textAlign: "center",
            paddingTop: "48px",
            paddingBottom: "48px",
          }}
        >
          <p className="font-large-1">Peraturan dan Teknis Pelaksanaan</p>
          <Button onClick={this.handleClick} type="primary" size="large">
            Unduh Petunjuk Teknis
          </Button>
        </Card>

        <Card
          className="cardSchedule"
          style={{
            backgroundColor: "#19202d",
            width: "100%",
            paddingTop: "48px",
            paddingBottom: "48px",
          }}
        >
          <div>
            <p
              className="font-large-1"
              style={{
                textAlign: "center",
                color: "white",
              }}
            >
              Informasi Lebih Lanjut
            </p>
            <Row gutter={[48]}>
              <Col
                style={{ marginTop: 12 }}
                xs={24}
                sm={24}
                md={24}
                lg={12}
                align="center"
              >
                <Iframe
                  src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d63347.53946360337!2d112.15210554648523!3d-7.100340313267944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x2e778c56f1d28f55%3A0x31e1bcffa8e71dc7!2sMTs%20Negeri%201%20Babat%2C%20Jl.%20Raya%20Plaosan%20-%20Babat%20No.11%2C%20Plaosan%2C%20Kec.%20Babat%2C%20Kabupaten%20Lamongan%2C%20Jawa%20Timur%2062271!3m2!1d-7.100426799999999!2d112.18712509999999!5e0!3m2!1sid!2sid!4v1612161239398!5m2!1sid!2sid"
                  width="80%"
                  height="250"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={0}
                style={{ marginTop: 12 }}
              ></Col>
              <Col xs={4} sm={4} md={4} lg={0}></Col>

              <Col
                style={{ marginTop: 12 }}
                xs={16}
                sm={16}
                md={16}
                lg={12}
                align="justify"
              >
                {this.state.contact.map((item) => (
                  <Row style={{ marginTop: 4 }} gutter={[12]}>
                    <Col xs={24} sm={8} md={8} lg={8}>
                      <p className="font-medium-1" style={{ color: "white" }}>
                        {item.name}
                      </p>
                    </Col>
                    <Col xs={24} sm={16} md={16} lg={16}>
                      <p className="font-medium-1" style={{ color: "white" }}>
                        {item.content}
                      </p>
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col xs={4} sm={4} md={4} lg={0}></Col>
            </Row>
          </div>
        </Card>
        <BackTop>
          <div
            style={{
              height: 40,
              width: 40,
              lineHeight: "40px",
              backgroundColor: "#1088e9",
              color: "#fff",
              textAlign: "center",
            }}
          >
            <ArrowUpOutlined />
          </div>
        </BackTop>
      </React.Fragment>
    );
  }
}
export default CardDashboard;
