import React from 'react';

import { Menu, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';


class ActionPayment extends React.Component{
  state = {
    payment : this.props.payment
  }
  editPayment = () => {
    this.props.edit(this.props.payment)
}

componentDidUpdate(prevProps, prevState) {
  if(prevProps.payment !== this.props.payment) {
      this.setState({payment: this.props.payment})
  }
}
    render(){
        return(
<Space wrap>
   
    <Dropdown.Button onClick={this.editPayment}  placement="bottomCenter" icon={<DownOutlined />}
    overlay={<Menu>

    </Menu>}
>
      Konfirmasi Pembayaran
    </Dropdown.Button>
    
  </Space>
        )
    }
}

export default ActionPayment;