import React from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  DatePicker,
  Row,
  Col,
  Select,
} from "antd";
import { phaseService } from "../../../../Service/phase.services";
import { categoryService } from "../../../../Service/category.services";
import dayjs from "dayjs";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class ManageModal extends React.Component {
  state = {
    data: this.props.schedule,
    isOpen: false,
    isLoading: false,
    name: "",
    type: "",
    startDate: "",
    endDate: "",
    line_category_id: -1,
    categories: [],
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  addSchedule = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.data) {
      phaseService
        .editSchedule(this.state.data.id, {
          name: this.state.name,
          type: this.state.type,
          line_category_id: this.state.line_category_id,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              type: "",
              line_category_id: -1,
              startDate: "",
              endDate: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              type: "",
              line_category_id: -1,
              startDate: "",
              endDate: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      phaseService
        .addSchedule({
          name: this.state.name,
          type: this.state.type,
          line_category_id: this.state.line_category_id,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              type: "",
              line_category_id: -1,
              startDate: "",
              endDate: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              type: "",
              line_category_id: -1,
              startDate: "",
              endDate: "",
            });
            this.props.onUpdated({
              isUpdated: false,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.schedule !== this.props.schedule) {
      if (this.props.schedule)
        this.setState({
          isLoading: false,
          data: this.props.schedule,
          name: this.props.schedule.name,
          type: this.props.schedule.type,
          startDate: this.props.schedule.start_date,
          endDate: this.props.schedule.end_date,
          line_category_id: this.props.schedule.line_category_id
          
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          name: "",
          type: "",
          startDate: "",
          endDate: "",
          line_category_id: -1
        });
    }
  }
  onChange(date, type) {
    if (type === "startDate") {
      this.setState({ startDate: date });
    } else {
      this.setState({ endDate: date });
    }
  }

  componentDidMount() {
    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "line_category_id", label: item.name, value: item.id };
      });
      data.unshift({ name: "line_category_id", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data });
    });
  }

  render() {
    return (
      <Modal
        title={this.state.data ? "Edit Tahapan" : "Tambah Tahapan"}
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            loading={this.state.isLoading}
            key="submit"
            type="primary"
            onClick={(e) => this.addSchedule(e)}
          >
            {this.state.data ? "Edit Tahapan" : "Tambah Tahapan"}
          </Button>,
        ]}
      >
        <Form>
          <Row gutter={[16]} style={{ marginTop: 16 }}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Nama <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Nama"
                type="text"
                value={this.state.name}
                id="name"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Col>
          </Row>
          <Row gutter={[12]} style={{ marginTop: 12 }}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Tipe <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Tipe"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ type: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this.state.type}
              >
                <Option value="">Semua Tipe</Option>
                <Option value="registration">Registrasi</Option>
                <Option value="administration">Administrasi</Option>
                <Option value="test">Tes</Option>
                <Option value="re-registration">Daftar Ulang</Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={[12]} style={{ marginTop: 12 }}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Kategori Jalur <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Semua Kategori"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ line_category_id: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this.state.line_category_id}
              >
                {this.state.categories.map((item) => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row style={{ marginTop: 12 }} gutter={[12]}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Tanggal Mulai <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Tanggal Mulai"
                  value={this.state.startDate ? dayjs(this.state.startDate) : ''}
                  onChange={(date) => this.onChange(date, "startDate")}
                  showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
                ></DatePicker>
            </Col>
          </Row>

          <Row style={{ marginTop: 12 }} gutter={[12]}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Tanggal Selesai <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Tanggal Selesai"
                  value={this.state.endDate ? dayjs(this.state.endDate) : ''}
                  onChange={(date) => this.onChange(date)}
                  showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
                ></DatePicker>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
