import React from "react";

import { Row, Col, Select, Modal, Form, Button } from "antd";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import ImportTable from "./ImportTable";
import { lineService } from "../../../../Service/line.services";
import BasicDropzone from "../../../../Component/File/BasicDropzone";
import { scoreService } from "../../../../Service/score.service";
import ManageModal from "./ManageModal";
const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class Import extends React.Component {
  constructor(props) {
    super(props);
    this.fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    this.fileExtension = ".xlsx";
    this._modalManage = React.createRef();
  }

  state = {
    score: [],
    isLoading: false,
    lines: [],
    lineId: null,
    type: null
  };

  showModal = () => {
    this._modalManage.current.toggleModal();
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    scoreService
      .importScore({
        line_id: this.state.lineId,
        type: this.state.type,
        score: this.state.score,
      })
      .then(
        (resp) => {
          this.setState({ 
            isLoading: false,
            lineId: null,
            type: null,
            score: []
          });
          this.toggleModal();
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
          });
        },
        (err) => {
          this.setState({ 
            isLoading: false,
            lineId: null,
            type: null,
            score: []
          });
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      );
  };

  componentDidMount() {
    lineService.getLine(1).then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "lineId", label: `${item.name} (${item.line_category_name})`, value: item.id };
      });
      this.setState({ lines: data });
    });
  }
  onDropped = (files) => {
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(files[0]);
    fileReader.onloadend = (e) => {
      if (this.state.type) {
        let data = e.target.result;
        let workbook = XLSX.read(data, {
          type: "binary",
        });

        let scoreJSON = workbook.SheetNames.map((item) => {
          return XLSX.utils.sheet_to_row_object_array(workbook.Sheets[item]);
        });

        scoreJSON[0].map((item) => {
          delete item.Nama;
          
          let heading = [
            [
              "registration_number",
              "total_score",
              "rank",
              "is_passed",
              "description",
            ],
          ];

          let schoolHeading = [
            [
              "registration_number",
              // "score",
              // "document_score",
              "total_score",
              "rank",
              "is_passed",
              "class_type",
              "description",
              "virtual_account",
            ],
          ];
          const ws = XLSX.utils.book_new();
          XLSX.utils.sheet_add_aoa(ws, this.state.type === 'school' ? schoolHeading : heading);
          let c = XLSX.utils.sheet_add_json(ws, [item], {
            origin: "A2",
            skipHeader: true,
          });
          let score = XLSX.utils.sheet_to_row_object_array(c);
          this.state.score.push(score[0]);

          return null;
        });

        let score = this.state.score;
        this.setState({ score });
      } else {
        toast.error('Harap Pilih Tipe Seleksi', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    };

    fileReader.onerror = (err) => {
      this.setState({ score: [] });

      toast.error('Harap Pilih File Ulang', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    };
  };

  render() {
    let data = this.state;
    return (
      <React.Fragment>
        <Row gutter={[16]} style={{ textAlign: "right" }}>
          <Col style={{ marginTop: 16 }} xs={0} sm={8} md={12} lg={16}></Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={6} lg={4}>
            <Button
              type="primary"
              style={{ float: "right", width: "100%" }}
              onClick={this.toggleModal}
            >
              Impor Dari Excel
            </Button>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={6} lg={4}>
            <Button
              type="primary"
              style={{ float: "right", width: "100%" }}
              onClick={this.showModal}
            >
              Unduh Template
            </Button>
          </Col>
        </Row>
        <ImportTable />

        <Modal
          title="Impor Nilai"
          centered
          visible={this.state.isOpen}
          toggle={this.toggleModal}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Batalkan
            </Button>,
            <Button
              loading={this.state.isLoading}
              key="submit"
              type="primary"
              disabled={!this.state.lineId || !this.state.type || this.state.score.length <= 0}
              onClick={(e) => this.handleSubmit(e)}
            >
              Impor Nilai
            </Button>,
          ]}
          style={{ width: "100%", resize: "none" }}
        >
          <Form>
            <Row gutter={[12]}>
              <Col xs={24} sm={5} md={5} lg={5}>
                <label>
                  Jalur <span style={{ color: "red" }}> *</span>
                </label>
              </Col>
              <Col xs={24} sm={18} md={18} lg={18}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Pilih Jalur"
                  optionFilterProp="children"
                  onChange={(e) => {
                    this.setState({ lineId: e });
                  }}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  value={this.state.lineId}
                >
                  {data.lines.map((item) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={[12]} style={{marginTop: 12}}>
              <Col xs={24} sm={5} md={5} lg={5}>
                <label>
                  Tipe Seleksi <span style={{ color: "red" }}> *</span>
                </label>
              </Col>
              <Col xs={24} sm={18} md={18} lg={18}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Pilih Tipe Seleksi"
                  optionFilterProp="children"
                  onChange={(e) => {
                    this.setState({ type: e });
                  }}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  value={this.state.type}
                >
                  <Option value='school'>Seleksi PPDB</Option>
                  <Option value='mahad'>Seleksi Mahad</Option>
                </Select>
              </Col>
            </Row>

            <Row style={{ marginTop: "5%", marginLeft: "5%" }}>
              <Col xs={24} sm={5} md={5} lg={5}></Col>
              <Col xs={24} sm={18} md={18} lg={18}>
                <BasicDropzone onDropped={this.onDropped} />
              </Col>
            </Row>
          </Form>
        </Modal>
        <ManageModal ref={this._modalManage} />
      </React.Fragment>
    );
  }
}

export default Import;
