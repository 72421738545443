import React from "react";
import { Modal, Button, Row, Col } from "antd";
import { mainConfig } from "../../../../Config/mainConfig";

class DownloadModal extends React.Component {
  state = {
    data: this.props.user,
    id: 0,
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      if (this.props.user)
        this.setState({
          isLoading: false,
          data: this.props.user,
          id: this.props.user.id,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          id: "",
        });
    }
  }

  render() {
    return (
      <Modal
        title="Unduh Berkas"
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
        ]}
      >
        <Row>
          <Col span={7}>
            <label>Berkas Utama </label>
          </Col>
          <Col span={16}>
            <Button
              key="submit"
              type="primary"
              href={
                mainConfig.apiUrl +
                "/user/file/" +
                this.state.id +
                "?type=requirement_file"
              }
            >
              Unduh Berkas
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "3%" }}>
          <Col span={7}>
            <label>Berkas Tambahan </label>
          </Col>
          <Col span={16}>
            <Button
              key="submit"
              type="primary"
              href={
                mainConfig.apiUrl +
                "/user/file/" +
                this.state.id +
                "?type=additional_file"
              }
            >
              Unduh Berkas
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default DownloadModal;
