import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "antd";
import { useDropzone } from "react-dropzone";
import UploadFileImg from "../../Assets/uploadFile.svg";
import { FileText } from "react-feather";

function ZipDropzone(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    // accept:
    //   "application/zip,application/x-zip,application/x-zip-compressed,application/octet-stream",
    maxFiles: 1,
    init: function () {
      this.on("maxfilesexceeded", function (file) {
        this.removeAllFiles();
        this.addFile(file);
      });
      this.on("sending", function (file, xhr, data) {});
    },
    onDrop: (acceptedFiles) => {
      props.onDropped(acceptedFiles);
      setFiles(acceptedFiles.map((file) => Object.assign(file)));
    },
  });

  const thumbs =
    files.length > 0
      ? files.map((file) => (
          <p key={file.name} className="black mt-1">
            <FileText size={40} />
            {file.name}
          </p>
        ))
      : "";

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      // files.forEach(file => URL.revokeObjectURL(file.preview))
    },
    [files]
  );

  return (
    <section>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />{" "}
        {thumbs ? (
          thumbs
        ) : (
          <Row gutter={16}>
            <Col style={{display: "flex", alignItems:"center"}}>
            <img className="center" src={UploadFileImg} alt=""></img>
            </Col>
            <Col style={{display: "flex", alignItems:"center"}}>
            <Button
              outline="true"
              className="font-normal text-bold-500 center"
              color="primary"
            >
              Pilih & Upload File
            </Button>
            </Col>
          </Row>
        )}
      </div>
      {/* <aside className="thumb-container">{thumbs}</aside> */}
    </section>
  );
}

class DropzoneZip extends React.Component {
  render() {
    return <ZipDropzone onDropped={this.props.onDropped} />;
  }
}

export default DropzoneZip;
