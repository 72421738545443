import React from "react";
import { Modal, Input, Button, Row, Col } from "antd";
import { participantService } from "../../../../Service/participant.service";

class ManageModal extends React.Component {
  state = {
    data: this.props.school,
    name: "",
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  addSchool = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.data) {
      participantService
        .editSchool(this.state.data.id, {
          name: this.state.name,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      participantService
        .addSchool({
          name: this.state.name,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
            });
            this.props.onUpdated({
              isUpdated: false,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.school !== this.props.school) {
      if (this.props.school)
        this.setState({
          isLoading: false,
          data: this.props.school,
          name: this.props.school.name,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          name: "",
        });
    }
  }

  render() {
    return (
      <Modal
        title={this.state.data ? "Edit Sekolah" : "Tambah Sekolah"}
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={(e) => this.addSchool(e)}
          >
            {this.state.data ? "Edit Sekolah" : "Tambah Sekolah"}
          </Button>,
        ]}
      >
        <Row>
          <Col span={6}>
            <label>Nama Sekolah </label>
          </Col>
          <Col span={17}>
            <Input
              placeholder="Nama Sekolah"
              type="text"
              name="name"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default ManageModal;
