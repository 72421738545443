import React from "react";
import { ToastContainer } from "react-toastify";
import AppHeader from "./Component/Header";
import { Layout } from "antd";
import Sidebar from "./Component/Sidebar";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { history } from "./History";
import Announcement from "../src/View/Announcement/Index";
import CardDashboard from "./View/Dashboard/CardDashboard";
import Register from "./View/RegisterPage/Index";
import { jwtDecode } from "jwt-decode";

class App extends React.Component {
  render() {
    return (
      <>
        <Router history={history}>
          <Layout className="mainLayout">
            <AppHeader />
            <div style={{ marginTop: "64px" }}></div>
            <Switch>
              <Route
                exact
                path="/"
                component={() => {
                  return <Redirect to="/dashboard" />;
                }}
              />
              <Route
                exact
                path="/profile"
                component={() => {
                  let userToken = localStorage.getItem("USER_TOKEN");
                  let userData = jwtDecode(userToken);
                  let user_role = userData ?? "";
                  if (user_role.role === "admin") {
                    return <Redirect to="/profile/admin/summary" />;
                  } else if (user_role.role === "user") {
                    return <Redirect to="/profile/summary" />;
                  } else {
                    return <Redirect to="/dashboard" />;
                  }
                }}
              />
              <Route
                path="/profile"
                component={() => {
                  let userToken = localStorage.getItem("USER_TOKEN");
                  let userData = jwtDecode(userToken);
                  let user_role = userData ?? "";
                  if (user_role) {
                    return <Sidebar />;
                  } else {
                    return <Redirect to="/dashboard" />;
                  }
                }}
              />
              <Route exact path="/dashboard" component={CardDashboard} />
              <Route exact path="/announcement" component={Announcement} />
              <Route path="/profile" component={Sidebar} />
              <Route path="/register" component={Register} />
            </Switch>
            <ToastContainer />
          </Layout>
        </Router>
      </>
    );
  }
}

export default App;
