// import { authHeader } from '../_helpers';
import { mainConfig } from '../Config/mainConfig';
import { authHeader, handleResponse} from './auth.header';

export const addressService = {
   getListAddress,
   getAddress
};

function getListAddress(page, limit) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/address?page=${page}&per_page=${limit}`, reqOpt).then(handleResponse)
}
function getAddress(city, province, page, limit) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/address?page=${page}&per_page=${limit}&city=${city}&province=${province}`, reqOpt).then(handleResponse)
}

