import React from "react";
import { Card, Table, Empty } from "antd";
import { scoreService } from "../../../Service/score.service";

class Result extends React.Component {
  classTypes = {
    reguler: 'Reguler',
    superior: 'Unggulan',
    excelent: 'Excelent (Tahfidz)'
  }

  state = {
    columns: [
      {
        title: "Jalur",
        dataIndex: "line",
        key: "line",
      },
      {
        title: "Ketegori Jalur",
        key: "line_category",
        dataIndex: "line_category",
        responsive: ["md"],
      },
      {
        title: "Nilai Total",
        dataIndex: "total_score",
        key: "total_score",
      },
      {
        title: "Peringkat",
        dataIndex: "rank",
        key: "rank",
      },
      {
        title: "Status",
        dataIndex: "is_passed_text",
        key: "is_passed_text",
      },
      {
        title: "Keterangan",
        dataIndex: "description",
        key: "description",
      },
    ],
    schoolColumns: [
      {
        title: "Jalur",
        dataIndex: "line",
        key: "line",
      },
      {
        title: "Ketegori Jalur",
        key: "line_category",
        dataIndex: "line_category",
        responsive: ["md"],
      },
      // {
      //   title: "Nilai Tes",
      //   dataIndex: "score",
      //   key: "score",
      // },
      // {
      //   title: "Nilai Piagam",
      //   dataIndex: "document_score",
      //   key: "document_score",
      // },
      {
        title: "Nilai Total",
        dataIndex: "total_score",
        key: "total_score",
      },
      {
        title: "Peringkat",
        dataIndex: "rank",
        key: "rank",
      },
      {
        title: "Status",
        dataIndex: "is_passed_text",
        key: "is_passed_text",
      },
      {
        title: "Pilihan Kelas",
        key: "class_type",
        dataIndex: "class_type",
        render: (text) => {
          return this.classTypes[text];
        }
      },
      {
        title: "Keterangan",
        dataIndex: "description",
        key: "description",
      },
    ],
    data: [],
    page: 1,
    limit: 10,
    total: 0,
    value: "",
    lineId: "",
    user: {}
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData(true);
  }

  getData(showLoading = false) {
    let userData = localStorage.getItem("USER_DATA");
    userData = JSON.parse(userData);
    this.setState({user: userData});
    this.setState({ isLoading: showLoading });
    scoreService
      .getScore(
        this.state.page,
        this.state.limit,
        this.state.value,
        -1,
        -1,
        userData.id
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  render() {
    let { data, columns, schoolColumns } = this.state;
    let table = {
      data: data,
      columns: columns,
      schoolColumns: schoolColumns,
      isLoading: this.state.isLoading,
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      
        <React.Fragment>
        <div>
          <div style={{ marginTop: 20 }}>
            <p className="font-normal text-bold-700"> Pengumuman PPDB </p>
            <div>
              <Card
                style={{
                  
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Table
                  columns={table.schoolColumns}
                  dataSource={table.data.filter(item => item.type === 'school')}
                  pagination={false}
                  locale={emptyData}
                  scroll={{ x: true }}
                />
              </Card>
            </div>
          </div>
          <div style={{ marginTop: 20, display: this.state.user?.is_mahad ? 'block' : 'none' }}>
            <p className="font-normal text-bold-700"> Pengumuman Mahad </p>
            <div>
              <Card
                style={{
                  
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Table
                  columns={table.columns}
                  dataSource={table.data.filter(item => item.type === 'mahad')}
                  pagination={false}
                  locale={emptyData}
                  scroll={{ x: true }}
                />
              </Card>
            </div>
          </div>
        </div>
        </React.Fragment>
    );
  }
}
export default Result;
