import React from "react";
import { Table, Pagination, Empty, Row, Col, DatePicker, Input } from "antd";

import "react-toastify/dist/ReactToastify.css";
import ActionAnnouncement from "./ActionAnnouncement";
import { announcementService } from "../../../Service/announcement.service";
import moment from "moment";
import "moment/locale/id";
import { Parser } from "html-to-react";
const { Search } = Input;
const CustomHeader = (props) => {
  return (
    <React.Fragment>
      <div style={{ marginTop: "2%", marginBottom: "2%" }}>
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={6} lg={6}>
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Tanggal Mulai"
              onChange={(date) => props.changeDate(date, "startDate")}
            ></DatePicker>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={6} lg={6}>
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Tanggal Berakhir"
              onChange={(date) => props.changeDate(date)}
            ></DatePicker>
          </Col>

          <Col style={{ marginTop: 16 }} xs={24} sm={24} md={12} lg={12}>
            <div className="form-control-position pr-3">
              <Search
                placeholder="Cari pengumuman"
                allowClear
                size="middle"
                onChange={(e) => props.handleFilter(e)}
                style={{
                  color: "#555597",
                  height: 30,
                  width: "100%",
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
class AnnouncementTable extends React.Component {
  state = {
    columns: [
      {
        title: "Tanggal",
        dataIndex: "created_at",
        key: "created_at",

        responsive: ["md"],
      },
      {
        title: "Judul",
        dataIndex: "title",
        key: "title",
        responsive: ["md"],
      },
      {
        title: "Isi",
        dataIndex: "content",
        render: (text)=>{
          return (<div style={{ overflow: "hidden", "text-overflow": "ellipsis", height:"48px",}} >{Parser().parse(text)}</div>)
        },
        key: "content",
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => (
          <ActionAnnouncement
            announcement={row}
            edit={this.props.edit}
            delete={this.props.delete}
          />
        ),
      },
    ],
    data: [],
    page: 1,
    limit: 10,
    total: 0,
    startDate: "",
    endDate: "",
    value: "",
  };

  componentDidMount() {
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    announcementService
      .getAnnouncement(
        this.state.page,
        this.state.limit,
        this.state.value,
        this.state.startDate,
        this.state.endDate
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (
      prevProps.reload !== this.props.reload ||
      prevState.page !== this.state.page ||
      prevState.limit !== this.state.limit ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.value !== this.state.value
    ) {
      this.getData(false);
    }
  }

  onChange = (page, pageSize) => {
    this.setState({ page: page, limit: pageSize });
  };

  changeDate = (dates, type) => {
    if (type === "startDate") {
      let startDate = moment(dates._d).format("YYYY-MM-DD 00:01");
      this.setState({ startDate });
    } else {
      let endDate = moment(dates._d).format("YYYY-MM-DD 23:59");
      this.setState({ endDate });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };

  render() {
    let { data, columns, value } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <CustomHeader
          data={data}
          columns={table.columns}
          //Date
          changeDate={this.changeDate}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          //Search
          handleFilter={this.handleFilter}
          value={value}
        />
        <Table
          scroll={{ x: true }}
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          loading={table.isLoading}
          locale={emptyData}
        />
        {table.total !== 0 && (
          <Pagination
            style={{ textAlign: "center", marginTop: "5%", color: "black" }}
            responsive={true}
            defaultCurrent={1}
            total={table.total}
            showSizeChanger={true}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} dari ${total} data`
            }
            onChange={this.onChange}
          />
        )}
      </React.Fragment>
    );
  }
}
export default AnnouncementTable;
