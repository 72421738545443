import React from "react";
import { Tabs, Card } from "antd";
import Participant from "./Participant/Participants";
import School from "./School/School";

const { TabPane } = Tabs;

function callback(key) {}

class Index extends React.Component {
  render() {
    return (
      <React.Fragment>
       
          <div style={{ marginTop: 20 }}>
            <Card
              style={{
                
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Peserta" key="1">
                  <Participant />
                </TabPane>
                <TabPane tab="Sekolah" key="2">
                  <School />
                </TabPane>
                {
                // <TabPane tab="Peserta Olimpiade" key="3">
                  // <Olympiad/>
                // </TabPane>
                }
              </Tabs>
            </Card>
          </div>
     
      </React.Fragment>
    );
  }
}
export default Index;
