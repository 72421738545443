import React from 'react';

import { Menu, Dropdown, Space } from 'antd';
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';

class Action extends React.Component {
  state = {
    category: this.props.category
}
  editCategory = () => {
    this
        .props
        .edit(this.state.category)
}

deleteCategory = () => {
  this
    .props
    .delete(this.state.category)
}

componentDidUpdate(prevProps, prevState) {
  if(prevProps.category !== this.props.category) {
      this.setState({category: this.props.category})
  }
}
  render() {
    return (
      <Space wrap>
        <Dropdown.Button onClick={e => this.editCategory(e)} placement="bottomCenter" icon={<DownOutlined />}
          overlay={<Menu>
            <Menu.Item key="1" icon={<DeleteOutlined />} onClick={e => this.deleteCategory(e)}>
              Hapus
          </Menu.Item>

          </Menu>}

        >
          Lihat Detail
    </Dropdown.Button>

      </Space>
    )
  }
}

export default Action;