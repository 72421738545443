import React from "react";
import { Modal, Form, Input, Button, Row, Col } from "antd";
import { categoryService } from "../../../../Service/category.services";

class ManageModal extends React.Component {
  state = {
    data: this.props.category,
    isOpen: false,
    isLoading: false,
    name: "",
    description: "",
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  addCategory = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.data) {
      categoryService
        .editCategory(this.state.data.id, {
          name: this.state.name,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      categoryService
        .addCategory({
          name: this.state.name,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
            });
            this.props.onUpdated({
              isUpdated: false,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      if (this.props.category)
        this.setState({
          isLoading: false,
          data: this.props.category,
          name: this.props.category.name,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          name: "",
          description: "",
        });
    }
  }
  render() {
    return (
      <Modal
        title={this.state.data ? "Edit Kategori" : "Tambah Kategori"}
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.isLoading}
            onClick={(e) => this.addCategory(e)}
          >
            {this.state.data ? "Edit Kategori" : "Tambah Kategori"}
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Row style={{ marginTop: 16 }} gutter={[16]}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Nama <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Nama"
                type="text"
                value={this.state.name}
                id="name"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
