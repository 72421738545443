import React from "react";
import { Modal, Form, Input, Button, Row, Col, Select, Collapse } from "antd";
import { lineService } from "../../../../Service/line.services";
import { categoryService } from "../../../../Service/category.services";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class ManageModal extends React.Component {
  state = {
    data: this.props.category,
    isOpen: false,
    isLoading: false,
    id: "",
    name: "",
    description: "",
    line_category_id: -1,
    requirement: "",
    additional: "",
    categories: [],
    participantLinks: [],
    studentLinks: [],
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  addCategory = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const participantLinksSubmit = this.state.participantLinks.map(
      (item) => item.link
    );
    const studentLinksSubmit = this.state.studentLinks.map((item) => item.link);
    if (this.state.data) {
      lineService
        .editLine(this.state.data.id, {
          name: this.state.name,
          line_category_id: this.state.line_category_id,
          additional: this.state.additional,
          requirement: this.state.requirement,
          description: this.state.description,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      lineService
        .addLine({
          name: this.state.name,
          line_category_id: this.state.line_category_id,
          additional: this.state.additional,
          requirement: this.state.requirement,
          description: this.state.description,
          participant_wag_link: participantLinksSubmit,
          student_wag_link: studentLinksSubmit,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
              participantLinks: [],
              studentLinks: [],
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
              participantLinks: [],
              studentLinks: [],
            });
            this.props.onUpdated({
              isUpdated: false,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      if (this.props.category) {
        this.setState({
          isLoading: false,
          data: this.props.category,
          id: this.props.category.id,
          name: this.props.category.name,
          line_category_id: this.props.category.line_category_id,
          additional: this.props.category.additional,
          requirement: this.props.category.requirement,
          description: this.props.category.description,
        });
        // lineService
        //   .getLineLinkParticipants(this.props.category.id)
        //   .then((resp) => {
        //     let participantDefault = resp?.data.map((o) => {
        //       return { id: o.id, link: o.link };
        //     });
        //     this.setState({ participantLinks: participantDefault });
        //   });
        // lineService.getLineLinkStudents(this.props.category.id).then((resp) => {
        //   let studentDefault = resp?.data.map((o) => {
        //     return { id: o.id, link: o.link };
        //   });
        //   this.setState({ studentLinks: studentDefault });
        // });
      } else
        this.setState({
          isLoading: false,
          name: "",
          line_category_id: -1,
          additional: "",
          requirement: "",
          description: "",
        });
    }
  }

  addParticipantLinks() {
    const links = this.state.participantLinks;
    const link = [{ id: 0, link: "" }];
    links.push(link);
    this.setState({
      participantLinks: links,
    });
  }
  onChangeParticipants(index, value) {
    const newIds = this.state.participantLinks.slice();
    newIds[index].link = value;
    this.setState({ participantLinks: newIds });
  }
  onDeleteParticipantLinks(index, id) {
    if (this.state.data) {
      lineService.deleteLineLinks(id.id).then(
        (resp) => {
          this.toggleModal();
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
        },
        (err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
        }
      );
    } else {
      const dataDelete = this.state.participantLinks.filter(function (_, i) {
        return i !== index;
      });
      this.setState({ participantLinks: dataDelete });
    }
  }

  addStudentLinks() {
    const links = this.state.studentLinks;
    const link = [{ id: 0, link: "" }];
    links.push(link);
    this.setState({
      studentLinks: links,
    });
  }
  onChangeStudents(index, value) {
    const newIds = this.state.studentLinks.slice();
    newIds[index].link = value;
    this.setState({ studentLinks: newIds });
  }
  onDeleteStudentLinks(index, id) {
    if (this.state.data) {
      lineService.deleteLineLinks(id.id).then(
        (resp) => {
          this.toggleModal();
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
        },
        (err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
        }
      );
    } else {
      const dataDelete = this.state.studentLinks.filter(function (number, i) {
        return i !== index;
      });
      this.setState({ studentLinks: dataDelete });
    }
  }
  onSubmitLinksPartial(type, item) {
    if (item.length > 0 && item[0].id === 0) {
      lineService
        .addLineLinks({
          line_id: this.props.category.id,
          type: type,
          link: item.link,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      lineService
        .editLineLinks(item.id, {
          line_id: this.props.category.id,
          type: type,
          link: item.link,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    }
  }
  componentDidMount() {
    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "categoryId", label: item.name, value: item.id };
      });
      data.unshift({ name: "categoryId", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data });
    });
  }

  render() {
    return (
      <Modal
        title={this.state.data ? "Edit Jalur" : "Tambah Jalur"}
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.isLoading}
            onClick={(e) => this.addCategory(e)}
          >
            {this.state.data ? "Edit Jalur" : "Tambah Jalur"}
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Row style={{ marginTop: 16 }} gutter={[16]}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Nama <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Nama"
                type="text"
                value={this.state.name}
                id="name"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Col>
          </Row>
          <Row gutter={[12]} style={{ marginTop: 12 }}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Kategori Jalur <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Semua Kategori"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ line_category_id: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this.state.line_category_id}
              >
                {this.state.categories.map((item) => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row style={{ marginTop: 16 }} gutter={[16]}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Berkas yang Diperlukan <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Berkas yang Diperlukan"
                type="text"
                value={this.state.requirement}
                id="name"
                onChange={(e) => this.setState({ requirement: e.target.value })}
              />
            </Col>
          </Row>
          {/* <Row style={{ marginTop: 16 }} gutter={[16]}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Berkas Tambahan <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Berkas Tambahan"
                type="text"
                value={this.state.additional}
                id="name"
                onChange={(e) => this.setState({ additional: e.target.value })}
              />
            </Col>
          </Row> */}
          <Row style={{ marginTop: 16 }} gutter={[16]}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Deskripsi <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Deskripsi"
                type="text"
                value={this.state.description}
                id="name"
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </Col>
          </Row>
          {/* <Row style={{ marginTop: 16, marginLeft: -10 }} gutter={30}>
            <Collapse
              style={{ width: "100%" }}
              expandIconPosition="right"
              ghost={true}
            >
              <Collapse.Panel
                header={
                  <label>
                    Link Grup WA Peserta{" "}
                    <span style={{ color: "red" }}> *</span>
                  </label>
                }
                key="1"
              >
                <Button
                  block
                  type="dashed"
                  onClick={() => this.addParticipantLinks()}
                >
                  Tambah Link WA Peserta
                </Button>
                {this.state.participantLinks?.map((item, index) => (
                  <Row gutter={[20]}>
                    <Col md={21}>
                      <Input
                        style={{ marginTop: 16 }}
                        placeholder={`Link Grup WA ${index + 1}`}
                        type="text"
                        value={item.link}
                        id="name"
                        onChange={(e) =>
                          this.onChangeParticipants(index, e.target.value)
                        }
                      />
                    </Col>
                    {this.state.data && (
                      <Col md={1}>
                        <SaveOutlined
                          onClick={() =>
                            this.onSubmitLinksPartial("participant", item)
                          }
                          style={{
                            marginTop: 20,
                            fontSize: 22,
                            color: "#1890ff",
                            cursor: "pointer",
                          }}
                        />
                      </Col>
                    )}
                    <Col md={1}>
                      <DeleteOutlined
                        onClick={() =>
                          this.onDeleteParticipantLinks(index, item)
                        }
                        style={{
                          marginTop: 20,
                          fontSize: 22,
                          marginLeft: 10,
                          color: "#1890ff",
                        }}
                      />
                    </Col>
                  </Row>
                ))}
              </Collapse.Panel>
            </Collapse>
          </Row>

          <Row style={{ marginLeft: -10 }} gutter={30}>
            <Collapse
              style={{ width: "100%" }}
              expandIconPosition="right"
              ghost={true}
            >
              <Collapse.Panel
                header={
                  <label>
                    Link Grup WA Siswa <span style={{ color: "red" }}> *</span>
                  </label>
                }
                key="1"
              >
                <Button
                  block
                  type="dashed"
                  onClick={() => this.addStudentLinks()}
                >
                  Tambah Link WA Siswa
                </Button>
                {this.state.studentLinks?.map((item, index) => (
                  <Row gutter={[20]}>
                    <Col md={21}>
                      <Input
                        style={{ marginTop: 16 }}
                        placeholder={`Link Grup WA ${index + 1}`}
                        type="text"
                        value={item.link}
                        id="name"
                        onChange={(e) =>
                          this.onChangeStudents(index, e.target.value)
                        }
                      />
                    </Col>
                    {this.state.data && (
                      <Col md={1}>
                        <SaveOutlined
                          onClick={() =>
                            this.onSubmitLinksPartial("student", item)
                          }
                          style={{
                            marginTop: 20,
                            fontSize: 22,

                            color: "#1890ff",
                          }}
                        />
                      </Col>
                    )}
                    <Col md={1}>
                      <DeleteOutlined
                        onClick={() => this.onDeleteStudentLinks(index, item)}
                        style={{
                          marginTop: 20,
                          fontSize: 22,
                          marginLeft: 10,
                          color: "#1890ff",
                        }}
                      />
                    </Col>
                  </Row>
                ))}
              </Collapse.Panel>
            </Collapse>
          </Row> */}
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
