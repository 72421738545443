// import { authHeader } from '../_helpers';
import { mainConfig } from "../Config/mainConfig";
import { authHeader, handleResponse } from "./auth.header";

export const scoreService = {
  getScore,
  importScore,
  editScore,
  deleteScore,
  getStatusImport,
  viewStatusImport,
};

function getScore(page, limit, search, lineId, status, userId, force = false, type = '') {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${
      mainConfig.apiUrl
    }/score?page=${page}&per_page=${limit}&search=${search}&line_id=${lineId}&status=${status}&user_id=${userId}&force=${force ? 1 : 0}&type=${type}`,
    reqOpt
  ).then(handleResponse);
}
function importScore(data) {
  const reqOpt = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/score/import`, reqOpt).then(
    handleResponse
  );
}
function viewStatusImport(data) {
  const reqOpt = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/score/import-status`, reqOpt).then(
    handleResponse
  );
}

function getStatusImport(page, limit) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/score/import-status?page=${page}&per_page=${limit}`,
    reqOpt
  ).then(handleResponse);
}

function editScore(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/score/${id}`, reqOpt).then(handleResponse);
}
function deleteScore(id) {
  const reqOpt = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/score/${id}`, reqOpt).then(handleResponse);
}
