import { mainConfig } from '../Config/mainConfig';
import { authHeader, handleResponse} from './auth.header';

export const SettingService = {
   getSetting,
   editSetting,
   getBanner,
   createSession

};

function getSetting() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/setting/`, reqOpt).then(handleResponse)
}
function getBanner() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/setting/banner`, reqOpt).then(handleResponse)
}

function editSetting(data){
    let file = data.image
    delete data.image
    const formData = new FormData()
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    if(file) formData.append('banner_image', file)
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(true),
        body: formData
    }

    return fetch(`${mainConfig.apiUrl}/setting/`, reqOpt).then(handleResponse)
}

function createSession(data){
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/setting/new-session`, reqOpt).then(handleResponse)
}


