import React from "react";
import { Modal, Form, Input, Button } from "antd";

import { participantService } from "../../../Service/participant.service";

class ManageModal extends React.Component {
  state = {
    isOpen: false,
    name: "",
    data: null,
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  handleSubmit = (e) => {
    participantService
      .addSchool({
        name: this.state.name,
      })
      .then(
        (resp) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
            name: "",
          });
          this.props.onUpdated(resp);
        },
        (error) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
            name: "",
          });
          this.props.onUpdated({
            isUpdated: false,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  render() {
    return (
      <Modal
        title="Tambah Sekolah"
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        footer={null}
        style={{ width: "100%", resize: "none" }}
        onCancel={this.handleCancel}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item
            label="Nama Sekolah"
            name="name"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
            rules={[
              {
                required: true,
                message: "Masukkan Nama Sekolah!",
              },
            ]}
          >
            <Input placeholder="Nama Sekolah" name="name" />
          </Form.Item>

          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={this.handleSubmit}
          >
            Tambah Sekolah
          </Button>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
