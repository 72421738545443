import React from "react";

import { Row, Col, Button } from "antd";
import { toast } from "react-toastify";
import ParticipantsTable from "./ParticipantsTable";
import { participantService } from "../../../../Service/participant.service";
import DeleteModal from "../../../../Component/Modal/DeleteModal";
import { history } from "../../../../History";
import { lineService } from "../../../../Service/line.services";
import DownloadModal from "./DownloadModal";

class Participant extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalDelete = React.createRef();
    this._modalDownload = React.createRef();
  }

  state = {
    user: null,
    reload: false,
    lines: [],
  };

  addParticipants = () => {
    history.push({
      pathname: "/profile/admin/participant/add",
      state: {
        data: null,
      },
    });
  };

  toggleModalDelete = () => {
    this._modalDelete.current.toggleModal();
  };

  toggleModaDownload = () => {
    this._modalDownload.current.toggleModal();
  };

  download = (user) => {
    this.setState({ user: user, reload: false });
    this.toggleModaDownload();
  };

  delete = (user) => {
    this.setState({ user: user, reload: false });
    this.toggleModalDelete();
  };

  deleteParticipant = (id) => {
    participantService.deleteParticipant(id).then(
      (resp) => {
        this.toggleModalDelete();
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
        this.setState({ user: null, reload: true });
      },
      (err) => {
        this.toggleModalDelete();
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };
  componentDidMount() {
    lineService.getLine(1).then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "lineId", label: item.name, value: item.id };
      });
      data.unshift({ name: "lineId", label: "Semua Jalur", value: -1 });
      this.setState({ lines: data });
    });
  }
  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({ payment: null, reload: true });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };
  render() {
    return (
      <React.Fragment>
        <Row style={{ marginTop: 16 }} gutter={[16]}>
          <Col xs={24} sm={24} md={19} lg={19}>
            <p className="font-medium-1 text-bold-700">Daftar Peserta</p>
          </Col>
          <Col xs={24} sm={24} md={5} lg={5}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={this.addParticipants}
            >
              Tambah Peserta
            </Button>
          </Col>
        </Row>
        <ParticipantsTable
          reload={this.state.reload}
          edit={this.edit}
          delete={this.delete}
          download={this.download}
          lines={this.state.lines}
        />

        {/* <ManageModal
          ref={this._modalManage}
          onUpdated={this.onUpdated}
          user={this.state.user}
          categories={this.state.categories}
        /> */}

        <DeleteModal
          ref={this._modalDelete}
          id={this.state.user ? this.state.user.id : -1}
          name={this.state.user ? this.state.user.name : ""}
          onDeleted={this.deleteParticipant}
        />
        <DownloadModal
          ref={this._modalDownload}
          user={this.state.user}
          onUpdated={this.onUpdated}
        />
      </React.Fragment>
    );
  }
}

export default Participant;
