import React from "react";

import { Layout, Menu, Button } from "antd";
import { Link } from "react-router-dom";
import LoginModal from "../Component/Modal/LoginModal";
import LogoutModal from "./Modal/LogoutModal";
import { jwtDecode } from "jwt-decode";

const { Header } = Layout;

class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalLogout = React.createRef();
  }
  state = {
    user_role: "",
  };

  logoutModal = () => {
    this._modalLogout.current.toggleModal();
  };

  showModal = () => {
    this._modalManage.current.toggleModal();
  };
  componentDidMount() {
    if (localStorage.length > 0) {
      let userToken = localStorage.getItem("USER_TOKEN");
      let userData = jwtDecode(userToken);
      this.setState({ user_role: userData?.role });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userData !== this.props.userData) {
      this.componentDidMount(true);
    }
  }
  render() {
    return (
      <React.Fragment>
        <Layout>
          <Header
            className="header"
            style={{ position: "fixed", zIndex: 1, width: "100%" }}
          >
            <div className="logo" />
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={[
                window.location.pathname.search("dashboard") > -1
                  ? "1"
                  : window.location.pathname.search("announcement") > -1
                  ? "2"
                  : window.location.pathname.search("profile") > -1
                  ? "3"
                  : "",
              ]}
            >
              <Menu.Item key="1">
                Beranda
                <Link to={"/dashboard"}></Link>
              </Menu.Item>
              <Menu.Item key="2">
                Pengumuman
                <Link to={"/announcement"} />
              </Menu.Item>

              {this.state.user_role === "" ? (
                <Menu.Item
                  disabled={true}
                  style={{ backgroundColor: "transparent", cursor: "auto" }}
                  key="4"
                >
                  <Button
                    // style={{ marginLeft: "20px" }}
                    type="primary"
                    onClick={(e) => this.showModal()}
                  >
                    Masuk
                  </Button>
                </Menu.Item>
              ) : (
                <>
                  <Menu.Item key="3">
                    Profil
                    <Link to={"/profile/"} />
                  </Menu.Item>
                  <Menu.Item
                    disabled={true}
                    style={{ backgroundColor: "transparent", cursor: "auto" }}
                    key="4"
                  >
                    <Button
                      // style={{ marginLeft: "20px" }}
                      type="primary"
                      danger
                      onClick={(e) => this.logoutModal()}
                    >
                      Keluar
                    </Button>
                  </Menu.Item>
                </>
              )}
            </Menu>
          </Header>
        </Layout>
        <LoginModal ref={this._modalManage} />
        <LogoutModal ref={this._modalLogout} />
      </React.Fragment>
    );
  }
}
export default AppHeader;
