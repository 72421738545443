import { mainConfig } from "../Config/mainConfig";
import { authHeader, handleResponse } from "./auth.header";

export const participantService = {
  getFileProgress,
  getProgress,
  getParticipant,
  getParticipantOlymid,
  getBiodata,
  getUser,
  getProfileImage,
  editUser,
  addParticipant,
  addFromOlympiad,
  editParticipant,
  deleteParticipant,
  getSchool,
  addSchool,
  deleteSchool,
  editSchool,
  uploadFile,
  getFileStatus,
  getListFile,
  updateStatus,
  downloadFile
};
function getProgress(id) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/user/progress/${id}`, reqOpt).then(
    handleResponse
  );
}
function getFileProgress(id) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/user/file-progress/${id}`, reqOpt).then(
    handleResponse
  );
}
function getProfileImage(id) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/user/image/${id}`, reqOpt).then(
    handleResponse
  );
}
function getParticipant(page = 1, limit = 0, lineId = -1, search, asc = false, class_type = '', is_mahad = '') {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${
      mainConfig.apiUrl
    }/user?page=${page}&per_page=${limit}&line_id=${lineId}&search=${search}&desc_sort=${asc ? 1 : 0}&class_type=${class_type}&is_mahad=${is_mahad}`,
    reqOpt
  ).then(handleResponse);
}

function getParticipantOlymid(page = 1, limit = 0, search) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/user/olymp?page=${page}&per_page=${limit}&search=${search}`,
    reqOpt
  ).then(handleResponse);
}

function getUser(id) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/user/${id}`, reqOpt).then(handleResponse);
}
function addParticipant(data, file) {
  delete data.user_id;
  delete data.district;
  delete data.city;
  delete data.registration_number;
  delete data.old_password;
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  if (file) formData.append("profile_image", file);

  const reqOpt = {
    method: "POST",
    headers: authHeader(true),
    body: formData,
  };

  return fetch(`${mainConfig.apiUrl}/user`, reqOpt).then(handleResponse);
}
function addFromOlympiad(data) {
  const reqOpt = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/user/add-from-olymp`, reqOpt).then(
    handleResponse
  );
}
function editParticipant(id, body, file) {
  delete body.user_id;
  delete body.district;
  delete body.city;
  delete body.registration_number;
  const formData = new FormData();
  Object.keys(body).forEach((key) => formData.append(key, body[key]));
  if (file) formData.append("profile_image", file);

  const reqOpt = {
    method: "PUT",
    headers: authHeader(true),
    body: formData,
  };

  return fetch(`${mainConfig.apiUrl}/user/${id}`, reqOpt).then(handleResponse);
}

function uploadFile(id, body, file) {
  const formData = new FormData();
  Object.keys(body).forEach((key) => formData.append(key, body[key]));

  const reqOpt = {
    method: "POST",
    headers: authHeader(true),
    body: formData,
  };

  return fetch(`${mainConfig.apiUrl}/file/`, reqOpt).then(handleResponse);
}

function getBiodata(id) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/user/${id}`, reqOpt).then(handleResponse);
}

// function addContact(data){
//     const reqOpt = {
//         method: 'POST',
//         headers: authHeader(),
//         body: JSON.stringify(data)
//     }

//     return fetch(`${mainConfig.apiUrl}/contact`, reqOpt).then(handleResponse)
// }
function editUser(data, file, id) {
  delete data.line_id;
  delete data.line;
  delete data.line_category;
  delete data.admission_id;
  delete data.line_category_id;
  delete data.updated_at;
  delete data.school;
  delete data.province;
  delete data.city;
  delete data.district;
  delete data.olympiad_id;
  delete data.id;
  delete data.profile_image;
  delete data.registration_number;
  delete data.username;
  delete data.created_at;
  delete data.school_participant_group_link;
  delete data.mahad_participant_group_link;
  delete data.school_student_group_link;
  delete data.mahad_student_group_link;
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  if (file) formData.append("profile_image", file);

  const reqOpt = {
    method: "PUT",
    headers: authHeader(true),
    body: formData,
  };

  return fetch(`${mainConfig.apiUrl}/user/${id}`, reqOpt).then(handleResponse);
}
function deleteParticipant(id) {
  const reqOpt = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/user/${id}`, reqOpt).then(handleResponse);
}

function getSchool(page, limit, value) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/school?page=${page}&per_page=${limit}&search=${value}`,
    reqOpt
  ).then(handleResponse);
}
function addSchool(data) {
  const reqOpt = {
    method: "POST",
    headers: {
      pragma: "no-cache",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/school`, reqOpt).then(handleResponse);
}
function deleteSchool(id) {
  const reqOpt = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/school/${id}`, reqOpt).then(
    handleResponse
  );
}
function editSchool(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/school/${id}`, reqOpt).then(
    handleResponse
  );
}

function getFileStatus(line_id, user_id) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/file/status?line_id=${line_id}&user_id=${user_id}`,
    reqOpt
  ).then(handleResponse);
}
function getListFile(page, limit, status, lines, value) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/file?page=${page}&per_page=${limit}&file_status=${status}&line_id=${lines}&search=${value}`,
    reqOpt
  ).then(handleResponse);
}
function updateStatus(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/file/${id}`, reqOpt).then(handleResponse);
}

function downloadFile(user_id, line_id, type) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/file/download?user_id=${user_id}&line_id=${line_id}&type=${type}`, reqOpt).then(handleResponse);
}
