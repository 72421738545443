import { mainConfig } from "../Config/mainConfig";
import { authHeader, handleResponse } from "./auth.header";

export const paymentService = {
  getPayment,
  getPaymentStatus,
  createPayment,
  getPaymentsByUser,
  getImage,
  updatePaymentStatus,
};

function getPayment(page, limit, value, paymentStatus, startDate, endDate) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/payment?page=${page}&per_page=${limit}&search=${value}&payment_status=${paymentStatus}&start_date=${startDate}&end_date=${endDate}`,
    reqOpt
  ).then(handleResponse);
}

function getPaymentStatus() {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/payment/status`, reqOpt).then(
    handleResponse
  );
}
function getPaymentsByUser(id) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/payment/user/${id}`, reqOpt).then(
    handleResponse
  );
}
function createPayment(data) {
  let file = data.image;
  delete data.image;
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  if (file) formData.append("proof_document", file);
  const reqOpt = {
    method: "POST",
    headers: authHeader(true),
    body: formData,
  };

  return fetch(`${mainConfig.apiUrl}/payment`, reqOpt).then(handleResponse);
}
function getImage(id) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/user/image/${id}`, reqOpt).then(
    handleResponse
  );
}
function updatePaymentStatus(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/payment/confirmation/${id}`, reqOpt).then(
    handleResponse
  );
}
