import React from "react";
import { Modal, Input, Button, Row, Col } from "antd";
import { participantService } from "../../../../Service/participant.service";
import { Space } from "antd";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

class ManageModal extends React.Component {
  state = {
    data: this.props.data,
    user_id: this.props.user_id,
    payment_amount: 0,
    payment_due: 0,
    isLoading: false,
    payment_note: null,
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }
  handleSubmit = () => {
    let body = this.state.data;
    body["payment_offline_approve"] = true;
    body["payment_amount"] = this.state.payment_amount;
    body["payment_due"] = this.state.payment_due;
    body["payment_note"] = this.state.payment_note ?? "";
    this.setState({ isLoading: true });
    participantService.editParticipant(body.user_id, body).then(
      (resp) => {
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
          onClose: () => {
            this.setState({
              isOpen: false,
              isLoading: false,
              // data: [],
            });
            // history.push("/profile/admin/participant/edit");
          },
        });
      },
      (error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
          onClose: () => {
            this.setState({
              isOpen: false,
              isLoading: false,
              // data: [],
            });
            // history.push("/profile/admin/participant/edit");
          },
        });
      }
    );
  };
  format = (num, currency, minFraction, maxFraction) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: currency || "IDR",

      minimumFractionDigits: minFraction,
      maximumFractionDigits: maxFraction,
    });
    return formatter.format(num);
  };
  formatCurrency = (num) => {
    if (num % 1 === 0) {
      return this.format(num, undefined, 0, 2);
    } else {
      return this.format(num, undefined, 2, undefined);
    }
  };
  render() {
    return (
      <Modal
        title="Daftar Ulang"
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={(e) => this.handleSubmit()}
            loading={this.state.isLoading}
          >
            Daftar Ulang
          </Button>,
        ]}
      >
        <Row>
          <Col span={6}>
            <label>Jumlah Dibayar </label>
          </Col>
          <Col span={17}>
          <NumericFormat
                thousandSeparator={true}
                onValueChange={(e) =>
                  this.setState({
                    payment_amount: e.floatValue ? e.floatValue : 0,
                  })
                }
                value={this.state.payment_amount}
                customInput={Input}
              />
          </Col>
        </Row>
        <Space />
        <Row style={{ marginTop: 24 }}>
          <Col span={6}>
            <label>Sisa Pembayaran</label>
          </Col>
          <Col span={17}>
          <NumericFormat
                thousandSeparator={true}
                onValueChange={(e) =>
                  this.setState({
                    payment_due: e.floatValue ? e.floatValue : 0,
                  })
                }
                value={this.state.payment_due}
                customInput={Input}
              />
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col span={6}>
            <label>Catatan</label>
          </Col>
          <Col span={17}>
            <Input
              placeholder='Isi dengan detail pembayaran'
              value={this.state.payment_note}
              onChange={(e) => {
                this.setState({ payment_note: e.target.value });
              }}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default ManageModal;
