import React from "react";
import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  Row, Col, Button, Card, Divider, Input, Tooltip, Select,
} from "antd";
import { toast } from "react-toastify";
import DropzoneZip from "../../../Component/File/ZipDropzone";
import { participantService } from "../../../Service/participant.service";
import { lineService } from "../../../Service/line.services";
// import ReactToPrint from "react-to-print";
// import { mainConfig } from "../../../Config/mainConfig";
import moment from "moment";
import { phaseService } from "../../../Service/phase.services";
import { history } from "../../../History";
const { TextArea } = Input;

const { Option } = Select;

// class PrintComponent extends React.Component {
//   constructor(props) {
//     super(props);
//     this._infoModal = React.createRef();
//   }

//   state = {
//     user_id: 0,
//     data: [],
//     year: "",
//   };

//   componentDidMount() {
//     var date = new Date();
//     var convert = moment(date, "DD/MM/YYYY").year();
//     this.setState({ year: convert });
//     window.scrollTo(0, 0);
//     let userData = localStorage.getItem("USER_DATA");
//     userData = JSON.parse(userData);
//     this.setState({ user_id: userData.id });
//     participantService.getUser(userData.id).then(
//       (resp) => {
//         if (resp.status) {
//           this.setState({
//             data: resp.data,
//             total: resp.total,
//             isLoading: false,
//           });
//         }
//       },
//       (error) => {
//         this.setState({ isLoading: false });
//       }
//     );
//   }

//   render() {
//     let classTypes = {
//       reguler: 'Reguler',
//       superior: 'Unggulan',
//       excelent: 'Excelent (Tahfidz)'
//     }

//     return (
//       <React.Fragment>
//         <div className="print-source">
//           <Card
//           // style={{
//           //   width: "794px",
//           //   height: "550px",
//           //   marginLeft: "1%",
//           //   marginRight: "1%",
//           //   marginTop: "1%",
//           //   marginBottom: "1%",
//           //   paddingBottom: 0,
//           // }}
//           >
//             <Row align="middle" gutter={[16]}>
//               <Col span={4} style={{ textAlign: "right", marginBottom: 16 }}>
//                 <img
//                   src={
//                     window.location.protocol +
//                     "//" +
//                     window.location.host +
//                     "/logomts.png"
//                   }
//                   style={{ width: "45%" }}
//                   alt="Logo Matsanela"
//                 />
//               </Col>
//               <Col span={14}>
//                 <p
//                   style={{
//                     textAlign: "left",
//                     fontSize: "9pt",
//                     fontWeight: "bold",
//                     marginLeft: "3%",
//                   }}
//                 >
//                   KARTU PESERTA PPDB <br />
//                   MTs Negeri 1 Lamongan <br />
//                   {this.state.year}
//                 </p>
//               </Col>
//               {/* <Col span={6} style={{ textAlign: "left" }}>
//                 <Barcode
//                   width={1}
//                   height={40}
//                   displayValue={false}
//                   value={this.state.data.registration_number}
//                 />
//               </Col> */}
//             </Row>
//             <Divider
//               style={{ borderWidth: "3px", borderColor: "#000000", margin: 0 }}
//             ></Divider>
//             <Row>
//               <Col
//                 span={20}
//                 style={{
//                   textAlign: "left",
//                   marginTop: "48px",
//                 }}
//               >
//                 <Row>
//                   <Col span={6}>
//                     <label>NISN</label>
//                   </Col>
//                   <Col span={17}>
//                     <Input value={this.state && this.state.data.nisn} />
//                   </Col>
//                 </Row>
//                 <Row style={{ marginTop: "2%" }}>
//                   <Col span={6}>
//                     <label>Nama</label>
//                   </Col>
//                   <Col span={17}>
//                     <Input value={this.state && this.state.data.name} />
//                   </Col>
//                 </Row>
//                 <Row style={{ marginTop: "2%" }}>
//                   <Col span={6}>
//                     <label>Nomor Peserta</label>
//                   </Col>
//                   <Col span={17}>
//                     <Input
//                       value={this.state && this.state.data.registration_number}
//                     />
//                   </Col>
//                 </Row>
//               </Col>
//               <Col span={3} style={{ marginTop: "4%" }}>
//                 <img
//                   style={{ width: "113.38582677px", height: "113.38582677px" }}
//                   src={`${mainConfig.apiUrl}/user/image/${this.state.user_id}`}
//                   alt="Foto Profil Peserta"
//                 />
//               </Col>
//             </Row>
//             <Row style={{ marginTop: "2%", textAlign: "left" }}>
//               <Col span={5}>
//                 <label>Asal Sekolah</label>
//               </Col>
//               <Col span={19}>
//                 <Input value={this.state && this.state.data.school} />
//               </Col>
//             </Row>
//             <Row style={{ marginTop: "2%", textAlign: "left" }}>
//               <Col span={5}>
//                 <label>Jalur</label>
//               </Col>
//               <Col span={19}>
//                 <Input value={`${this.state?.data?.line} (${this.state?.data?.line_category})`} />
//               </Col>
//             </Row>
//             <Row style={{ marginTop: "2%", textAlign: "left" }}>
//               <Col span={5}>
//                 <label>Pilihan Kelas</label>
//               </Col>
//               <Col span={19}>
//                 <Input value={classTypes[this.state?.data?.class_type]} />
//               </Col>
//             </Row>
//             <Row style={{ marginTop: "2%", textAlign: "left" }}>
//               <Col span={5}>
//                 <label>Pilihan Mahad</label>
//               </Col>
//               <Col span={19}>
//                 <Input value={this.state?.data?.is_mahad ? 'Ya' : 'Tidak'} />
//               </Col>
//             </Row>
//             <Row style={{ marginTop: "2%", textAlign: "left" }}>
//               <Col span={5}>
//                 <label>Alamat</label>
//               </Col>
//               <Col span={19}>
//                 <Input
//                   value={
//                     this.state &&
//                     this.state.data.district + ", " + this.state.data.city
//                   }
//                 />
//               </Col>
//             </Row>
//             <Row style={{ marginTop: "4%", textAlign: "left" }}>
//               <Col>
//                 <p className="font-small-2" padding="0" margin="0">
//                   Gunakan username <b>CBT{this.state.data.nisn}</b> dan password{" "}
//                   <b>{this.state.data.registration_number}</b> untuk login CBT.
//                 </p>
//               </Col>
//             </Row>
//           </Card>
//         </div>
//       </React.Fragment>
//     );
//   }
// }
class UploadFile extends React.Component {
  state = {
    user_id: 0,
    line_id: 0,
    file_progress: [],
    file_status: [],
    data: [],
    body: {
      requirement_file: "",
      additional_file: "",
    },
    status: "",
    isLoading: false,
    requirement_text: "",
    additional_text: "",
    is_add_uploaded: "",
    is_req_uploaded: "",
    line: null,
    phase: [],
    lines: [],
    progress: 1,
    can_upload: true
  };
  dropRequirement = (files) => {
    let body = this.state.body;
    body.requirement_file = files[0];
    this.setState({ body });
  };
  dropAdditional = (files) => {
    let body = this.state.body;
    body.additional_file = files[0];
    this.setState({ body });
  };

  onSelectLine(lineId) {
    if (lineId > 0) {
      lineService.getLineId(lineId).then(
        (resp) => {
          if (resp.status) {
            this.setState({
              line: resp.data,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
    } else {
      this.setState({ line: null });
    }
  }

  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    let body = this.state.body;
    body["requirement_text"] = this.state.requirement_text;
    body["additional_text"] = this.state.additional_text;
    body["user_id"] = this.state.user_id;
    body["line_id"] = this.state.line_id;
    participantService.uploadFile(this.state.user_id, body).then(
      (resp) => {
        this.setState({
          isLoading: false,
        });
        toast.success(
          "Unggah pemberkasan berhasil, harap cek kembali status pemberkasan 1x24 jam, jika terverifikasi kamu bisa cetak kartu peserta disini",
          {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 4000,
          }
        );
        setTimeout(function () {
          window.location.reload();
        }, 7000);
      },
      (error) => {
        this.setState({ description: "", title: "", isLoading: false });
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    let userData = localStorage.getItem("USER_DATA");
    userData = JSON.parse(userData);
    this.setState({ user_id: userData.id});

    await participantService.getBiodata(userData.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({ line_id: resp.data.line_id, isLoading: false });
          lineService.getLineId(resp.data.line_id).then(
            (resp) => {
              if (resp.status) {
                this.setState({
                  line: resp.data,
                });
              }
            },
            (error) => {
              this.setState({ isLoading: false });
            }
          );
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    )

    await lineService.getLine().then(
      (resp) => {
        let data = resp.data.map((item) => {
          return { name: "lineId", label: `${item.name} (${item.line_category_name})`, value: item.id };
        });
        data.unshift({ name: "lineId", label: "Pilih Jalur", value: -1 });
        this.setState({ lines: data });
      },
      (error) => {
        this.setState({ isLoading: false });
      });


    await participantService.getProgress(userData.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({ progress: resp.data.progress });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );

    await phaseService.getScheduleScore(
      1,
      999999999,
      "registration",
      moment().format("YYYY-MM-DD HH:mm:ss"),
      moment().format("YYYY-MM-DD HH:mm:ss"),
    ).then(
        (resp) => {
          if (resp.status) {
            this.setState({ phase: resp.data });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );

    let resp = await participantService.getFileStatus(this.state?.line?.id ?? "", userData.id);
    let file_status;
    if (resp.status) {
      file_status = resp.data
    }

  
    // this filter let user upload new file for new line category
    if ((this.state?.progress <= 3 && this.state?.phase?.length > 0 && this.state?.phase[0]?.line_category_id !== this.state?.line?.line_category_id) || !this.state?.line?.line_category_id) {
      this.setState({
        can_upload: true, 
        status: "not-uploaded",
        file_status: {},
        line_id: null,
        line: {}
      });
    } else {
      this.setState({
        can_upload: false,
        status: file_status?.file_status ?? "",
        file_status: file_status ?? {},
      });
    }
  }

  handlePrintCard = () => {
    history.push({
      pathname: "/profile/card",
      state: {
        user_id: this.state.user_id,
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Row gutter={[24]}>
            <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
              <Card
                className="font-medium-1"
                style={{

                  borderColor:
                    this.state.status === "not-uploaded" ? "#ec4646" : "",
                  color:
                    this.state.status === "not-uploaded" ? "#ec4646" : "black",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  padding: "4% 10% 4% 10%",
                }}
              >
                Belum Diunggah
                <CloseCircleOutlined
                  style={{ marginLeft: "6%", verticalAlign: "middle" }}
                />
              </Card>
            </Col>
            <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
              <Card
                className="font-medium-1"
                style={{

                  borderColor: this.state.status === "waiting" ? "#ffcc29" : "",
                  color: this.state.status === "waiting" ? "#ffcc29" : "black",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  padding: "4% 10% 4% 10%",
                }}
              >
                Menunggu Verifikasi
                <InfoCircleOutlined
                  style={{ marginLeft: "6%", verticalAlign: "middle" }}
                />
              </Card>
            </Col>
            <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
              <Card
                className="font-medium-1"
                style={{

                  borderColor: this.state.status === "verified" ? "#00af91" : "",
                  color: this.state.status === "verified" ? "#00af91" : "black",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  padding: "4% 12% 4% 10%",
                }}
              >
                Terverifikasi
                <CheckCircleOutlined
                  style={{ marginLeft: "6%", verticalAlign: "middle" }}
                />
              </Card>
            </Col>
          </Row>
          <div style={{ marginTop: 20 }}>
            <>
              <Card
                style={{

                  overflow: "hidden",
                }}
              >
                <Row
                  style={{
                    marginTop: 12,
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <Col xs={24}
                    sm={24}
                    md={24}
                    lg={24}>
                    <p className="font-normal" style={{display: this.state?.phase?.length > 0 && this.state?.phase[0]?.line_category_id !== this.state?.line?.line_category_id ? "block" : "none"}}>*Unggah berkas ulang, jika ingin mendaftar kembali</p>
                    <p className="font-normal text-bold-700">Pilih Jalur </p>
                    <Select
                      showSearch
                      disabled={!this.state.can_upload}
                      style={{ width: "100%" }}
                      placeholder="Pilih Jalur"
                      optionFilterProp="children"
                      onChange={(e) => {
                        this.onSelectLine(e);
                        this.setState({ line_id: e });
                      }}
                      value={this.state.line_id}
                    >
                      {this.state.lines.map((item) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                {this.state.line?.id ? <>
                  <Divider />
                  <Row
                    style={{
                      marginTop: 12,
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      style={{ marginTop: "2%" }}
                    >
                      <p className="font-normal text-bold-700"> Unggah Berkas Berikut </p>
                      <p className="font-normal">
                        {" "}
                        {this.state.line && this.state.line.requirement}{" "}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      sm={10}
                      md={10}
                      lg={10}
                    >
                      {this.state.file_status.is_req_uploaded === "true" ? (
                        <p className="font-normal" style={{ color: "#1890ff" }}>
                          Berkas sudah diunggah
                          <CheckCircleOutlined
                            style={{ marginLeft: "6%", verticalAlign: "middle" }}
                          />
                        </p>
                      ) : this.state.status !== "not-uploaded" &&
                        this.state.file_status.is_req_uploaded === "false" ? (
                        <p className="font-normal" style={{ color: "#1890ff" }}>
                          Berkas Kosong
                          <CloseCircleOutlined
                            style={{ marginLeft: "6%", verticalAlign: "middle" }}
                          />
                        </p>
                      ) : (
                        <>
                          <DropzoneZip onDropped={this.dropRequirement} />
                          <p
                            className="font-small-2"
                            style={{ color: "red", marginTop: "3%" }}
                          >
                            Maksimal berkas berukuran 10MB
                          </p>
                        </>
                      )}
                    </Col>
                    <Col
                      xs={24}
                      sm={5}
                      md={5}
                      lg={5}
                    ></Col>
                    <TextArea
                      disabled={this.state.status !== "not-uploaded"}
                      style={{ width: "100%" }}
                      placeholder='Isi dengan keterangan berkas'
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      value={
                        this.state.status !== "not-uploaded"
                          ? this.state.file_status &&
                          this.state.file_status.requirement_text
                          : this.state.requirement_text
                      }
                      onChange={(e) => {
                        this.setState({ requirement_text: e.target.value });
                      }}
                    ></TextArea>
                  </Row>
                  {/* <Divider /> */}
                  {/* <Row
                    style={{
                      marginTop: 50,
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <p className="font-normal text-bold-700"> Berkas Tambahan </p>
                      <p className="font-normal ">
                        {" "}
                        {this.state.line && this.state.line.additional}{" "}
                      </p>
                      <TextArea
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        disabled={this.state.status !== "not-uploaded"}
                        style={{ width: "100%" }}
                        type="text"
                        placeholder='Isi dengan keterangan berkas, isi "Tidak ada berkas" jika tidak ada berkas yang dikirim'
                        name="nisn"
                        value={
                          this.state.status !== "not-uploaded"
                            ? this.state.file_status &&
                            this.state.file_status.additional_text
                            : this.state.additional_text
                        }
                        onChange={(e) => {
                          this.setState({ additional_text: e.target.value });
                        }}
                      ></TextArea>
                    </Col>
                    <Col
                      xs={24}
                      sm={10}
                      md={10}
                      lg={10}
                      style={{ marginTop: "2%" }}
                    >
                      {this.state.file_status.is_add_uploaded === "true" ? (
                        <p className="font-normal" style={{ color: "#1890ff" }}>
                          Berkas sudah diunggah
                          <CheckCircleOutlined
                            style={{ marginLeft: "6%", verticalAlign: "middle" }}
                          />
                        </p>
                      ) : this.state.status !== "not-uploaded" &&
                        this.state.file_status.is_add_uploaded === "false" ? (
                        <p className="font-normal" style={{ color: "#1890ff" }}>
                          Berkas Kosong
                          <CloseCircleOutlined
                            style={{ marginLeft: "6%", verticalAlign: "middle" }}
                          />
                        </p>
                      ) : (
                        <>
                          <DropzoneZip onDropped={this.dropAdditional} />
                          <p
                            className="font-small-2"
                            style={{ color: "red", marginTop: "3%" }}
                          >
                            Maksimal berkas berukuran 10MB
                          </p>
                        </>
                      )}
                    </Col>
                    <Col
                      xs={24}
                      sm={5}
                      md={5}
                      lg={5}
                      style={{ marginTop: "2%" }}
                    ></Col>
                  </Row> */}
                  <div style={{ float: "right", marginTop: "4%" }}>
                    <Row style={{ marginTop: 16, float: "right" }} gutter={[16]}>
                      <Col>
                        <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                          <Button
                            type="primary"
                            disabled={this.state.status !== "verified"}
                            onClick={this.handlePrintCard}
                          >
                            Cetak Kartu Peserta
                          </Button>
                        </Tooltip>
                      </Col>
                      {/* <Col>
                        {this.state.status !== "verified" ? (
                          <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                            <Button
                              type="primary"
                              disabled={this.state.status !== "verified"}
                              style={{
                                marginLeft: "2%",
                                borderRadius: "5%",
                              }}
                            >
                              Cetak Kartu Peserta
                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <ReactToPrint
                              pageStyle="
                              @page {
                                size: a5;
                                margin: 0;
                              }
                              "
                              trigger={() => (
                                <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                                  <Button
                                    type="primary"
                                    disabled={this.state.status !== "verified"}
                                    style={{
                                      marginLeft: "2%",
                                      borderRadius: "5%",
                                    }}
                                  >
                                    Cetak Kartu Peserta
                                  </Button>
                                </Tooltip>
                              )
                              }
                              content={() => this.componentRef}
                            />
                            <PrintComponent ref={(el) => (this.componentRef = el)} />
                          </>
                        )}
                      </Col> */}
                      <Col>
                        <Tooltip
                          title={
                            this.state.status !== "not-uploaded"
                              ? "Kamu sudah melakukan unggah berkas"
                              : ""
                          }
                        >
                          <Button
                            disabled={
                              this.state.status !== 'not-uploaded' || (this.state.status === 'not-uploaded' && !this.state.body.requirement_file)
                            }
                            loading={this.state.isLoading}
                            type="primary"
                            onClick={this.handleSubmit}
                          >
                            Unggah File
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </div></>
                  : <></>}
              </Card>
            </>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UploadFile;
