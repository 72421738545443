import React from "react";
import { Modal, Form, Input, Button, Row, Col, Select } from "antd";
import { scoreService } from "../../../Service/score.service";
import { lineService } from "../../../Service/line.services";
import { phaseService } from "../../../Service/phase.services";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

class ManageModal extends React.Component {
  state = {
    data: this.props.score,
    isOpen: false,
    phase: [],
    lines: [],
    user_id: 0,
    score: 0,
    is_passed: 0,
    line_id: -1,
    description: "",
    isLoading: false,
    total_score: "",
    document_score: "",
    rank: "",
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.score !== this.props.score) {
      if (this.props.score)
        this.setState({
          isLoading: false,
          data: this.props.score,
          user_id: this.props.score.user_id,
          is_passed: this.props.score.is_passed,
          score: this.props.score.score,
          document_score: this.props.score.document_score,
          total_score: this.props.score.total_score,
          rank: this.props.score.rank,
          line_id: this.props.score.line_id,
          description: this.props.score.description,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          is_passed: "",
          score: "",
          line_id: "",
          description: "",
          total_score: "",
          document_score: "",
          rank: "",
        });
    }
  }

  componentDidMount() {
    lineService.getLine().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "lineId", label: `${item.name} (${item.line_category_name})`, value: item.id };
      });
      data.unshift({ name: "lineId", label: "Semua Jalur", value: -1 });
      this.setState({ lines: data });
    });

    // phaseService.getScheduleScore(1, 999999999, "competition").then((resp) => {
    //   let data = resp.data.map((item) => {
    //     return { name: "phaseId", label: item.name, value: item.id };
    //   });
    //   data.unshift({ name: "phaseId", label: "Semua Jadwal", value: -1 });
    //   this.setState({ phase: data });
    // });
  }

  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();
    scoreService
      .editScore(this.state.data.id, {
        user_id: this.state.user_id,
        is_passed: this.state.is_passed,
        score: this.state.score,
        total_score: this.state.total_score,
        document_score: this.state.document_score,
        rank: this.state.rank,
        line_id: this.state.line_id,
        description: this.state.description,
      })
      .then(
        (resp) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
            is_passed: "",
            score: "",
            line_id: "",
            description: "",
            total_score: "",
            document_score: "",
            rank: "",
          });
          this.props.onUpdated(resp);
        },
        (error) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
            phase_id: "",
            is_passed: "",
            score: "",
            line_id: "",
            description: "",
            total_score: "",
            document_score: "",
            rank: "",
          });
          this.props.onUpdated({
            isUpdated: false,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  render() {
    return (
      <Modal
        title="Edit Nilai"
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            loading={this.state.isLoading}
            key="submit"
            type="primary"
            onClick={(e) => this.handleSubmit(e)}
          >
            Edit Nilai
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Nilai Tes</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Nilai tes"
                type="text"
                value={this.state.score}
                id="score"
                onChange={(e) => this.setState({ score: e.target.value })}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Nilai Piagam</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Nilai piagam"
                type="text"
                value={this.state.document_score}
                id="score"
                onChange={(e) =>
                  this.setState({ document_score: e.target.value })
                }
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Nilai Total</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Nilai total"
                type="text"
                value={this.state.total_score}
                id="score"
                onChange={(e) => this.setState({ total_score: e.target.value })}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Peringkat</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Peringkat"
                type="text"
                value={this.state.rank}
                id="score"
                onChange={(e) => this.setState({ rank: e.target.value })}
              />
            </Col>
          </Row>

          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Jalur</label>
            </Col>
            <Col span={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Pilih Jalur"
                optionFilterProp="children"
                value={this.state.line_id}
                onChange={(e) => {
                  this.setState({ line_id: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
              >
                {this.state &&
                  this.state.lines.map((item) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Status</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Status 1 jika diterima, 0 jika tidak diterima"
                type="text"
                value={this.state.is_passed}
                id="is_passed"
                onChange={(e) => this.setState({ is_passed: e.target.value })}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Keterangan</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Keterangan"
                type="text"
                value={this.state.description}
                id="description"
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
