import React from 'react';

import { Menu, Dropdown, Space } from 'antd';
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';

class Action extends React.Component {
  state = {
    data: this.props.data
}
  editWAGroup = () => {
    this
        .props
        .edit(this.state.data)
}

deleteWAGroup = () => {
  this
    .props
    .delete(this.state.data)
}

componentDidUpdate(prevProps, prevState) {
  if(prevProps.data !== this.props.data) {
      this.setState({data: this.props.data})
  }
}
  render() {
    return (
      <Space wrap>
        <Dropdown.Button onClick={e => this.editWAGroup(e)} placement="bottomCenter" icon={<DownOutlined />}
          overlay={<Menu>
            <Menu.Item key="1" icon={<DeleteOutlined />} onClick={e => this.deleteWAGroup(e)}>
              Hapus
          </Menu.Item>

          </Menu>}

        >
          Lihat Detail
    </Dropdown.Button>

      </Space>
    )
  }
}

export default Action;