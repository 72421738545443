import React from "react";
import { Card, Row, Col, Input, Button } from "antd";
import { SettingService } from "../../../../Service/setting.services";
import { toast } from "react-toastify";
import Editor from 'react-simple-wysiwyg';

class Description extends React.Component {
  state = {
    data: this.props.data,
    title: "",
    description: "",
    isLoading: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data)
        this.setState({
          isLoading: false,
          data: this.props.data,
          title: this.props.data.about_title,
          description: this.props.data.about_description,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          title: "",
          description: "",
        });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    SettingService.editSetting({
      about_title: this.state.title,
      about_description: this.state.description,
    }).then(
      (resp) => {
        this.setState({
          isLoading: false,
        });
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      },
      (error) => {
        this.setState({ description: "", title: "", isLoading: false });
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };

  render() {
    return (
      <>
        <Card
          loading={this.state.isLoading}
          style={{
            
            height: "100%",
            overflow: "hidden",
            marginTop: "2%",
          }}
        >
          <p className="font-medium-1 text-bold-700"> Deskripsi </p>

          <Row style={{ marginTop: 12 }} gutter={[4]}>
            <Col xs={24} sm={5} md={5} lg={5} style={{ marginLeft: "2%" }}>
              <p className="font-normal"> Teks Judul </p>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
              <Input
                type="text"
                value={this.state.title}
                onChange={(e) => this.setState({ title: e.target.value })}
              ></Input>
            </Col>
          </Row>
          <Row style={{ marginTop: 12 }} gutter={[4]}>
            <Col xs={24} sm={5} md={5} lg={5} style={{ marginLeft: "2%" }}>
              <p className="font-normal"> Teks Deskripsi </p>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Editor value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />
              {/* <TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}
              ></TextArea> */}
            </Col>
          </Row>
          <Button
            type="primary"
            style={{ float: "right", marginTop: "2%" }}
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
          >
            Perbarui
          </Button>
        </Card>
      </>
    );
  }
}

export default Description;
