import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import moment from 'moment';
import { participantService } from '../../../Service/participant.service';
import { mainConfig } from '../../../Config/mainConfig';
import { phaseService } from '../../../Service/phase.services';

class CardTemplate extends React.Component {


  state = {
    data: {},
    phase: []
  };

  async componentDidMount() {
    if (this.props?.user_id >= 0) {
      let userData = await participantService.getBiodata(this.props.user_id);

      if (userData.data) {
        this.setState({ data: userData.data });
      }

      phaseService.getSchedule(1, 999999999, userData.data.line_category_id, 'test').then(
        (resp) => {
          this.setState({ phase: resp.data });
        });
    }
  }

  render() {
    const data = this.state.data;

    let classTypes = {
      reguler: 'Reguler',
      superior: 'Unggulan',
      excelent: 'Excelent (Tahfidz)'
    };

    const styles = StyleSheet.create({
      page: {
        padding: 16,
        gap: 4
      },
      logoImage: {
        height: "1cm",
        width: "1cm"
      },
      userImage: {
        height: "3cm",
        width: "3cm"
      },
      sectionHeader: {
        flexDirection: "row",
        marginVertical: 4,
        alignItems: "center",
        gap: 8
      },
      sectionImageLeft: {
        height: "3cm",
        width: "3cm",
        border: 1,
        borderRadius: 4,
        borderColor: "#FFFFFF",
        marginBottom: 4
      },
      sectionImageRight: {
        height: "3cm",
        width: "3cm",
        border: 1,
        borderRadius: 4,
        borderColor: "#E4E4E4",
        justifyContent: "center",
        textAlign: "center",
        marginBottom: 4
      },
      sectionBody: {
        flexDirection: "row",
        padding: 2,
        border: 1,
        borderRadius: 4,
        borderColor: "#E4E4E4",
        marginVertical: 4,
      },
      sectionLeft1: {
        margin: 12,
        flex: "1",
        gap: 8
      },
      sectionRight1: {
        margin: 12,
        flex: "3",
        gap: 8
      },
      sectionLeft2: {
        margin: 12,
        flex: "1",
        gap: 8
      },
      sectionRight2: {
        margin: 12,
        flex: "1",
        gap: 8
      },
      textUnderline1: {
        fontSize: 10,
        borderBottom: 1,
        fontFamily: "OpenSans",
        borderBottomColor: "#E4E4E4"
      },
      textUnderline2: {
        fontSize: 10,
        fontFamily: "OpenSans",
        textAlign: "center",
        borderBottom: 1,
        borderBottomColor: "#E4E4E4"
      },
      textNormal1: {
        fontSize: 8,
        borderBottom: 1,
        fontFamily: "OpenSans",
        borderBottomColor: "#FFFFFF"
      },
      textNormal2: {
        fontSize: 10,
        borderBottom: 1,
        fontFamily: "OpenSans",
        borderBottomColor: "#FFFFFF",
        color: "#4F4F4F"
      },
      textTitle1: {
        fontSize: 10,
        textAlign: "center",
        fontFamily: "OpenSans",
        color: "#4F4F4F"
      },
      textTitle2: {
        fontSize: 8,
        fontWeight: 700,
        fontFamily: "OpenSans",
      },
    });

    Font.register({
      family: 'OpenSans',
      fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
      ]
    });

    return (
      <Document>
        <Page size="A5" style={styles.page}>
          <View style={styles.sectionHeader}>
            <Image
              style={styles.logoImage}
              src={
                window.location.protocol +
                "//" +
                window.location.host +
                "/logomts.png"
              }
            />
            <View>
              <Text style={styles.textTitle2}>Kartu Peserta</Text>
              <Text style={styles.textTitle2}>Penerimaan Peserta Didik Baru</Text>
              <Text style={styles.textTitle2}>MTs Negeri 1 Lamongan Tahun {moment().format('YYYY')}</Text>
            </View>
          </View>
          <View style={styles.sectionBody}>
            <View style={styles.sectionLeft1}>
              <View style={styles.sectionImageLeft}></View>
              <Text style={styles.textNormal2}>NISN</Text>
              <Text style={styles.textNormal2}>Nama</Text>
              <Text style={styles.textNormal2}>Nomor Peserta</Text>
              <Text style={styles.textNormal2}>Asal Sekolah</Text>
              <Text style={styles.textNormal2}>Jalur Masuk</Text>
              <Text style={styles.textNormal2}>Pilihan Kelas</Text>
              <Text style={styles.textNormal2}>Pilihan Mahad</Text>
              <Text style={styles.textNormal2}>Alamat</Text>
            </View>
            <View style={styles.sectionRight1}>
              <View style={styles.sectionImageRight}>
                <Image
                  src={`${mainConfig.apiUrl}/user/image/${data.id}`}
                  style={styles.userImage}
                />
              </View>
              <Text style={styles.textUnderline1}>{data.nisn ?? "-"}</Text>
              <Text style={styles.textUnderline1}>{data.name ?? "-"}</Text>
              <Text style={styles.textUnderline1}>{data.registration_number ?? "-"}</Text>
              <Text style={styles.textUnderline1}>{data.school ?? "-"}</Text>
              <Text style={styles.textUnderline1}>{data.line ?? "-"}</Text>
              <Text style={styles.textUnderline1}>{classTypes[data.class_type] ?? "-"}</Text>
              <Text style={styles.textUnderline1}>{data.is_mahad ? 'Ya' : 'Tidak'}</Text>
              <Text style={styles.textUnderline1}>{(data.district ?? "-") + ", " + (data.city ?? "-")}</Text>
            </View>
          </View>
          <View style={styles.sectionHeader}>
            <Text style={styles.textTitle2}>Jadwal Tes {data.line_category}</Text>
          </View>
          <View style={styles.sectionBody}>
            <View style={styles.sectionLeft2}>
              <Text style={styles.textTitle1}>Tanggal</Text>
              {
                this.state.phase.map(item => {
                  return <Text style={styles.textUnderline2}>
                    {moment(item.start_date).isSame(moment(item.end_date))
                      ? moment(item.start_date).locale('id').format("D MMM") +
                      ". " +
                      moment(item.start_date).format("LT")
                      : moment(item.start_date).locale('id').format("D MMM") +
                      ". " +
                      moment(item.start_date).format("LT") +
                      " - " +
                      moment(item.end_date).locale('id').format("D MMM") +
                      ". " +
                      moment(item.end_date).format("LT")}
                  </Text>;
                })
              }
            </View>
            <View style={styles.sectionRight2}>
              <Text style={styles.textTitle1}>Kegiatan</Text>
              {
                this.state.phase.map(item => {
                  return <Text style={styles.textUnderline2}>{item.name}</Text>
                })
              }
            </View>
          </View>
          <Text style={styles.textNormal1}>Gunakan username CBT{data.nisn} dan password {data.registration_number} untuk login CBT.</Text>
        </Page>
      </Document>
    );
  }
}

export default CardTemplate;
