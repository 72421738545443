import React from "react";
import {
  Row,
  Col,
  Card,
  Divider,
  Pagination,
  Input,
  Empty,
} from "antd";
import { announcementService } from "../../Service/announcement.service";
import moment from "moment";
import "moment/locale/id";
import { Parser } from "html-to-react";
const { Search } = Input;
moment.locale("id");

class Pengumuman extends React.Component {
  state = {
    data: [],
    total: 0,
    page: 1,
    limit: 10,
    isLoading: true,
    startDate: "",
    endDate: "",
    value: "",
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    announcementService
      .getAnnouncement(
        this.state.page,
        this.state.limit,
        this.state.value,
        this.state.startDate,
        this.state.endDate
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.value !== this.state.value ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.getData(false);
    }
  }
  onChange = (page) => {
    this.setState({ page: page });
  };
  changeDate = (dates, type) => {
    if (type === "startDate") {
      let startDate = moment(dates._d).format("YYYY-MM-DD 00:01");
      this.setState({ startDate });
    } else {
      let endDate = moment(dates._d).format("YYYY-MM-DD 23:59");
      this.setState({ endDate });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };

  render() {
    return (
      <React.Fragment>
          <Card
          >
            <div style={{ margin: 20 }}>
              <div>
                <Card
                  style={{
                    
                    height: "100%",
                    overflow: "hidden",
                  }}
                  loading={this.state.isLoading}
                >
                  <React.Fragment>
                    <Row justify={"center"}>
                      <Col xs={20}>
                        <Search
                          placeholder="Cari pengumuman"
                          allowClear
                          onChange={(e) => this.handleFilter(e)}
                          size="middle"
                          style={{
                            color: "#555597",
                            height: 30,
                            width: "100%",
                          }}
                        />
                      </Col>
                    </Row>
                    {this.state.data.map((item) => (
                      <Row justify={"center"}>
                        <Col xs={20}>
                          <p className="font-normal text-bold-700">
                            {item.title}
                          </p>
                          <p className="font-small-3">
                            {moment(item.created_at).format("LL")}
                          </p>
                        </Col>
                        <Col xs={20}>
                          <div className="font-normal">{Parser().parse(item.content)}</div>
                        </Col>
                        <Divider style={{margin:"2px"}}/>
                      </Row>
                    ))}
                  </React.Fragment>
                  {this.state.data.length > 0 && (
                    <Pagination
                      responsive={true}
                      style={{ textAlign: "center"}}
                      defaultCurrent={1}
                      total={this.state.total}
                      showSizeChanger={false}
                      onChange={this.onChange}
                    />
                  )}

                  {this.state.data.length === 0 && (
                    <Empty
                      style={{ marginTop: "3%", marginBottom: "3%" }}
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 80,
                      }}
                      description={<span>Tidak ada data</span>}
                    ></Empty>
                  )}
                </Card>
              </div>
            </div>
          </Card>
      </React.Fragment>
    );
  }
}
export default Pengumuman;
