import moment from "moment";
import React from "react";

import { Row, Col, Pagination, Input, Select, Table, Empty } from "antd";
import ActionParticipants from "./ActionParticipants";
import { participantService } from "../../../../Service/participant.service";
import ButtonExport from "../../../../Component/File/ButtonExport";

const { Option } = Select;
const { Search } = Input;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

const CustomHeader = (props) => {
  let csvData = [];
  let classTypes = {
    reguler: 'Reguler',
    superior: 'Unggulan',
    excelent: 'Excelent (Tahfidz)'
  }

  for (let item of props.data) {
    // if (item.registration_number) {
      csvData.push({
        "Nomor Peserta": item.registration_number,
        NISN: item.nisn,
        Email: item.email,
        "No Kartu Keluarga": item.kk_number,
        NIK: item.nik,
        "Nama Peserta": item.name,
        "Nama Ayah": item.father,
        "Nama Ibu": item.mother,
        Jalur: item.line ? `${item.line ?? ''} (${item.line_category ?? ''})` : '',
        Kelas: classTypes[item.class_type],
        "Tinggal di Mahad": item.is_mahad ? 'Ya' : 'Tidak',
        "Username CBT/NISN": "CBT" + item.nisn,
        "Password CBT/No Peserta": item.registration_number,
        "Jenis Kelamin": item.gender === "male" ? "Laki-laki" : "Perempuan",
        "Tempat Lahir": item.birth_place,
        "Tanggal Lahir": item.birth_date,
        "No Telepon": item.phone_number,
        "No Telepon Orang Tua": item.parent_phone,
        "Asal Sekolah": item.school,
        "Kota/Provinsi": item.city,
        Kecamatan: item.district,
        "Alamat Lengkap": item.address_detail,
      });
    // }
  }

  return (
    <React.Fragment>
      <div style={{ marginTop: "2%", marginBottom: "3%" }}>
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={3}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Semua Jalur"
              optionFilterProp="children"
              onChange={props.onLine}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              {props.line.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={3}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Semua Kelas"
              optionFilterProp="children"
              onChange={props.onClassType}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              <Option value="">Semua Kelas</Option>
              <Option value="reguler">Reguler</Option>
              <Option value="superior">Unggulan</Option>
              <Option value="excelent">Excelent (Tahfidz)</Option>
            </Select>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={3}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Semua Pilihan Mahad"
              optionFilterProp="children"
              onChange={props.onMahad}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              <Option value="">Semua Pilihan Mahad</Option>
              <Option value={true}>Memilih Mahad</Option>
              <Option value={false}>Tidak Pilih Mahad</Option>
            </Select>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={16} md={16} lg={12}>
            <div className="form-control-position pr-3">
              <Search
                placeholder="Cari nama peserta, nisn, atau nomor registrasi"
                allowClear
                onChange={(e) => props.handleFilter(e)}
                size="middle"
                style={{
                  color: "#555597",
                  height: 30,
                  width: "100%",
                }}
              />
            </div>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={3}>
            <ButtonExport
              csvData={csvData}
              fileName={"Data Peserta (Terverifikasi) " + moment().format("LL")}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
class Score extends React.Component {
  state = {
    columns: [
      {
        title: "Nama",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "NISN",
        dataIndex: "nisn",
        key: "nisn",
        responsive: ["md"],
      },
      {
        title: "No. Peserta",
        dataIndex: "registration_number",
        key: "registration_number",
        responsive: ["md"],
      },
      {
        title: "Jalur",
        key: "line",
        dataIndex: "line",
        responsive: ["md"],
      },
      {
        title: "Kategori Jalur",
        key: "line_category",
        dataIndex: "line_category",
        responsive: ["md"],
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => (
          <ActionParticipants
            user={row}
            edit={this.props.edit}
            delete={this.props.delete}
            download={this.props.download}
          />
        ),
      },
    ],
    data: [],
    lines: -1,
    class_type: '',
    is_mahad: '',
    value: "",
    page: 1,
    limit: 10,
    total: 0,
  };

  componentDidMount() {
    this.getData(true);
  }
  handleTableChange = (pagination) => {
    this.getData({
      pagination,
    });
  };
  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    participantService
      .getParticipant(
        this.state.page,
        this.state.limit,
        this.state.lines,
        this.state.value,
        false,
        this.state.class_type,
        this.state.is_mahad,
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
              limit: resp.per_page,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (
      prevProps.reload !== this.props.reload ||
      prevState.lines !== this.state.lines ||
      prevState.class_type !== this.state.class_type ||
      prevState.is_mahad !== this.state.is_mahad ||
      prevState.page !== this.state.page ||
      prevState.value !== this.state.value ||
      prevState.limit !== this.state.limit
    ) {
      this.getData(false);
    }
  }

  onLine = (item) => {
    this.setState({ lines: item });
  };

  onClassType = (item) => {
    this.setState({ class_type: item });
  };

  onMahad = (item) => {
    this.setState({ is_mahad: item });
  };

  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };

  onChange = (page, pageSize) => {
    if (isNaN(pageSize)) {
      this.setState({ page: page, limit: 0 });
    } else {
      this.setState({ page: page, limit: pageSize });
    }
  };

  render() {
    let { data, columns, value } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };

    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <CustomHeader
          data={data}
          columns={table.columns}
          //Line
          line={this.props.lines}
          onLine={this.onLine}
          lines={this.state.lines}
          // Class Type
          onClassType={this.onClassType}
          class_type={this.state.class_type}
          // Mahad
          onMahad={this.onMahad}
          is_mahad={this.state.is_mahad}
          //Search
          handleFilter={this.handleFilter}
          value={value}
        />
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          locale={emptyData}
          loading={table.isLoading}
          scroll={{ x: true }}
        />
        {table.total > 0 && (
          <Pagination
            style={{ textAlign: "center", marginTop: "5%", color: "black" }}
            responsive={true}
            defaultCurrent={1}
            total={table.total}
            showSizeChanger={true}
            pageSize={
              this.state.limit === 0 ? this.state.total : this.state.limit
            }
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} dari ${total} data`
            }
            onChange={this.onChange}
            pageSizeOptions={["10", "20", "50", "100", "semua"]}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Score;
