// import { authHeader } from '../_helpers';
import { mainConfig } from "../Config/mainConfig";
import { authHeader, handleResponse } from "./auth.header";

export const announcementService = {
  getAnnouncement,
  addAnnouncement,
  deleteAnnouncement,
  editAnnouncement,
  getReportDashboard,
};

function getAnnouncement(page, limit, value, startDate, endDate) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/announcement?page=${page}&per_page=${limit}&search=${value}&start_date=${startDate}&end_date=${endDate}`,
    reqOpt
  ).then(handleResponse);
}
function addAnnouncement(data) {
  const reqOpt = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/announcement`, reqOpt).then(
    handleResponse
  );
}

function editAnnouncement(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/announcement/${id}`, reqOpt).then(
    handleResponse
  );
}
function deleteAnnouncement(id) {
  const reqOpt = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/announcement/${id}`, reqOpt).then(
    handleResponse
  );
}

function getReportDashboard() {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/report/dashboard`, reqOpt).then(
    handleResponse
  );
}
