import React from "react"
import { Button } from "antd"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DownloadOutlined } from "@ant-design/icons";

class ButtonExport extends React.Component {
    constructor(props) {
        super(props)
        this.fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        this.fileExtension = '.xlsx';
    }

    exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: this.fileType});
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }
    
    render() {
        return (
            <Button
            type='primary'
            style={{width: '100%'}}
            onClick={() => this.exportToCSV(this.props.csvData, this.props.fileName)}>
                <span className="float-left">Ekspor</span>
                <DownloadOutlined className="float-right" size={13}/>
            </Button>
        )
    }
}

export default ButtonExport