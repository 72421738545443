import React from "react";

import { Row, Col, Input, Table, Pagination, Empty } from "antd";
import { participantService } from "../../../../Service/participant.service";
import ButtonExport from "../../../../Component/File/ButtonExport";
import ActionSchool from "./ActionSchool";

const { Search } = Input;

const CustomHeader = (props) => {
  let csvData = props.data.map((item) => {
    return {
      nama_sekolah: item.name,
    };
  });
  return (
    <React.Fragment>
      <div style={{ marginTop: "2%", marginBottom: "3%" }}>
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={24} md={20} lg={20}>
            <div className="form-control-position pr-3">
              <Search
                placeholder="Cari nama sekolah"
                allowClear
                onChange={(e) => props.handleFilter(e)}
                size="middle"
                style={{
                  color: "#555597",
                  height: 30,
                  width: "100%",
                }}
              />
            </div>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={24} md={4} lg={4}>
            <ButtonExport csvData={csvData} fileName="Data Sekolah" />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
class Score extends React.Component {
  state = {
    columns: [
      {
        title: "Nama",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => (
          <ActionSchool
            school={row}
            edit={this.props.edit}
            delete={this.props.delete}
          />
        ),
      },
    ],
    data: [],
    value: "",
    page: 1,
    limit: 10,
    total: 0,
  };

  componentDidMount() {
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    participantService
      .getSchool(this.state.page, this.state.limit, this.state.value)
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (
      prevProps.reload !== this.props.reload ||
      prevState.page !== this.state.page ||
      prevState.limit !== this.state.limit ||
      prevState.value !== this.state.value ||
      prevState.limit !== this.state.limit
    ) {
      this.getData(false);
    }
  }

  onChange = (page, pageSize) => {
    this.setState({ page: page, limit: pageSize });
  };

  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };

  render() {
    let { data, columns, value } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <CustomHeader
          data={data}
          columns={table.columns}
          //Search
          handleFilter={this.handleFilter}
          value={value}
        />
        <Table
          scroll={{ x: true }}
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          locale={emptyData}
          loading={table.isLoading}
        />
        {table.total > 0 && (
          <Pagination
            responsive={true}
            style={{ textAlign: "center", marginTop: "5%", color: "black" }}
            defaultCurrent={1}
            total={table.total}
            showSizeChanger={true}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} dari ${total} data`
            }
            onChange={this.onChange}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Score;
