import React from "react";
import { Tabs, Card } from "antd";
import Import from "./Import/Import";
import Result from "./Result";

const { TabPane } = Tabs;

function callback(key) {}

class Index extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div style={{ marginTop: 20 }}>
          <Card
            style={{
              
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Seleksi PPDB" key="1">
                <Result type="school" />
              </TabPane>
              <TabPane tab="Seleksi Mahad" key="4">
                <Result type="mahad" />
              </TabPane>
              <TabPane tab="Impor Nilai" key="5">
                <Import />
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
export default Index;
