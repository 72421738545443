import React from "react";
import { Button, Modal } from "antd";

class DeleteModal extends React.Component {
  state = {
    isOpen: false,
    id: this.props.id,
    name: this.props.name,
    isLoading: this.props.isLoading,
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  deleteItem = () => {
    this.props.onDeleted(this.state.id);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      this.setState({ name: this.props.name, id: this.props.id });
    }
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
    if (this.state.type !== "") {
      this.setState({ isOpen: false });
    }
  };

  render() {
    return (
      <Modal
        title={"Konfirmasi Hapus"}
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button onClick={this.toggleModal}>Batalkan</Button>,
          <Button
            type="primary"
            danger
            onClick={this.deleteItem}
            loading={this.props.isLoading}
          >
            Ya,Hapus
          </Button>,
        ]}
      >
        <p className="mx-1 my-0 black">
          Hapus <span style={{ fontWeight: 500 }}>{this.state.name}</span> ?
        </p>
      </Modal>
    );
  }
}

export default DeleteModal;
