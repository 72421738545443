// import { authHeader } from '../_helpers';
import { mainConfig } from "../Config/mainConfig";
import { authHeader, handleResponse } from "./auth.header";

export const lineService = {
  getLine,
  getLineId,
  addLine,
  editLine,
  deleteLine,
};

function getLine(all = 0) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/line?all=${all}`, reqOpt).then(
    handleResponse
  );
}

function getLineId(id) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/line/${id}`, reqOpt).then(handleResponse);
}

function addLine(data) {
  const reqOpt = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/line`, reqOpt).then(handleResponse);
}
function editLine(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/line/${id}`, reqOpt).then(handleResponse);
}
function deleteLine(id) {
  const reqOpt = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/line/${id}`, reqOpt).then(handleResponse);
}

