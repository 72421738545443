import React from "react";

import { Layout, Menu } from "antd";
import {
  UserOutlined,
  AppstoreOutlined,
  NotificationOutlined,
  FileTextOutlined,
  LoginOutlined,
  TrophyOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Router, Switch, Route, Link } from "react-router-dom";
import { history } from "../History";
import summaryAdmin from "../View/Admin/Summary/Index";
import summaryUser from "../View/User/Summary/Index";
import biodata from "../View/User/Biodata/Index";
import result from "../View/User/Result/Index";
import resultAdmin from "../View/Admin/Result/Index";
import register from "../View/User/Register/Index";
import settings from "../View/Admin/Setting/Index";
import manageAnnouncement from "../View/Admin/Announcement/Annaouncement";
import participants from "../View/Admin/Participants/Index";
import manageParticipants from "../View/Admin/Participants/Participant/Manage";
import paymentAdmin from "../View/Admin/Payment/Payment";
import file from "../View/User/UploadFile/Index";
import manageFile from "../View/Admin/File/File";
import { jwtDecode } from "jwt-decode";
import CardViewer from "../View/User/CadPDF/CardViewer";

const { Sider } = Layout;

class Sidebar extends React.Component {
  state = {
    user_role: "",
  };
  componentDidMount() {
    if (localStorage.length > 0) {
      let userToken = localStorage.getItem("USER_TOKEN");
      let userData = jwtDecode(userToken);
      this.setState({ user_role: userData?.role });
    }
  }
  render() {
    return (
      <React.Fragment>
        <Router history={history}>
          <Layout>
            <Sider
              width={250}
              className="site-layout-background"
              breakpoint="lg"
              collapsedWidth="0"
            >
              {}
              <Menu
                mode="inline"
                defaultSelectedKeys={[
                  window.location.pathname.search("/profile/summary") > -1
                    ? "1"
                    : window.location.pathname.search("/profile/biodata") > -1
                    ? "2"
                    : window.location.pathname.search("profile/upload-file") >
                      -1
                    ? "3"
                    : window.location.pathname.search("profile/result") > -1
                    ? "4"
                    : window.location.pathname.search("profile/register") > -1
                    ? "5"
                    : window.location.pathname.search(
                        "/profile/admin/summary"
                      ) > -1
                    ? "summary"
                    : window.location.pathname.search(
                        "/profile/admin/manage-announcement"
                      ) > -1
                    ? "announcement"
                    : window.location.pathname.search(
                        "profile/admin/participant"
                      ) > -1
                    ? "participants"
                    : window.location.pathname.search("profile/admin/result") >
                      -1
                    ? "result"
                    : window.location.pathname.search(
                        "profile/admin/re-registration"
                      ) > -1
                    ? "registration"
                    : window.location.pathname.search(
                        "profile/admin/settings"
                      ) > -1
                    ? "settings"
                    : "",
                ]}
                style={{ height: "100%", borderRight: 0 }}
              >
                {this.state.user_role === "admin" ? (
                  <React.Fragment>
                    <Menu.Item
                      key="summary"
                      icon={<AppstoreOutlined />}
                      className="font-normal"
                    >
                      Ringkasan
                      <Link to={"/profile/admin/summary"}></Link>
                    </Menu.Item>
                    <Menu.Item
                      key="announcement"
                      icon={<NotificationOutlined />}
                      className="font-normal"
                    >
                      Pengumuman
                      <Link to={"/profile/admin/manage-announcement"}></Link>
                    </Menu.Item>
                    <Menu.Item
                      key="participants"
                      icon={<UserOutlined />}
                      className="font-normal"
                    >
                      Peserta
                      <Link to={"/profile/admin/participant"}></Link>
                    </Menu.Item>
                    <Menu.Item
                      key="file"
                      icon={<FileTextOutlined />}
                      className="font-normal"
                    >
                      Pemberkasan
                      <Link to={"/profile/admin/file"}></Link>
                    </Menu.Item>
                    <Menu.Item
                      key="result"
                      icon={<TrophyOutlined />}
                      className="font-normal"
                    >
                      Hasil Seleksi
                      <Link to={"/profile/admin/result"}></Link>
                    </Menu.Item>
                    <Menu.Item
                      key="registration"
                      icon={<LoginOutlined />}
                      className="font-normal"
                    >
                      Daftar Ulang
                      <Link to={"/profile/admin/re-registration"}></Link>
                    </Menu.Item>

                    <Menu.Item
                      key="settings"
                      icon={<SettingOutlined />}
                      className="font-normal"
                    >
                      Pengaturan
                      <Link to={"/profile/admin/settings"}></Link>
                    </Menu.Item>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Menu.Item
                      key="1"
                      icon={<AppstoreOutlined />}
                      className="font-normal"
                    >
                      Ringkasan
                      <Link to={"/profile/summary"}></Link>
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      icon={<UserOutlined />}
                      className="font-normal"
                    >
                      Biodata
                      <Link to={"/profile/biodata"}></Link>
                    </Menu.Item>
                    <Menu.Item
                      key="3"
                      icon={<FileTextOutlined />}
                      className="font-normal"
                    >
                      Pemberkasan
                      <Link to={"/profile/upload-file"}></Link>
                    </Menu.Item>
                    <Menu.Item
                      key="4"
                      icon={<TrophyOutlined />}
                      className="font-normal"
                    >
                      Hasil Seleksi
                      <Link to={"/profile/result"}></Link>
                    </Menu.Item>

                    <Menu.Item
                      key="5"
                      icon={<LoginOutlined />}
                      className="font-normal"
                    >
                      Daftar Ulang
                      <Link to={"/profile/register"}></Link>
                    </Menu.Item>
                  </React.Fragment>
                )}
              </Menu>
            </Sider>
            <Layout
              style={{ padding: "0 24px 24px", minHeight: "100vh" }}
            >
              <Switch>
                <Route
                  path="/profile"
                  render={({ match: { url } }) => {
                    return (
                      <>
                        <Route
                          path={`${url}/summary`}
                          component={summaryUser}
                          exact
                        />
                        <Route
                          path={`${url}/upload-file`}
                          component={file}
                          exact
                        />
                        <Route
                          path={`${url}/biodata`}
                          component={biodata}
                          exact
                        />
                        <Route
                          path={`${url}/result`}
                          component={result}
                          exact
                        />
                        <Route
                          path={`${url}/register`}
                          component={register}
                          exact
                        />
                        <Route
                          path={`${url}/card`}
                          component={CardViewer}
                          exact
                        />
                       <Route
                          path={`${url}/admin/result`}
                          component={resultAdmin}
                          exact
                        />
                        <Route
                          path={`${url}/admin/summary`}
                          component={summaryAdmin}
                          exact
                        />
                        <Route
                          path={`${url}/admin/settings`}
                          component={settings}
                          exact
                        />
                        <Route
                          path={`${url}/admin/manage-announcement`}
                          component={manageAnnouncement}
                          exact
                        />
                        <Route
                          path={`${url}/admin/participant`}
                          component={participants}
                          exact
                        />
                        <Route
                          path={`${url}/admin/participant/add`}
                          component={manageParticipants}
                          exact
                        />
                        <Route
                          path={`${url}/admin/participant/edit`}
                          component={manageParticipants}
                          exact
                        />
                        <Route
                          path={`${url}/admin/re-registration`}
                          component={paymentAdmin}
                          exact
                        />
                        <Route
                          path={`${url}/admin/file`}
                          component={manageFile}
                          exact
                        />
                      </>
                    );
                  }}
                />
              </Switch>
            </Layout>
          </Layout>
        </Router>
      </React.Fragment>
    );
  }
}
export default Sidebar;
