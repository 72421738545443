import { mainConfig } from "../../../Config/mainConfig";
import React from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  Card,
  Tooltip,
  Input,
  Image,
  Divider,
} from "antd";
import { paymentService } from "../../../Service/payment.service";
import { participantService } from "../../../Service/participant.service";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { NumericFormat } from "react-number-format";
import moment from "moment";
class ManageModal extends React.Component {
  state = {
    data: this.props.payment,
    isOpen: false,
    isLoading: false,
    loading: false,
    pay_date: "",
    noRef: "",
    user_name: "",
    nisn: "",
    name: "",
    payment_status: "",
    due: 0,
    note: "",
    user_id: 0,
    dataUser: [],
    year: "",
    virtualAccount: "",
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  handleConfirm = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    paymentService
      .updatePaymentStatus(this.state.data.id, {
        is_confirmed: true,
        due: this.state.due,
        note: this.state.note,
      })
      .then(
        (resp) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
          });
          this.props.onUpdated(resp);
        },
        (error) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
          });
          this.props.onUpdated({
            isUpdated: true,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  handleCanceled = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    paymentService
      .updatePaymentStatus(this.state.data.id, {
        is_confirmed: false,
      })
      .then(
        (resp) => {
          this.toggleModal();
          this.setState({
            loading: false,
            data: null,
          });
          this.props.onUpdated(resp);
        },
        (error) => {
          this.toggleModal();
          this.setState({
            loading: false,
            data: null,
          });
          this.props.onUpdated({
            isUpdated: true,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.payment !== this.props.payment) {
      this.getData(false);
      if (this.props.payment)
        this.setState({
          isLoading: false,
          id: this.props.payment.id,
          data: this.props.payment,
          pay_date: this.props.payment.pay_date,
          name: this.props.payment.name,
          user_name: this.props.payment.user_name,
          noRef: this.props.payment.ref_no,
          nisn: this.props.payment.nisn,
          payment_status: this.props.payment.payment_status,
          user_id: this.props.payment.user_id,
          due: this.props.payment.due ?? 0,
          note: this.props.payment.note,
          amount: this.props.payment.amount,
          virtualAccount: this.props.payment.virtual_account,
        });
      else
        this.setState({
          isLoading: false,
          id: "",
          data: null,
          pay_date: "",
          name: "",
          user_name: "",
          noRef: "",
          nisn: "",
          payment_status: "",
          user_id: "",
          due: 0,
          note: "",
          amount: 0,
        });
    }
  }

  getData() {
    window.scrollTo(0, 0);

    participantService
      .getUser(this.props.payment && this.props.payment.user_id)
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              dataUser: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidMount() {
    var date = new Date();
    var convert = moment(date, "DD/MM/YYYY").year();
    this.setState({ year: convert });
  }

  render() {
    return (
      <Modal
        title="Konfirmasi Pembayaran"
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        width={800}
        footer={[
          <>
            <Row gutter={[4, 16]}>
              <Col xs={24} sm={8} md={8} lg={8}>
                <Button
                  type="primary"
                  danger
                  key="back"
                  onClick={this.handleCanceled}
                  loading={this.state.loading}
                  style={{ width: "100%" }}
                >
                  Batalkan Pembayaran
                </Button>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8}>
                <Button
                  loading={this.state.isLoading}
                  key="submit"
                  type="primary"
                  disabled={this.state.payment_status === "paid"}
                  onClick={this.handleConfirm}
                  style={{ width: "100%" }}
                >
                  Konfirmasi Pembayaran
                </Button>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8}>
                <ReactToPrint
                pageStyle="
                @page {
                  size: a5;
                  margin: 0;
                }
                "
                  trigger={() => (
                    <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                      <Button
                        type="primary"
                        style={{
                          width: "100%",
                        }}
                      >
                        Cetak Bukti Pembayaran
                      </Button>
                    </Tooltip>
                  )}
                  content={() => this.componentRef}
                />
                <div
                  className="print-potrait"
                  ref={(el) => (this.componentRef = el)}
                >
                  <Card
                    // style={{
                    //   width: "794px",
                    //   height: "100%",
                    //   marginLeft: "1%",
                    //   marginRight: "1%",
                    //   marginTop: "1%",
                    //   marginBottom: "1%",
                    //   paddingBottom: 0,
                    // }}
                  >
                    <Row align="middle" gutter={[16]}>
                      <Col
                        span={4}
                        style={{ textAlign: "right", marginBottom: 16 }}
                      >
                        <img
                          src={
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            "/logomts.png"
                          }
                          style={{ width: "45%" }}
                          alt="Logo Matsanela"
                        />
                      </Col>
                      <Col span={14}>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "9pt",
                            fontWeight: "bold",
                            marginLeft: "3%",
                          }}
                        >
                          Bukti Pembayaran PPDB <br />
                          MTs Negeri 1 Lamongan <br />
                          {this.state.year}
                        </p>
                      </Col>
                      {/* <Col span={6} style={{ textAlign: "left" }}>
                        <Barcode
                          width={1}
                          height={40}
                          displayValue={false}
                          value={
                            this.state.data &&
                            this.state.data.registration_number
                          }
                        />
                      </Col> */}
                    </Row>
                    <Divider
                      style={{
                        borderWidth: "3px",
                        borderColor: "#000000",
                        margin: 0,
                      }}
                    ></Divider>
                    <Row style={{ textAlign: "left", marginTop: "4%" }}>
                      <Col span={6}>
                        <label>NISN</label>
                      </Col>
                      <Col span={18}>
                        <Input value={this.state && this.state.nisn} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Nama</label>
                      </Col>
                      <Col span={18}>
                        <Input value={this.state && this.state.user_name} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Tanggal Pembayaran</label>
                      </Col>
                      <Col span={18}>
                        <Input value={this.state && this.state.pay_date} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Jumlah Dibayar</label>
                      </Col>
                      <Col span={18}>
                        <Input
                          value={
                            this.state &&
                            parseInt(this.state.amount ?? 0).toLocaleString()
                          }
                        />
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Nama Pengirim</label>
                      </Col>
                      <Col span={18}>
                        <Input value={this.state && this.state.name} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Nomor Referensi</label>
                      </Col>
                      <Col span={18}>
                        <Input value={this.state && this.state.noRef} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Sisa Pembayaran</label>
                      </Col>
                      <Col span={18}>
                        <Input
                          value={
                            (this.state && parseInt(this.state.due) === 0) ? 
                              "Lunas" : 
                              (this.state.due ? 
                                parseInt(this.state.due).toLocaleString() : 
                                "Belum Lunas"
                              )
                          }
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Catatan</label>
                      </Col>
                      <Col span={18}>
                      <Input
                          value={this.state.note}
                        />
                      </Col>
                    </Row>

                    <Row justify="center">
                      <Col style={{ marginTop: "4%" }}>
                        <img
                          style={{
                            width: "400px",
                            height: "400px",
                          }}
                          src={`${mainConfig.apiUrl}/payment/image/${this.state.data?.id}`}
                          alt={this.state?.data?.offline_approve ? "Diverifikasi Offline" : "Bukti Pembayaran"}
                        />
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Col>
            </Row>
          </>,
        ]}
      >
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
            <Card
              className="font-normal"
              style={{
                color:
                  this.state.payment_status === "due" ||
                  !this.state.payment_status
                    ? "#ec4646"
                    : "black",
                borderColor:
                  this.state.payment_status === "due" ||
                  !this.state.payment_status
                    ? "#ec4646"
                    : "",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                textAlign: "center",
                paddingTop: 0,
              }}
            >
              Belum Dibayar
              <CloseCircleOutlined
                style={{ marginLeft: "6%", verticalAlign: "middle" }}
              />
            </Card>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
            <Card
              className="font-normal"
              style={{
                borderColor:
                  this.state.payment_status === "waiting" ? "#ffcc29" : "",
                color:
                  this.state.payment_status === "waiting" ? "#ffcc29" : "black",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                textAlign: "center",
                marginTop: 0,
              }}
            >
              Menunggu Konfirmasi
              <InfoCircleOutlined
                style={{ marginLeft: "6%", verticalAlign: "middle" }}
              />
            </Card>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
            <Card
              className="font-normal"
              style={{
                borderColor:
                  this.state.payment_status === "paid" ? "#00af91" : "",
                color:
                  this.state.payment_status === "paid" ? "#00af91" : "black",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                textAlign: "center",
              }}
            >
              Terbayar
              <CheckCircleOutlined
                style={{ marginLeft: "6%", verticalAlign: "middle" }}
              />
            </Card>
          </Col>
        </Row>

        <Form
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          style={{ marginTop: "5%" }}
        >
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Nama</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.user_name}</p>
            </Col>
          </Row>

          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">NISN</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.nisn}</p>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Tanggal Pembayaran</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.pay_date}</p>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Jumlah Dibayar</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">
                {parseInt(this.state.amount ?? 0).toLocaleString()}
              </p>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Virtual Account</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.virtualAccount}</p>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Nama Pengirim</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.name}</p>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Nomor Referensi</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.noRef}</p>
            </Col>
          </Row>

          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Sisa Pembayaran</p>
            </Col>
            <Col span={15}>
              <NumericFormat
                disabled={this.state.payment_status === "paid"}
                placeholder={"Isi 0 jika sudah lunas"}
                thousandSeparator={true}
                onValueChange={(e) =>
                  this.setState({
                    due: e.floatValue ? e.floatValue : 0,
                  })
                }
                value={this.state.due}
                customInput={Input}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Catatan</p>
            </Col>
            <Col span={15}>
              <Input
                disabled={this.state.payment_status === "paid"}
                placeholder='Isi dengan detail pembayaran'
                value={this.state.note}
                onChange={(e) => {
                  this.setState({ note: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginBottom: "3%" }}>
            <Col span={9}>
              <p className="font-normal"> Bukti Transfer </p>
            </Col>

            <Col span={15}>
              <Image
                src={`${mainConfig.apiUrl}/payment/image/${this.state.id}`}
                className="dz-img"
                style={{ width: 160, height: 160, padding: "0.8rem" }}
                alt={this.state?.data?.offline_approve ? "Diverifikasi Offline" : "Bukti Pembayaran"}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
