import React from "react";

import { Card } from "antd";
import FileTable from "./FileTable";
import { toast } from "react-toastify";
import ManageModal from "./ManageModal";
import { lineService } from "../../../Service/line.services"
class Payment extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }
  state = {
    reload: false,
    status: null,
    lines: []
  };

  toggleModalManage = (status) => {
    if (status) {
      this.setState({ status: status, reload: false });
    } else {
      this.setState({ status: null, reload: false });
    }

    this._modalManage.current.toggleModal();
  };

  edit = (status) => {
    this.toggleModalManage(status);
  };

  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({ payment: null, reload: true });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };
  componentDidMount(){
    lineService.getLine(1).then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "lineId", label: `${item.name} (${item.line_category_name})`, value: item.id };
      });
      data.unshift({ name: "lineId", label: "Semua Jalur", value: -1 });
      this.setState({ lines: data });
    });
  
  }
  render() {
    return (
      <React.Fragment>
        <div style={{ marginTop: 20 }}>
          <Card
            style={{
              
              height: "100%",
              overflow: "hidden",
            }}
          >
            <FileTable edit={this.edit} reload={this.state.reload} lines={this.state.lines} />
          </Card>
        </div>

        <ManageModal
          status={this.state.status}
          ref={this._modalManage}
          onUpdated={this.onUpdated}
        />
      </React.Fragment>
    );
  }
}

export default Payment;
