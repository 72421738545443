import React from "react";
import { Modal, Form, Input, Button } from "antd";
import { toast } from "react-toastify";
import { SettingService } from "../../../Service/setting.services";

class ModalPpbd extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }
  state = {
    isOpen: false,
    name: "",
    password: "",
    isLoading: false,
  };

  showModal = (type) => {
    this.setState({ type: type });
    this._modalManage.current.toggleModal();
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
    if (this.state.type !== "") {
      this.setState({ isOpen: false });
    }
  };
  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({ data: null, reload: true });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };

  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    SettingService.createSession({
      name: this.state.name,
      password: this.state.password,
    }).then(
      (resp) => {
        this.toggleModal();
        this.setState({
          isLoading: false,
          data: null,
          name: "",
          password: "",
        });
        this.onUpdated(resp);
      },
      (error) => {
        this.toggleModal();
        this.setState({
          isLoading: false,
          data: null,
          name: "",
          password: "",
        });
        this.onUpdated({
          isUpdated: false,
          status: false,
          message: error,
          data: null,
        });
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          title="Akhiri Sesi"
          centered
          visible={this.state.isOpen}
          toggle={this.toggleModal}
          footer={null}
          style={{ width: "100%", resize: "none" }}
          onCancel={this.handleCancel}
        >
          <p style={{ color: "red", textAlign: "center" }}>
            Mengakhiri sesi berarti menghapus semua data ppdb saat ini dan
            diganti dengan sesi baru!
          </p>
          <Form
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            onFinish={(e) => this.handleSubmit(e)}
          >
            <Form.Item
              label="Nama Sesi"
              name="old_password"
              onChange={(e) => this.setState({ name: e.target.value })}
              rules={[
                {
                  required: true,
                  message: "Masukkan Nama Sesi!",
                },
              ]}
            >
              <Input placeholder="Nama Sesi" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              onChange={(e) => this.setState({ password: e.target.value })}
              rules={[
                {
                  required: true,
                  message: "Masukkan Password!",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Button
              loading={this.state.isLoading}
              type="primary"
              style={{ width: "100%" }}
              onClick={(e) => this.handleSubmit()}
            >
              Lanjutkan
            </Button>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalPpbd;
