// import { authHeader } from '../_helpers';
import { mainConfig } from "../Config/mainConfig";
import { authHeader, handleResponse } from "./auth.header";

export const phaseService = {
  getSchedule,
  getScheduleScore,
  addSchedule,
  editSchedule,
  deleteSchedule,
};

function getSchedule(page, limit, lineCategoryId = -1, type = "") {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/phase?page=${page}&per_page=${limit}&line_category_id=${lineCategoryId}&type=${type}`,
    reqOpt
  ).then(handleResponse);
}
function getScheduleScore(page, limit, type, startDate = "", endDate = "") {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/phase?page=${page}&per_page=${limit}&type=${type}&start_date=${startDate}&end_date=${endDate}`,
    reqOpt
  ).then(handleResponse);
}
function addSchedule(data) {
  const reqOpt = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/phase`, reqOpt).then(handleResponse);
}
function editSchedule(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/phase/${id}`, reqOpt).then(handleResponse);
}
function deleteSchedule(id) {
  const reqOpt = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/phase/${id}`, reqOpt).then(handleResponse);
}
