import { mainConfig } from "../../Config/mainConfig";
import React from "react";

import { Button, Carousel } from "antd";
import LoginModal from "../../Component/Modal/LoginModal";
import { history } from "../../History";
import { SettingService } from "../../Service/setting.services";
import { Parser } from "html-to-react";

class CarouselDashboard extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }
  state = {
    isOpen: false,
    data: [],
    isLoggedIn: false,
  };

  showModal = () => {
    this._modalManage.current.toggleModal();
  };
  componentDidMount() {
    if (localStorage.length > 0) {
      let userData = localStorage.getItem("USER_DATA");
      this.setState({ isLoggedIn: userData ? true : false });
    }

    SettingService.getSetting().then(
      (resp) => {
        if (resp.status) {
          this.setState({
            data: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }

  click() {
    history.push({
      pathname: "/register",
    });
  }

  render() {
    let data = this.props.data;
    return (
      <div
        className="heroBlock"
        style={{
          backgroundImage: "url(" + mainConfig.apiUrl + "/setting/banner)",
          boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.4)",
          height: "100vh",
        }}
      >
        <Carousel autoplay>
          <div className="container-fluid">
            <div className="content">
              <p className="font-large-2" style={{ textAlign: "center" }}>
                {data.banner_title}
              </p>
              <p className="font-medium-3" style={{ textAlign: "center" }}>
                {Parser().parse(data.banner_description)}
              </p>
              <div className="btnHolder" style={{ textAlign: "center", display: this.state.isLoggedIn ? "none" : "block"}}>
                <Button type="primary" onClick={this.click} size="large">
                  Daftar Sekarang
                </Button>
                <Button type="primary" onClick={this.showModal} size="large">
                  Masuk ke Akun
                </Button>
              </div>
            </div>
          </div>
        </Carousel>
        <LoginModal ref={this._modalManage} />
      </div>
    );
  }
}

export default CarouselDashboard;
