import React from "react";
import { Card, Row, Col, Input, Button } from "antd";
import { SettingService } from "../../../../Service/setting.services";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

class PaymentSetting extends React.Component {
  state = {
    data: this.props.data,
    payment_bank: "",
    payment_name: "",
    payment_amount: 0,
    payment_account: "",
    payment_description: "",
    payment_document: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data)
        this.setState({
          isLoading: false,
          data: this.props.data,
          payment_bank: this.props.data.payment_bank,
          payment_name: this.props.data.payment_name,
          payment_amount: this.props.data.payment_amount,
          payment_account: this.props.data.payment_account,
          payment_description: this.props.data.payment_description,
          payment_document: this.props.data.payment_document,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          title: "",
          description: "",
        });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    SettingService.editSetting({
      payment_bank: this.state.payment_bank,
      payment_name: this.state.payment_name,
      payment_account: this.state.payment_account,
      payment_description: this.state.payment_description,
      payment_document: this.state.payment_document,
    }).then(
      (resp) => {
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      },
      (error) => {
        this.setState({ description: "", title: "" });
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };

  render() {
    return (
      <>
        <Card
          style={{
            
            height: "100%",
            overflow: "hidden",
            marginTop: "2%",
          }}
        >
          <p className="font-medium-1 text-bold-700"> Pembayaran </p>

          <Row style={{ marginTop: 12 }} gutter={[4]}>
            <Col xs={24} sm={5} md={5} lg={5} style={{ marginLeft: "2%" }}>
              <p className="font-normal"> Bank Pembayaran </p>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
              <Input
                placeholder="Bank Pembayaran"
                type="text"
                value={this.state.payment_bank}
                onChange={(e) =>
                  this.setState({ payment_bank: e.target.value })
                }
              ></Input>
            </Col>
          </Row>
          <Row style={{ marginTop: 12 }} gutter={[4]}>
            <Col xs={24} sm={5} md={5} lg={5} style={{ marginLeft: "2%" }}>
              <p className="font-normal"> Atas Nama </p>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
              <Input
                placeholder="Atas Nama"
                type="text"
                value={this.state.payment_name}
                onChange={(e) =>
                  this.setState({ payment_name: e.target.value })
                }
              ></Input>
            </Col>
          </Row>
          <Row style={{ marginTop: 12 }} gutter={[4]}>
            <Col xs={24} sm={5} md={5} lg={5} style={{ marginLeft: "2%" }}>
              <p className="font-normal"> Nomor Rekening </p>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
              <Input
                placeholder="Nomor Rekening"
                type="text"
                value={this.state.payment_account}
                onChange={(e) =>
                  this.setState({ payment_account: e.target.value })
                }
              ></Input>
            </Col>
          </Row> 

          <Row style={{ marginTop: 12 }} gutter={[4]}>
            <Col xs={24} sm={5} md={5} lg={5} style={{ marginLeft: "2%" }}>
              <p className="font-normal"> Jumlah Pembayaran </p>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
              <NumericFormat
                thousandSeparator={true}
                onValueChange={(e) =>
                  this.setState({
                    payment_amount: e.floatValue ? e.floatValue : 0,
                  })
                }
                value={this.state.payment_amount}
                customInput={Input}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 12 }} gutter={[4]}>
            <Col xs={24} sm={5} md={5} lg={5} style={{ marginLeft: "2%" }}>
              <p className="font-normal"> Petunjuk Pembayaran </p>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
              <Input
                placeholder="Petunjuk Pembayaran"
                type="text"
                value={this.state.payment_description}
                onChange={(e) =>
                  this.setState({ payment_description: e.target.value })
                }
              ></Input>
            </Col>
          </Row>
          {/* <Row style={{ marginTop: 12 }} gutter={[4]}>
            <Col xs={24} sm={5} md={5} lg={5} style={{ marginLeft: "2%" }}>
              <p className="font-normal"> Dokumen Pernyataan </p>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Link Dokumen Pernyataan"
                type="text"
                value={this.state.payment_document}
                onChange={(e) =>
                  this.setState({ payment_document: e.target.value })
                }
              ></Input>
            </Col>
          </Row>  */}
          <Button
            type="primary"
            style={{ float: "right", marginTop: "2%" }}
            onClick={this.handleSubmit}
          >
            Perbarui
          </Button>
        </Card>
      </>
    );
  }
}

export default PaymentSetting;
