import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useDropzone } from "react-dropzone";
import { mainConfig } from "../../Config/mainConfig";
import uploadFileImage from "../../Assets/uploadFile.svg";
import "../../App.css";

function DropzoneImage(props) {
  const IMG_URL = `${mainConfig.apiUrl}/${props.item}`;
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/jpeg",
    maxFiles: 1,
    init: function () {
      this.on("maxfilesexceeded", function (file) {
        this.removeAllFiles();
        this.addFile(file);
      });
      this.on("sending", function (file, xhr, data) {});
    },
    onDrop: (acceptedFiles) => {
      props.onDropped(acceptedFiles);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs =
    files.length > 0 ? (
      files.map((file) => {
        return (
          <div className="dz-thumb" key={file.name}>
            <div className="dz-thumb-inner">
              <img
                src={file.preview}
                className="dz-img"
                style={{ width: 160, height: 160, padding: "0.8rem" }}
                alt={"Berkas Foto"}
              />
            </div>
          </div>
        );
      })
    ) : props.item && props.item !== "" ? (
      <div className="dz-thumb">
        <div className="dz-thumb-inner">
          <img
            src={IMG_URL}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = uploadFileImage;
            }}
            className="dz-img"
            style={{ width: 100, height: 100, padding: "0.8rem" }}
            alt={"Pilih Foto"}
          ></img>
        </div>
      </div>
    ) : (
      <div className="dz-thumb">
        <div className="dz-thumb-inner">
          <img
            src={uploadFileImage}
            className="dz-img"
            style={{ width: 100, height: 100, padding: "0.8rem" }}
            alt={"Pilih Foto"}
          ></img>
        </div>
      </div>
    );

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="d-inline-block">
      <div {...getRootProps({ className: "dropzone-produk" })}>
        <input {...getInputProps()} />
        {thumbs ? (
          thumbs
        ) : (
          <div className="mt-4">
            <br />
            <Button
              type="button"
              className="font-medium-14 center btn-back"
              color=""
            >
              Pilih foto
            </Button>
          </div>
        )}
      </div>
    </section>
  );
}

class ImageDropzone extends React.Component {
  render() {
    return (
      <DropzoneImage item={this.props.item} onDropped={this.props.onDropped} />
    );
  }
}

export default ImageDropzone;
