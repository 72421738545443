import React from "react";
import { Modal, Form, Input, Button, Row, Col, Select, Collapse } from "antd";
import { lineService } from "../../../../Service/line.services";
import { categoryService } from "../../../../Service/category.services";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { wagService } from "../../../../Service/wag.services";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class ManageModal extends React.Component {
  state = {
    data: this.props.data,
    isOpen: false,
    isLoading: false,
    id: "",
    link: "",
    type: null,
    score_type: null,
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  addUpdateLink = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.data) {
      wagService
        .editLinks(this.state.data.id, {
          link: this.state.link,
          type: this.state.type,
          score_type: this.state.score_type,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              data: this.props.data,
              isOpen: false,
              isLoading: false,
              id: "",
              link: "",
              type: null,
              score_type: null,
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            toast.error(error, {
              position: toast.POSITION.TOP_CENTER,
              pauseOnFocusLoss: false,
              autoClose: 2000,
            });
            this.setState({
              isLoading: false,
            });
          }
        );
    } else {
      wagService
        .addLinks({
          link: this.state.link,
          type: this.state.type,
          score_type: this.state.score_type,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              data: this.props.data,
              isOpen: false,
              isLoading: false,
              id: "",
              link: "",
              type: null,
              score_type: null,
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            toast.error(error, {
              position: toast.POSITION.TOP_CENTER,
              pauseOnFocusLoss: false,
              autoClose: 2000,
            });
            this.setState({
              isLoading: false,
            });
          }
        );
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data) {
        this.setState({
          isLoading: false,
          data: this.props.data,
          id: this.props.data.id,
          link: this.props.data.link ?? "",
          type: this.props.data.type ?? null,
          score_type: this.props.data.score_type ?? null,
        });
      } else
        this.setState({
          isOpen: false,
          isLoading: false,
          id: "",
          link: "",
          type: null,
          score_type: null,
        });
    }
  }


  componentDidMount() {
  }

  render() {
    return (
      <Modal
        title={this.state.data ? "Edit Jalur" : "Tambah Jalur"}
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.isLoading}
            onClick={(e) => this.addUpdateLink(e)}
          >
            {this.state.data ? "Edit Grup" : "Tambah Grup"}
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Row style={{ marginTop: 16 }} gutter={[16]}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Link Grup WA<span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="https://chat.whatsapp.com/kodeunik"
                type="text"
                value={this.state.link}
                id="link"
                onChange={(e) => this.setState({ link: e.target.value })}
              />
            </Col>
          </Row>
          <Row gutter={[12]} style={{ marginTop: 12 }}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Tipe Anggota <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Pilih Tipe Anggota"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ type: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this.state.type}
              >
              <Option value="participant">Pendaftar</Option>
              <Option value="student">Siswa Diterima</Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={[12]} style={{ marginTop: 12 }}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Tipe Seleksi <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Pilih Tipe Seleksi"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ score_type: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this.state.score_type}
              >
              <Option value="school">Seleksi PPDB</Option>
              <Option value="mahad">Seleksi Mahad</Option>
              </Select>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
