import React from "react";
import { Modal, Form, Button, Select, Row, Col } from "antd";

import { lineService } from "../../../../Service/line.services";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { participantService } from "../../../../Service/participant.service";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class ManageModal extends React.Component {
  constructor(props) {
    super(props);
    this.fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    this.fileExtension = ".xlsx";
  }

  state = {
    isOpen: false,
    data: null,
    line_id: null,
    type: null,
    lineName: "",
    lines: [],
    user: [],
  };

  downloadTemplate = async () => {
    let user = await participantService.getParticipant(1, 0, this.state.line_id, "", true, '', this.state.type === 'mahad' ? true : '');
    user = user?.data ?? [];

    user = await user.filter(
      (item) => item.registration_number !== null
    );

    let csvData = await user.map((item) => {
      let scoreTemplate = {
        Nama: item.name,
        "No Registrasi": item.registration_number,
        "Nilai Total": 0,
        Peringkat: 0,
        Status: 0,
        Keterangan: "",
      }

      let schoolScoreTemplate = {
        Nama: item.name,
        "No Registrasi": item.registration_number,
        // "Nilai Tes": 0,
        // "Nilai Piagam": 0,
        "Nilai Total": 0,
        Peringkat: 0,
        Status: 0,
        "Diterima di Kelas": item.class_type === 'superior' ? 'unggulan' : item.class_type,
        Keterangan: "",
        "Virtual Account": "",
      }

      return this.state.type === 'school' ? schoolScoreTemplate : scoreTemplate;
    });

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = await XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: this.fileType });
    const types = {
      school: 'PPDB',
      mahad: 'Mahad'
    }
    FileSaver.saveAs(data, "Template Import Nilai " + this.state.lineName + " " + types[this.state.type] + this.fileExtension);
    this.setState({
      isOpen: false,
      data: null,
      line_id: null,
      type: null,
      lineName: "",
      user: [],
    });
  };
  componentDidMount() {
    lineService.getLine(1).then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "lineId", label: `${item.name} (${item.line_category_name})`, value: item.id };
      });

      this.setState({ lines: data });
    });
  }

  handleChange = (e) => {
    const line = e;

    this.setState({ lineName: (this.state.lines.filter(item => item.value === line))[0]?.label, line_id : line })

  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({
      isOpen: false,
      data: null,
      line_id: null,
      type: null,
      lineName: "",
      user: [],
    });
  };

  render() {
    return (
      <>
        <Modal
          title={null}
          centered
          visible={this.state.isOpen}
          toggle={this.toggleModal}
          width={500}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Batalkan
            </Button>,
            <Button disabled={!this.state.line_id || !this.state.type} key="submit" type="primary" onClick={this.downloadTemplate}>
              Unduh Template
            </Button>,
          ]}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                textAlign: "center",
                marginBottom: "10%",
              }}
            >
              Unduh Template
            </p>

            <Row>
              <Col span={5}>
                <label>
                  Jalur <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col span={19}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Pilih Jalur"
                  optionFilterProp="children"
                  onChange={this.handleChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  value={this.state.line_id}
                >
                  {this.state &&
                    this.state.lines.map((item) => (
                      <Option value={item.value}>{item.label}</Option>
                    ))}
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: 12 }}>
              <Col span={5}>
                <label>
                  Tipe Seleksi <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col span={19}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Pilih Tipe Seleksi"
                  optionFilterProp="children"
                  onChange={e => this.setState({ type: e })}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  value={this.state.type}
                >
                  <Option value='school'>Seleksi PPDB</Option>
                  <Option value='mahad'>Seleksi Mahad</Option>
                </Select>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ManageModal;
