import React from "react";
import moment from "moment";
import {
  Card,
  Col,
  Row,
  Button,
  Input,
  Select,
  Tooltip,
  DatePicker,
  Statistic,
} from "antd";
import { toast } from "react-toastify";

import { participantService } from "../../Service/participant.service";
import { addressService } from "../../Service/address.services";
import { userService } from "../../Service/user.services";
import { phaseService } from "../../Service/phase.services";
import AddSchoolModal from "../Admin/Participants/School/ManageModal";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

const { Option } = Select;
const { Countdown } = Statistic;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this._modalManage = React.createRef();
    this.state.body = {
      name: "",
      nisn: "",
      birth_date: "",
      birth_place: "Pilih Kota",
      gender: "",
      class_type: "",
      is_mahad: "",
      phone_number: "",
      school_id: -1,
      password: "",
      address_detail: "",
      address_id: -1,
      father: "",
      mother: "",
      kk_number: "",
      nik: "",
      parent_phone: "",
      email: "",
    };
  }
  state = {
    isLoading: false,
    isAllLoaded: false,
    visible: false,
    school: [],
    data: this.props?.location?.state?.data,
    listAddress: [],
    address: [],
    // birth_place: [],
    phase: [],
    profile_image: "",
    page: 1,
    limit: 999999999,
    value: "",
    user_id: 0,
    progress: 1,

    errName: "",
    errNisn: "",
    errBirth_date: "",
    errBirth_place: "",
    errGender: "",
    errClassType: "",
    errMahad: "",
    errParent_phone: "",
    errSchool_id: "",
    errPassword: "",
    errAddress_detail: "",
    errAddress_id: "",
    errNik: "",
    errFather: "",
    errMother: "",
    errKk_number: "",
    errEmail: "",

    body: {
      name: "",
      nisn: "",
      birth_date: "",
      birth_place: "Pilih Kota",
      gender: "",
      class_type: "",
      is_mahad: "",
      phone_number: "",
      school_id: -1,
      password: "",
      address_detail: "",
      address_id: -1,
      nik: "",
      father: "",
      mother: "",
      kk_number: "",
      parent_phone: "",
      email: "",
    },
  };
  showModal = () => {
    this._modalManage.current.toggleModal();
  };

  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({
        isLoading: false,
        name: "",
        nisn: "",
        birth_date: "",
        // birth_place: [],
        gender: "",
        phone_number: "",
        school_id: -1,
        password: "",
        address_detail: "",
        address_id: -1,
        father: "",
        mother: "",
        kk_number: "",
        nik: "",
        parent_phone: "",
        email: "",
      });
      localStorage.clear();
      localStorage.setItem("USER_TOKEN", resp.data);
      const userDecode = jwtDecode(resp.data);
      localStorage.setItem("USER_DATA", JSON.stringify(userDecode));
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: true,
        autoClose: 2000,
      });
      setTimeout(function () {
        window.location.assign("/profile/summary");
      }, 3000);
    } else {
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: true,
        autoClose: 2000,
      });
    }
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleChange = (type, e) => {
    if (type === "city") {
      this.setState({ address: [] });
      const fields = e.split(", ");
      const city = fields[0];
      const province = fields[1];
      const page = 1;
      const limit = 999999999;

      addressService.getAddress(city, province, page, limit).then((resp) => {
        let data = resp.data.map((item) => {
          return { name: "addressId", label: item.district, value: item.id };
        });
        data.unshift({
          name: "addressId",
          label: "Pilih Kecamatan",
          value: -1,
        });
        this.setState({ address: data });
      });
    } else if (type === "district") {
      const fields = e.split(",");

      const address_id = fields[0];

      let body = this.state.body;

      body.address_id = parseInt(address_id);
      this.setState({ body, errAddress_id: "" });
    }
  };
  onChange(date, dateString) {
    let { body } = this.state;
    body.birth_date = dateString;
    this.setState({ body, errBirth_date: "" });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    phaseService
      .getScheduleScore(
        1,
        999999999,
        "registration",
        moment().format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss"),
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({ phase: resp.data, isLoading: false });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );

    participantService
      .getSchool(this.state.page, this.state.limit, this.state.value)
      .then((resp) => {
        let data = resp.data.map((item) => {
          return { name: "schoolId", label: item.name, value: item.id };
        });
        data.unshift({ name: "schoolId", label: "Pilih Sekolah", value: -1 });
        this.setState({ school: data, isLoading: false });
      });

    addressService.getListAddress(1, 999999999).then((resp) => {
      let data = resp.data.map((item) => {
        return {
          name: "addressId",
          label: item.city + ", " + item.province,
          value: item.id,
        };
      });
      // let birthPlace = resp.data.map((item) => {
      //   return { name: "birthPlace", label: item.city, value: item.city };
      // });
      data.unshift({ name: "addressId", label: "Pilih Kota", value: -1 });
      this.setState({
        listAddress: data,
        // birth_place: birthPlace,
        isLoading: false,
        isAllLoaded: true
      });
    });
  }

  handleSubmit = (e) => {
    if (this.state.body.name === "")
      this.setState({ errName: "Nama Wajib Diisi!" });
    if (this.state.body.nisn === "")
      this.setState({ errNisn: "NISN Wajib Diisi!" });
    if (this.state.body.nik === "")
      this.setState({ errNik: "NIK Wajib Diisi!" });
    if (this.state.body.birth_place === "Pilih Kota")
      this.setState({ errBirth_place: "Tempat Lahir Wajib Diisi!" });
    if (this.state.body.birth_date === "")
      this.setState({ errBirth_date: "Tanggal Lahir Wajib Diisi!" });
    if (this.state.body.gender === "")
      this.setState({ errGender: "Jenis Kelamin Wajib Diisi!" });
    if (this.state.body.father === "")
      this.setState({ errFather: "Nama Ayah Wajib Diisi!" });
    if (this.state.body.mother === "")
      this.setState({ errMother: "Nama Ibu Wajib Diisi!" });
    if (this.state.body.address_detail === "")
      this.setState({ errAddress_detail: "Alamat Lengkap Wajib Diisi!" });
    if (this.state.body.address_id === -1)
      this.setState({ errAddress_id: "Alamat Wajib Diisi!" });
    if (this.state.body.kk_number === "")
      this.setState({ errKk_number: "Nomor KK Wajib Diisi!" });
    if (this.state.body.school_id === -1)
      this.setState({ errSchool_id: "Asal Sekolah Wajib Diisi!" });
    if (this.state.body.parent_phone === "")
      this.setState({ errParent_phone: "No Telepon Orang Tua Wajib Diisi!" });
    if (this.state.body.password === "")
      this.setState({ errPassword: "Password Wajib Diisi!" });
    if (this.state.body.class_type === "")
      this.setState({ errClassType: "Harap Pilih Jenis Kelas!" });
    if (this.state.body.is_mahad === "")
      this.setState({ errMahad: "Pilihan Tinggal di Ma'had Wajib Diisi!" });
    let body = this.state.body;
    userService.register(body).then(
      (resp) => {
        this.onUpdated(resp);
      },

      (error) => {
        this.onUpdated({
          isUpdated: false,
          status: false,
          message: error,
          data: null,
        });
      }
    );
  };

  render() {
    let { body } = this.state;
    let data = this.state;

    if(!data.isAllLoaded){
      return (
        <></>
      );
    } else if (data.phase?.length > 0 && moment().isBetween(moment(data.phase[0].start_date), moment(data.phase[0].end_date))) {
      return (
        <React.Fragment>
          <Row style={{marginTop:"24px", marginBottom:"24px"}}>
            <Col xs={0} sm={0} md={5} lg={5}></Col>
            <Col xs={24} sm={24} md={14} lg={14}>
              <div>
                <Card
                  style={{
                    
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        NISN <span style={{ color: "red" }}> *</span>
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Input
                        placeholder="Masukkan NISN"
                        name="nisn"
                        value={body && body.nisn}
                        onChange={(e) => {
                          body.nisn = e.target.value;
                          this.setState({ body, errNisn: "" });
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <span style={{ color: "red" }}>{this.state.errNisn}</span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col  xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        Password <span style={{ color: "red" }}> *</span>{" "}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      sm={18}
                      md={18}
                      lg={18}
                    >
                      <Input.Password
                        placeholder="Masukkan Password Minimal 6 Digit"
                        name="password"
                        onChange={(e) => {
                          body.password = e.target.value;
                          this.setState({ body, errPassword: "" });
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errPassword}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        Jenis Kelas <span style={{ color: "red" }}>
                          {" "}
                          *
                        </span>{" "}
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Pilih Kelas"
                        optionFilterProp="children"
                        onChange={(e) => {
                          body.class_type = e;
                          this.setState({ body, errClassType: "" });
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        value={body && body.class_type}
                      >
                        <Option value="">Pilih Kelas</Option>
                        <Option value="reguler">Reguler</Option>
                        <Option value="superior">Unggulan</Option>
                        <Option value="excelent">Tahfidz (Excelent)</Option>
                      </Select>
                      <span style={{ color: "red" }}>
                        {this.state.errClassType}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        Tinggal di Ma'had <span style={{ color: "red" }}>
                          {" "}
                          *
                        </span>{" "}
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Tinggal di Ma'had"
                        optionFilterProp="children"
                        onChange={(e) => {
                          body.is_mahad = e;
                          this.setState({ body, errMahad: "" });
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        value={body && body.is_mahad}
                      >
                        <Option value="">Ingin Tinggal di Ma'had (Asrama)?</Option>
                        <Option value={true}>Ya</Option>
                        <Option value={false}>Tidak</Option>
                      </Select>
                      <span style={{ color: "red" }}>
                        {this.state.errMahad}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        Nama <span style={{ color: "red" }}> *</span>{" "}
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Input
                        style={{ width: "100%" }}
                        type="text"
                        placeholder="Masukkan Nama Lengkap"
                        name="firstName"
                        value={body && body.name}
                        onChange={(e) => {
                          body.name = e.target.value;
                          this.setState({ body, errName: "" });
                        }}
                      />
                      <span style={{ color: "red" }}>{this.state.errName}</span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        Jenis Kelamin <span style={{ color: "red" }}>
                          {" "}
                          *
                        </span>{" "}
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Jenis Kelamin"
                        optionFilterProp="children"
                        onChange={(e) => {
                          body.gender = e;
                          this.setState({ body, errGender: "" });
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        value={body && body.gender}
                      >
                        <Option value="">Jenis Kelamin</Option>
                        <Option value="male">Laki-Laki</Option>
                        <Option value="female">Perempuan</Option>
                      </Select>
                      <span style={{ color: "red" }}>
                        {this.state.errGender}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        No Kartu Keluarga{" "}
                        <span style={{ color: "red" }}> *</span>
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Input
                        placeholder="Masukkan Nomor Kartu Keluarga"
                        name="nik"
                        value={body && body.kk_number}
                        onChange={(e) => {
                          body.kk_number = e.target.value;
                          this.setState({ body, errKk_number: "" });
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errKk_number}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        NIK <span style={{ color: "red" }}> *</span>
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Input
                        type="text"
                        placeholder="Masukkan NIK"
                        name="nik"
                        value={body && body.nik}
                        onChange={(e) => {
                          body.nik = e.target.value;
                          this.setState({ body, errNik: "" });
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <span style={{ color: "red" }}>{this.state.errNik}</span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        Nama Ayah<span style={{ color: "red" }}> *</span>{" "}
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Input
                        style={{ width: "100%" }}
                        type="text"
                        placeholder="Masukkan Nama Ayah"
                        name="firstName"
                        value={body && body.father}
                        onChange={(e) => {
                          body.father = e.target.value;
                          this.setState({ body, errFather: "" });
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errFather}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        Nama Ibu<span style={{ color: "red" }}> *</span>{" "}
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Input
                        style={{ width: "100%" }}
                        type="text"
                        placeholder="Masukkan Nama Ibu"
                        name="firstName"
                        value={body && body.mother}
                        onChange={(e) => {
                          body.mother = e.target.value;
                          this.setState({ body, errMother: "" });
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errMother}
                      </span>
                    </Col>
                  </Row>
                  <Row gutter={[4]}>
                    <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        Tempat, Tanggal Lahir
                        <span style={{ color: "red" }}> *</span>
                      </p>
                    </Col>
                    <Col
                      style={{ marginTop: 12 }}
                      xs={24}
                      sm={10}
                      md={10}
                      lg={10}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Pilih Kota"
                        optionFilterProp="children"
                        onChange={(e) => {
                          body.birth_place = e;
                          this.setState({ body, errBirth_place: "" });
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        value={body && body.birth_place}
                      >
                        {data &&
                          data?.listAddress?.map((item) => (
                            <Option value={item.label}>{item.label}</Option>
                          ))}
                      </Select>
                      <span style={{ color: "red" }}>
                        {this.state.errBirth_place}
                      </span>
                    </Col>
                    <Col style={{ marginTop: 12 }} xs={24} sm={8} md={8} lg={8}>
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Tanggal Lahir"
                        value={body?.birth_date ? dayjs(body.birth_date) : ""}
                        onChange={(date, dateString) =>
                          this.onChange(date, dateString)
                        }
                      ></DatePicker>
                      <span style={{ color: "red" }}>
                        {this.state.errBirth_date}
                      </span>
                    </Col>
                  </Row>
                  <Row gutter={[4]}>
                    <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        Asal Sekolah <span style={{ color: "red" }}> *</span>
                      </p>
                    </Col>
                    <Col
                      style={{ marginTop: 12 }}
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Asal Sekolah"
                        optionFilterProp="children"
                        onChange={(e) => {
                          body.school_id = e;
                          this.setState({ body, errSchool_id: "" });
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        value={body && body.school_id}
                      >
                        {data &&
                          data?.school?.map((item) => (
                            <Option value={item.value}>{item.label}</Option>
                          ))}
                      </Select>
                      <span style={{ color: "red" }}>
                        {this.state.errSchool_id}
                      </span>
                    </Col>
                    <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                      <Tooltip title="Tambah sekolah jika sekolahmu tidak ada di list">
                        <Button
                          style={{ width: "100%" }}
                          onClick={(e) => this.showModal()}
                        >
                          Tambah Sekolah
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        No. Telepon Orang Tua
                        <span style={{ color: "red" }}> *</span>
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Input
                        placeholder="Masukkan No Telepon Orang Tua"
                        name="phone"
                        value={body && body.parent_phone}
                        onChange={(e) => {
                          body.parent_phone = e.target.value;
                          this.setState({ body, errParent_phone: "" });
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errParent_phone}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">No. Telepon </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Input
                        placeholder="Masukkan No Telepon Siswa"
                        name="phone"
                        value={body && body.phone_number}
                        onChange={(e) => {
                          body.phone_number = e.target.value;
                          this.setState({ body });
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        Email
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Input
                        placeholder="Masukkan Email"
                        name="email"
                        value={body && body.email}
                        onChange={(e) => {
                          body.email = e.target.value;
                          this.setState({ body, errEmail: "" });
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errEmail}
                      </span>
                    </Col>
                  </Row>

                  <Row gutter={[4]}>
                    <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        {" "}
                        Alamat <span style={{ color: "red" }}> *</span>{" "}
                      </p>
                    </Col>

                    <Col style={{ marginTop: 12 }} xs={24} sm={9} md={9} lg={9}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Pilih Kota"
                        optionFilterProp="children"
                        onChange={(e) => this.handleChange("city", e)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        value={body && body.city}
                      >
                        {data &&
                          data?.listAddress?.map((item) => (
                            <Option value={item.label}>{item.label}</Option>
                          ))}
                      </Select>
                    </Col>
                    <Col style={{ marginTop: 12 }} xs={24} sm={9} md={9} lg={9}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Pilih Kecamatan"
                        optionFilterProp="children"
                        onChange={(e) => this.handleChange("district", e)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        value={body && body.district}
                      >
                        {data &&
                          data.address.map((item) => (
                            <Option value={item.value + "," + item.label}>
                              {item.label}
                            </Option>
                          ))}
                      </Select>
                      <span style={{ color: "red" }}>
                        {this.state.errAddress_id}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={0} sm={6} md={6} lg={6}></Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Input
                        type="text"
                        placeholder="Masukkan Alamat Lengkap"
                        name="address"
                        value={body && body.address_detail}
                        onChange={(e) => {
                          body.address_detail = e.target.value;
                          this.setState({ body, errAddress_detail: "" });
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errAddress_detail}
                      </span>
                    </Col>
                  </Row>
                  <div style={{ float: "right", marginTop: "4%" }}>
                    <Row
                      // style={{ marginTop: 16, float: "right" }}
                      gutter={[4]}
                      justify={"end"}
                    >
                      <Col>
                        <Button
                          loading={this.state.isLoading}
                          type="primary"
                          onClick={this.handleSubmit}
                          disabled={
                            data
                              ? data.name === null ||
                                data.nisn === null ||
                                data.birth_place === null ||
                                data.birth_date === null ||
                                data.gender === null ||
                                data.school_id === null ||
                                data.grade === null ||
                                data.category_id === null ||
                                data.address_id === null ||
                                data.address_detail === null
                              : ""
                          }
                        >
                          Kirim Data & Daftar
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
              <AddSchoolModal
                ref={this._modalManage}
                onUpdated={(resp) => {
                  if (resp.status) {
                    toast.success(resp.message, {
                      position: toast.POSITION.TOP_CENTER,
                      pauseOnFocusLoss: true,
                      autoClose: 2000,
                    });
                    participantService
                      .getSchool(
                        this.state.page,
                        this.state.limit,
                        this.state.value
                      )
                      .then((resp) => {
                        let data = resp.data.map((item) => {
                          return {
                            name: "schoolId",
                            label: item.name,
                            value: item.id,
                          };
                        });
                        data.unshift({
                          name: "schoolId",
                          label: "Pilih Sekolah",
                          value: -1,
                        });
                        this.setState({ school: data, isLoading: false });
                      });
                  } else {
                    toast.error(resp.message, {
                      position: toast.POSITION.TOP_CENTER,
                      pauseOnFocusLoss: true,
                      autoClose: 2000,
                    });
                  }
                }}
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    } else if (data.phase?.length > 0) {
      return (
        <React.Fragment>
          <Row style={{ background: "#fff" }}>
            <Col xs={0} sm={0} md={6} lg={6}></Col>
            <Col
              xs={0}
              sm={0}
              md={12}
              lg={12}
              style={{ textAlign: "center" }}
              className="font-medium-1"
            ></Col>

            <Col xs={0} sm={0} md={6} lg={6}></Col>
          </Row>
          <Row style={{ background: "#fff" }}>
            <Col xs={0} sm={0} md={6} lg={6}></Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={{ textAlign: "center" }}
            >
              <div className="font-medium-1">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h1>Pendaftaran Akan Dibuka Dalam </h1>
              </div>

              <Countdown
                style={{ fontSize: "1000px" }}
                // className="font-medium-1"
                value={moment(data?.phase[0]?.start_date)}
                format="D hari HH:mm:ss detik"
                onFinish={() => {
                  window.location.reload();
                }}
              />
            </Col>

            <Col xs={0} sm={0} md={6} lg={6}></Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Row style={{ background: "#fff" }}>
            <Col xs={0} sm={0} md={6} lg={6}></Col>
            <Col
              xs={0}
              sm={0}
              md={12}
              lg={12}
              style={{ textAlign: "center" }}
              className="font-medium-1"
            ></Col>

            <Col xs={0} sm={0} md={6} lg={6}></Col>
          </Row>
          <Row style={{ background: "#fff" }}>
            <Col xs={0} sm={0} md={6} lg={6}></Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={{ textAlign: "center" }}
            >
              <div className="font-medium-1">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h1>Pendaftaran Belum Dibuka</h1>
              </div>
            </Col>

            <Col xs={0} sm={0} md={6} lg={6}></Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}
export default RegisterPage;
