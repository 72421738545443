import { Divider, Card, Col, Row, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { participantService } from "../../../Service/participant.service";
import { announcementService } from "../../../Service/announcement.service";
import { phaseService } from "../../../Service/phase.services";
import { SettingService } from "../../../Service/setting.services";
import { scoreService } from "../../../Service/score.service";
import QRCode from "react-qr-code";
import { Parser } from "html-to-react";

class Ringkasan extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }

  state = {
    announcement: [],
    score: [],
    schedule: [],
    progress: "",
    settings: [],
    user_id: 0,
    line_id: 0,
    line_category_id: 0,
    schoolParticipantLink: "",
    mahadParticipantLink: "",
  };

  handleDownload = (e) => {
    window.open(this.state.settings.technical_document);
  };

  handleClick = (e) => { };

  componentDidMount() {
    window.scrollTo(0, 0);
    let userData = localStorage.getItem("USER_DATA");
    userData = JSON.parse(userData);
    this.setState({
      user_id: userData.id,
    });

    participantService.getProgress(userData.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({ progress: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    participantService.getBiodata(userData.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            line_id: resp.data.line_id,
            line_category_id: resp.data.line_category_id,
            schoolParticipantLink: resp.data?.school_participant_group_link ?? "",
            mahadParticipantLink: resp.data?.mahad_participant_group_link ?? "",
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    ).then(phaseService.getSchedule(1, 999999999, this.state.line_category_id).then(
      (resp) => {
        if (resp.status) {
          this.setState({ schedule: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    ));

    announcementService.getAnnouncement(1, 2, "", "", "").then(
      (resp) => {
        if (resp.status) {
          this.setState({ announcement: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );

    scoreService.getScore(1, 999999999, "", -1, -1, userData.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({ score: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );

    SettingService.getSetting().then(
      (resp) => {
        if (resp.status) {
          this.setState({
            settings: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }

  showModal = () => {
    this._modalManage.current.toggleModal();
  };

  render() {
    const types = {
      school: 'PPDB',
      superior: 'Kelas Unggulan',
      excelent: 'Kelas Excelent (Tahfidz)',
      mahad: 'Mahad'
    };

    return (
      <React.Fragment>
        <Row gutter={[12]}>
          <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={8}>
            <Card
              style={{
                borderColor:
                  this.state.progress.progress < 2 ? "#1890ff" : "",
                textAlign: "left",
                height: "100%",
                overflow: "hidden",
                padding: "2%",
              }}
            >
              <p
                className="font-normal text-bold-500"
                style={{ display: "inline-block" }}
              >
                Lengkapi data diri
              </p>
              <p className="font-small-2">
                Cek kembali data diri yang sudah kamu isi di menu Biodata, jika
                ada kesalahan segera perbaiki
              </p>
              <Row align="bottom">
                <Col xs={24} sm={12} md={24} lg={12}>
                  <p
                    className="font-large-1 text-bold-700"
                    style={{ marginBottom: 0 }}
                  >
                    1
                  </p>
                </Col>
                <Col xs={24} sm={12} md={24} lg={12} align="right">
                  <Link to="/profile/biodata">
                    <Button type="link">Selengkapnya</Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={8}>
            <Card
              className="font-medium-1"
              style={{
                borderColor:
                  this.state.progress.progress === 2 ? "#1890ff" : "",
                
                textAlign: "left",
                height: "100%",
                overflow: "hidden",
                padding: "2%",
              }}
            >
              <p
                className="font-normal text-bold-500"
                style={{ display: "inline-block" }}
              >
                Unggah Berkas dan Ikuti Tes
                <br />
              </p>

              <p className="font-small-2">
                Lengkapi berkas yang menjadi syarat administrasi di menu
                Pemberkasan dan Ikuti tes sesuai masing-masing jalur
              </p>
              <Row align="bottom">
                <Col xs={24} sm={12} md={24} lg={12}>
                  <p
                    className="font-large-1 text-bold-700"
                    style={{ marginBottom: 0 }}
                  >
                    2
                  </p>
                </Col>
                <Col xs={24} sm={12} md={24} lg={12} align="right">
                  <Link to="/profile/upload-file">
                    <Button
                      disabled={this.state.progress.progress < 2 ? true : false}
                      type="link"
                    >
                      Selengkapnya
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={8}>
            <Card
              className="font-medium-1"
              style={{
                borderColor: this.state.progress.progress >= 3 ? "#1890ff" : "",
                
                textAlign: "left",
                height: "100%",
                overflow: "hidden",
                padding: "2%",
              }}
            >
              <p
                className="font-normal text-bold-500"
                style={{ display: "inline-block" }}
              >
                Cek Hasil dan Daftar Ulang
              </p>
              <p className="font-small-2">
                Cek hasil tesmu di menu Hasil Seleksi dan jika lolos lakukan
                daftar ulang di menu Daftar Ulang
              </p>
              <Row align="bottom">
                <Col xs={24} sm={12} md={24} lg={12}>
                  <p
                    className="font-large-1 text-bold-700"
                    style={{ marginBottom: 0 }}
                  >
                    3
                  </p>
                </Col>
                <Col xs={24} sm={12} md={24} lg={12} align="right">
                  <Link to="/profile/result">
                    <Button
                      disabled={this.state.progress.progress < 3 ? true : false}
                      type="link"
                    >
                      Selengkapnya
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 24, display: this.state.schoolParticipantLink ? "flex" : "none" }} gutter={[12,12]}>
          <Col xs={24} md={this.state.mahadParticipantLink ? 12 : 24}>
            <Card
              style={{
                
                height: "100%",
                overflow: "hidden",
                padding: "2%",
              }}
            >
              <p className="font-medium-1 text-bold-700">Grup Whatsapp PPDB</p>
              <Divider style={{ marginTop: 0, borderColor: "#bebebe" }} />
              <p className="font-normal">Scan Untuk Bergabung Grup Whatsapp Seleksi PPDB</p>
              <QRCode size={150} value={this.state.schoolParticipantLink} />
              <br />
              <span className="font-normal">Link: </span>{" "}
              <a href={this.state.schoolParticipantLink} className="font-normal">
                {this.state.schoolParticipantLink}
              </a>
            </Card>
          </Col>
          <Col xs={this.state.mahadParticipantLink ? 24 : 0} md={this.state.mahadParticipantLink ? 12 : 0}>
            <Card
              style={{
                
                height: "100%",
                overflow: "hidden",
                padding: "2%",
              }}
            >
              <p className="font-medium-1 text-bold-700">Grup Whatsapp Mahad</p>
              <Divider style={{ marginTop: 0, borderColor: "#bebebe" }} />
              <p className="font-normal">Scan Untuk Bergabung Grup Whatsapp Seleksi Mahad</p>
              <QRCode size={150} value={this.state.mahadParticipantLink} />
              <br />
              <span className="font-normal">Link: </span>{" "}
              <a href={this.state.mahadParticipantLink} className="font-normal">
                {this.state.mahadParticipantLink}
              </a>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }} gutter={[12]}>
          <Col span={24}>
            <Card
              style={{
                
                height: "100%",
                overflow: "hidden",
                padding: "2%",
              }}
            >
              <Row>
                <Col span={12}>
                  <p className="font-medium-1 text-bold-700">Pengumuman</p>
                </Col>
                <Col span={12} align="right">
                  <Link to="/announcement">
                    <Button type="link">Selengkapnya</Button>
                  </Link>
                </Col>
                <Divider style={{ marginTop: 0, borderColor: "#bebebe" }} />
              </Row>
              {this.state.announcement.map((item) => {
                return (
                  <>
                    <Row>
                      <p
                        className="font-normal text-bold-700"
                        style={{ marginBottom: 0 }}
                      >
                        {item.title}
                      </p>
                    </Row>

                    <Row>
                      <p className="font-small-2">
                        {moment(item.created_at).format("LL")}
                      </p>
                    </Row>
                    <Row>
                      <p className="font-normal">{Parser().parse(item.content)}</p>
                    </Row>
                    <Divider style={{ marginTop: 0 }} />
                  </>
                );
              })}
            </Card>
          </Col>
        </Row>

        <Row gutter={[12]}>
          <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={12}>
            <Card
              style={{
                
                height: "100%",
                overflow: "hidden",
                padding: "2%",
              }}
            >
              <Row>
                <Col span={12}>
                  <p className="font-medium-1 text-bold-700">
                    Jadwal Pelaksanaan
                  </p>
                </Col>
                <Col span={12} align="right">
                  <Button onClick={this.handleDownload} type="link">
                    Unduh Petunjuk Teknis
                  </Button>
                </Col>
              </Row>
              <Divider style={{ marginTop: 0, borderColor: "#bebebe" }} />
              {this.state.schedule.map((item) => {
                return (
                  <Row>
                    <Col span={9}>
                      <p className="font-normal">{item.name}</p>
                    </Col>
                    <Col span={15}>
                      <p className="font-normal">
                        {": "}
                        {moment(item.start_date).isSame(moment(item.end_date))
                          ? moment(item.start_date).format("ll") +
                          " " +
                          moment(item.start_date).format("LT")
                          : moment(item.start_date).format("ll") +
                          " " +
                          moment(item.start_date).format("LT") +
                          " - " +
                          moment(item.end_date).format("ll") +
                          " " +
                          moment(item.end_date).format("LT")}
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </Card>
          </Col>
          <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={12}>
            <Card
              style={{
                
                height: "100%",
                overflow: "hidden",
                padding: "2%",
              }}
            >
              <Row>
                <Col span={12}>
                  <p className="font-medium-1 text-bold-700">Hasil Seleksi</p>
                </Col>
                <Col span={12} align="right">
                  <Link to="/profile/result">
                    <Button type="link">Selengkapnya</Button>
                  </Link>
                </Col>
              </Row>
              <Divider style={{ marginTop: 0, borderColor: "#bebebe" }} />
              {this.state.score.map((item) => (
                <Row gutter={[12]}>
                  <Col xs={24}>
                    <p className="font-medium-1 text-bold-500">
                      {types[item.type]}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p className="font-medium-1">
                      {item.line} ({item.line_category})
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p className="font-medium-1">
                      {item.is_passed_text}
                    </p>
                  </Col>
                </Row>
              ))}
            </Card>
          </Col>
        </Row>
        {/* </Card> */}
        {/* <ManageModal ref={this._modalManage} />  */}
      </React.Fragment>
    );
  }
}
export default Ringkasan;
