import React from "react";
import {
  Card,
  Col,
  Row,
  Button,
  Input,
  Select,
  Tooltip,
  DatePicker,
  Switch,
  Divider,
} from "antd";
import { toast } from "react-toastify";
import ImageDropzone from "../../../../Component/File/ImageDropzone";
import { participantService } from "../../../../Service/participant.service";
import { lineService } from "../../../../Service/line.services";
import { addressService } from "../../../../Service/address.services";
import { scoreService } from "../../../../Service/score.service";
import { userService } from "../../../../Service/user.services";
import { history } from "../../../../History";
import { jwtDecode } from "jwt-decode";
import AddSchoolModal from "../School/ManageModal";
import ReregistrationModal from "./ReregistrationModal";
import ReactToPrint from "react-to-print";
import { mainConfig } from "../../../../Config/mainConfig";
import dayjs from "dayjs";

const { Option } = Select;

function onBlur() { }

function onFocus() { }

function onSearch(val) { }

class manageParticipants extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalInfo = React.createRef();
    this._modalReregistration = React.createRef();

    let data = this.props && this.props.location.state.data;
    if (data) {
      this.state.body.name = data.name;
      this.state.body.user_id = data.id;
      this.state.body.nisn = data.nisn;
      this.state.body.registration_number = data.registration_number;
      this.state.body.birth_date = data.birth_date;
      this.state.body.birth_place = data.birth_place;
      this.state.body.phone_number = data.phone_number;
      this.state.body.gender = data.gender;
      this.state.body.class_type = data.class_type;
      this.state.body.is_mahad = data.is_mahad;
      this.state.body.school_id = data.school_id;

      this.state.body.line_id = data.line_id;
      this.state.body.address_detail = data.address_detail;
      this.state.body.address_id = data.address_id;
      this.state.body.city = data.city;
      this.state.body.district = data.district;
      this.state.body.father = data.father;
      this.state.body.mother = data.mother;
      this.state.body.kk_number = data.kk_number;
      this.state.body.nik = data.nik;
      this.state.body.parent_phone = data.parent_phone;
      this.state.body.email = data.email;
    } else {
      this.state.isNewUser = true;
      this.state.body = {
        name: "",
        user_id: 0,
        registration_number: "",
        nisn: "",
        birth_date: "",
        birth_place: null,
        gender: "",
        class_type: "",
        is_mahad: "",
        phone_number: "",
        school_id: -1,

        line_id: -1,
        old_password: "",
        password: "",
        address_detail: "",
        address_id: -1,
        city: "Semua Kota",
        district: "Semua Kecamatan",
        father: "",
        mother: "",
        kk_number: "",
        nik: "",
        parent_phone: "",
        email: "",
      };
    }
  }
  state = {
    isLoading: false,
    visible: false,
    school: [],
    data: this.props.location.state.data,
    lines: [],
    listAddress: [],
    address: [],
    profile_image: "",
    page: 1,
    limit: 999999999,
    value: "",
    user_id: 0,
    progress: 1,
    offline_approve: false,
    score: [],
    isNewUser: false,
    body: {
      name: "",
      user_id: 0,
      registration_number: "",
      nisn: "",
      birth_date: "",
      birth_place: null,
      gender: "",
      class_type: "",
      is_mahad: "",
      phone_number: "",
      school_id: -1,

      line_id: -1,
      old_password: "",
      password: "",
      address_detail: "",
      address_id: -1,
      city: "",
      district: "",
      nik: "",
      father: "",
      mother: "",
      kk_number: "",
      parent_phone: "",
      email: "",
    },
  };
  showModal = () => {
    this._modalManage.current.toggleModal();
  };
  showModalReregistration = () => {
    this._modalReregistration.current.toggleModal();
    this.setState({ reregistration: true });
  };

  handlePrintCard = () => {
    history.push({
      pathname: "/profile/card",
      state: {
        user_id: this.state.body.user_id,
      }
    });
  }

  onUpdated = (resp) => {
    if (resp.status) {
      participantService
        .getSchool(this.state.page, this.state.limit, this.state.value)
        .then((resp) => {
          let data = resp.data.map((item) => {
            return { name: "schoolId", label: item.name, value: item.id };
          });
          data.unshift({ name: "schoolId", label: "Semua Sekolah", value: -1 });
          this.setState({ school: data, isLoading: false });
        });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleChange = (type, e) => {
    if (type === "city") {
      let body = this.state.body;
      body.city = e;

      this.setState({ address: [] });
      const fields = e.split(", ");
      const city = fields[0];
      const province = fields[1];
      const page = 1;
      const limit = 999999999;

      addressService.getAddress(city, province, page, limit).then((resp) => {
        let data = resp.data.map((item) => {
          return { name: "addressId", label: item.district, value: item.id };
        });
        data.unshift({
          name: "addressId",
          label: "Semua Kecamatan",
          value: -1,
        });
        this.setState({ address: data });
      });
    } else if (type === "district") {
      const fields = e.split(",");
      const district = fields[1];
      const address_id = fields[0];

      let body = this.state.body;
      body.district = district;
      this.setState({ body });

      body.address_id = parseInt(address_id);
      this.setState({ body });
    }
  };
  onChange(date, dateString) {
    let { body } = this.state;
    body.birth_date = dateString;
    this.setState({ body });
  }

  dropImage = (files) => {
    let image = this.state;
    image.profile_image = URL.createObjectURL(files[0]);
    this.setState({ profile_image: files[0], image });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    participantService
      .getSchool(this.state.page, this.state.limit, this.state.value)
      .then((resp) => {
        let data = resp.data.map((item) => {
          return { name: "schoolId", label: item.name, value: item.id };
        });
        data.unshift({ name: "schoolId", label: "Semua Sekolah", value: -1 });
        this.setState({ school: data, isLoading: false });
      });

    lineService.getLine().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "lineId", label:`${item.name} (${item.line_category_name})`, value: item.id };
      });
      data.unshift({ name: "lineId", label: "Pilih Jalur", value: -1 });
      this.setState({ lines: data, isLoading: false });
    });
    addressService.getListAddress(1, 999999999).then((resp) => {
      let data = resp.data.map((item) => {
        return {
          name: "addressId",
          label: item.city + ", " + item.province,
          value: item.id,
        };
      });
      data.unshift({ name: "addressId", label: "Semua Kota", value: -1 });
      this.setState({
        listAddress: data,
        isLoading: false,
      });
    });

    participantService.getProgress(this.state.body.user_id).then(
      (resp) => {
        if (resp.status) {
          this.setState({ progress: resp.data.progress, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    scoreService
      .getScore(
        1,
        999999999,
        "",
        this.state.body.line_id,
        1,
        this.state.body.user_id
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({ score: resp.data, isLoading: false });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }

  handleLogin = (e) => {
    this.setState({ isLoading: true });
    let userData = localStorage.getItem("USER_DATA");
    userData = JSON.parse(userData);
    const admin_key = userData.admin_key;
    userService
      .login({
        username: this.state.body.nisn,
        password: admin_key,
      })
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              isLoading: false,
              data: null,
              nisn: "",
              username: "",
              password: "",
            });
            localStorage.clear();
            localStorage.setItem("USER_TOKEN", resp.data);
            const userDecode = jwtDecode(resp.data);
            localStorage.setItem("USER_DATA", JSON.stringify(userDecode));
            this.onUpdated(resp);
            setTimeout(function () {
              window.location.assign(
                userDecode.role === "admin"
                  ? "/profile/admin/summary"
                  : "/profile/summary"
              );
            }, 3000);
          } else {
            this.onUpdated(resp);
          }
        },
        (error) => {
          this.onUpdated({
            isUpdated: true,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  handleSubmit = () => {
    if (!this.state.isNewUser) {
      let body = this.state.body;
      body["profile_image"] = this.state.profile_image;
      body["payment_offline_approve"] = false;
      this.setState({ isLoading: true });
      participantService.editParticipant(this.state.body.user_id, body).then(
        (resp) => {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
            onClose: () => {
              this.setState({
                isLoading: false,
              });
              history.push("/profile/admin/participant/edit");
            },
          });
        },
        (error) => {
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
            onClose: () => {
              this.setState({
                isLoading: false,
              });
            },
          });
        }
      );
    } else {
      this.setState({ isLoading: true });
      let body = this.state.body;
      body["profile_image"] = this.state.profile_image;
      body["offline_approve"] = this.state.offline_approve;
      if(!this.state.offline_approve){
        delete body.line_id;
      }
      participantService.addParticipant(body).then(
        (resp) => {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
            onClose: () => {
              this.setState({
                isLoading: false,
                body: {
                  name: "",
                  user_id: 0,
                  registration_number: "",
                  nisn: "",
                  birth_date: "",
                  birth_place: null,
                  gender: "",
                  phone_number: "",
                  school_id: -1,
                  line_id: -1,
                  old_password: "",
                  password: "",
                  address_detail: "",
                  address_id: -1,
                  city: "",
                  district: "",
                  nik: "",
                  father: "",
                  mother: "",
                  kk_number: "",
                  parent_phone: "",
                  email: "",
                },
              });
              history.push("/profile/admin/participant");
            },
          });
        },
        (error) => {
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
            onClose: () => {
              this.setState({
                isLoading: false,
              });
            },
          });
        }
      );
    }
  };

  render() {
    let { body } = this.state;
    let data = this.state;
    let classTypes = {
      reguler: 'Reguler',
      superior: 'Unggulan',
      excelent: 'Excelent (Tahfidz)'
    }
    return (
      <React.Fragment>
        <div style={{ marginTop: 20 }}>
          <div>
            <Card
              gutter={[16]}
              style={{
                
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Row gutter={[4]}>
                <Col
                  style={{ marginTop: 40 }}
                  xs={0}
                  sm={6}
                  md={6}
                  lg={6}
                ></Col>
                <Col style={{ marginTop: 40 }}>
                  <ImageDropzone
                    onDropped={this.dropImage}
                    item={`user/image/${body && body.user_id}`}
                  />
                  <br />
                  <p
                    className="font-small-2"
                    style={{ color: "red", marginLeft: "2%" }}
                  >
                    Ukuran Foto maks 500 KB
                  </p>
                </Col>
              </Row>
              {body && body.registration_number !== "" && (
                <Row style={{ marginTop: 12 }} gutter={[4]}>
                  <Col xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal">Nomor Peserta</p>
                  </Col>
                  <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                    <p className="font-normal">
                      {body && body.registration_number}
                    </p>
                  </Col>
                </Row>
              )}
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    NISN <span style={{ color: "red" }}> *</span>
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    type="text"
                    placeholder="Masukkan NISN"
                    name="nisn"
                    value={body && body.nisn}
                    onChange={(e) => {
                      body.nisn = e.target.value;
                      this.setState({ body });
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
              </Row>
              {this.state.data !== null ? (
                <Row gutter={[4]}>
                  <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal"> Password</p>
                  </Col>
                  <Col
                    style={{ marginTop: 12, display: "flex", alignItems:"center"}}
                    xs={24}
                    sm={18}
                    md={18}
                    lg={18}
                  >
                    <Tooltip title="Isi jika ingin mengganti password">
                      <Input.Password
                        placeholder="Masukkan Password Baru (Jika Ingin Mengganti)"
                        name="password"
                        onChange={(e) => {
                          body.password = e.target.value;
                          this.setState({ body });
                        }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              ) : (
                <Row gutter={[4]}>
                  <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal"> Password</p>
                  </Col>
                  <Col
                    style={{ marginTop: 12, display: "flex", alignItems:"center"}}
                    xs={24}
                    sm={18}
                    md={18}
                    lg={18}
                  >
                    <Input.Password
                      placeholder="Masukkan Password"
                      name="password"
                      onChange={(e) => {
                        body.password = e.target.value;
                        this.setState({ body });
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row style={{ marginTop: 12, display: this.state.isNewUser ? "none" : "flex" }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        Jalur <span style={{ color: "red" }}> *</span>{" "}
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Jalur"
                        disabled={this.state.progress >= 4 ? true : false}
                        optionFilterProp="children"
                        onChange={(e) => {
                          body.line_id = e;
                          this.setState({ body });
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        value={body && body.line_id}
                      >
                        {data &&
                          data.lines.map((item) => (
                            <Option value={item.value}>{item.label}</Option>
                          ))}
                      </Select>
                    </Col>
                  </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    Jenis Kelas <span style={{ color: "red" }}>
                      {" "}
                      *
                    </span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Select
                    showSearch
                    disabled={this.state.progress >= 4 ? true : false}
                    style={{ width: "100%" }}
                    placeholder="Pilih Kelas"
                    optionFilterProp="children"
                    onChange={(e) => {
                      body.class_type = e;
                      this.setState({ body });
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body && body.class_type}
                  >
                    <Option value="">Pilih Kelas</Option>
                    <Option value="reguler">Reguler</Option>
                    <Option value="superior">Unggulan</Option>
                    <Option value="excelent">Tahfidz (Excelent)</Option>
                  </Select>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    Tinggal di Ma'had <span style={{ color: "red" }}>
                      {" "}
                      *
                    </span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Select
                    showSearch
                    disabled={this.state.progress >= 4 ? true : false}
                    style={{ width: "100%" }}
                    placeholder="Tinggal di Ma'had"
                    optionFilterProp="children"
                    onChange={(e) => {
                      body.is_mahad = e;
                      this.setState({ body });
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body && body.is_mahad}
                  >
                    <Option value="">Ingin Tinggal di Ma'had (Asrama)?</Option>
                    <Option value={true}>Ya</Option>
                    <Option value={false}>Tidak</Option>
                  </Select>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Nama <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Masukkan Nama Lengkap"
                    name="firstName"
                    value={body && body.name}
                    onChange={(e) => {
                      body.name = e.target.value;
                      this.setState({ body });
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Jenis Kelamin <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Jenis Kelamin"
                    optionFilterProp="children"
                    onChange={(e) => {
                      body.gender = e;
                      this.setState({ body });
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body && body.gender}
                  >
                    <Option value="">Jenis Kelamin</Option>
                    <Option value="male">Laki-Laki</Option>
                    <Option value="female">Perempuan</Option>
                  </Select>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    No Kartu Keluarga <span style={{ color: "red" }}> *</span>
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    type="text"
                    placeholder="Masukkan Nomor Kartu Keluarga"
                    name="nik"
                    value={body && body.kk_number}
                    onChange={(e) => {
                      body.kk_number = e.target.value;
                      this.setState({ body });
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    NIK <span style={{ color: "red" }}> *</span>
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    type="text"
                    placeholder="Masukkan NIK"
                    name="nik"
                    value={body && body.nik}
                    onChange={(e) => {
                      body.nik = e.target.value;
                      this.setState({ body });
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Nama Ayah<span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Masukkan Nama Ayah"
                    name="firstName"
                    value={body && body.father}
                    onChange={(e) => {
                      body.father = e.target.value;
                      this.setState({ body });
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Nama Ibu<span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Masukkan Nama Ibu"
                    name="firstName"
                    value={body && body.mother}
                    onChange={(e) => {
                      body.mother = e.target.value;
                      this.setState({ body });
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[16]}>
                <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    Tempat, Tanggal Lahir
                    <span style={{ color: "red" }}> *</span>
                  </p>
                </Col>
                <Col style={{ marginTop: 12, display: "flex", alignItems:"center"}} xs={24} sm={10} md={10} lg={10}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Semua Kota"
                    optionFilterProp="children"
                    onChange={(e) => {
                      body.birth_place = e;
                      this.setState({ body });
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body && body.birth_place}
                  >
                    {data &&
                      data.listAddress.map((item) => (
                        <Option value={item.label}>{item.label}</Option>
                      ))}
                  </Select>
                </Col>
                <Col style={{ marginTop: 12, display: "flex", alignItems:"center"}} xs={24} sm={8} md={8} lg={8}>
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Tanggal Lahir"
                      value={ body?.birth_date ? dayjs(body?.birth_date) : ''}
                      onChange={(date, dateString) =>
                        this.onChange(date, dateString)
                      }
                    ></DatePicker>
                </Col>
              </Row>
              <Row gutter={[16]}>
                <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    Asal Sekolah <span style={{ color: "red" }}> *</span>
                  </p>
                </Col>
                <Col style={{ marginTop: 12, display: "flex", alignItems:"center"}} xs={24} sm={12} md={12} lg={12}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Asal Sekolah"
                    optionFilterProp="children"
                    onChange={(e) => {
                      body.school_id = e;
                      this.setState({ body });
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body && body.school_id}
                  >
                    {data &&
                      data.school.map((item) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                  </Select>
                </Col>
                <Col style={{ marginTop: 12, display: "flex", alignItems:"center"}} xs={24} sm={6} md={6} lg={6}>
                  <Tooltip title="Tambah sekolah jika sekolahmu tidak ada di list">
                    <Button
                      style={{ width: "100%" }}
                      onClick={(e) => this.showModal()}
                    >
                      + Sekolah
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    No. Telepon Orang Tua
                    <span style={{ color: "red" }}> *</span>
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    placeholder="Masukkan No Telepon Orang Tua"
                    name="phone"
                    value={body && body.parent_phone}
                    onChange={(e) => {
                      body.parent_phone = e.target.value;
                      this.setState({ body });
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">No. Telepon </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    placeholder="Masukkan no telepon"
                    name="phone"
                    value={body && body.phone_number}
                    onChange={(e) => {
                      body.phone_number = e.target.value;
                      this.setState({ body });
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    Email
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    placeholder="Masukkan email"
                    name="email"
                    value={body && body.email}
                    onChange={(e) => {
                      body.email = e.target.value;
                      this.setState({ body });
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[16]}>
                <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Alamat <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>

                <Col style={{ marginTop: 12, display: "flex", alignItems:"center"}} xs={24} sm={9} md={9} lg={9}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Semua Kota"
                    optionFilterProp="children"
                    onChange={(e) => this.handleChange("city", e)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body && body.city}
                  >
                    {data &&
                      data.listAddress.map((item) => (
                        <Option value={item.label}>{item.label}</Option>
                      ))}
                  </Select>
                </Col>
                <Col style={{ marginTop: 12, display: "flex", alignItems:"center"}} xs={24} sm={9} md={9} lg={9}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Semua Kecamatan"
                    optionFilterProp="children"
                    onChange={(e) => this.handleChange("district", e)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body && body.district}
                  >
                    {data &&
                      data.address.map((item) => (
                        <Option value={item.value + "," + item.label}>
                          {item.label}
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={0} sm={6} md={6} lg={6}></Col>
                <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
                  <Input
                    type="text"
                    placeholder="Masukkan alamat lengkap"
                    name="address"
                    value={body && body.address_detail}
                    onChange={(e) => {
                      body.address_detail = e.target.value;
                      this.setState({ body });
                    }}
                  />
                </Col>
              </Row>
              {this.state.isNewUser ?
                <>
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">Berkas Terverifikasi</p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18} style={{display: "flex", alignItems: "center"}}>
                      <Switch
                        checkedChildren="Ya"
                        unCheckedChildren="Tidak"
                        title="Dokumen Terverifikasi"
                        onChange={(e) => this.setState({ offline_approve: e })}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12, display: this.state.offline_approve ? "flex" : "none" }} gutter={[4]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                      <p className="font-normal">
                        Jalur <span style={{ color: "red" }}> *</span>{" "}
                      </p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Jalur"
                        disabled={this.state.progress >= 4 ? true : false}
                        optionFilterProp="children"
                        onChange={(e) => {
                          body.line_id = e;
                          this.setState({ body });
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        value={body && body.line_id}
                      >
                        {data &&
                          data.lines.map((item) => (
                            <Option value={item.value}>{item.label}</Option>
                          ))}
                      </Select>
                    </Col>
                  </Row>
                </>
                : <></>
              }

              <Row style={{ float: "right" }} gutter={[8]}>
                <Col style={{ marginTop: 16 }}>
                  <Button
                    loading={this.state.isLoading}
                    type="primary"
                    onClick={this.handleSubmit}
                  >
                    {!this.state.data ? "Tambah Peserta" : "Perbarui Peserta"}
                  </Button>
                </Col>
                {this.state.data && (
                  <Col style={{ marginTop: 16 }}>
                    <Button
                      loading={this.state.isLoading}
                      key="login"
                      type="primary"
                      onClick={(e) => this.showModalReregistration()}
                      disabled={this.state.progress < 4}
                    >
                      Daftar Ulang
                    </Button>
                  </Col>
                )}
                {this.state.data && (
                  <Col style={{ marginTop: 16 }}>
                    <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                      <Button
                        loading={this.state.isLoading}
                        disabled={this.state.progress <= 2}
                        type="primary"
                        onClick={this.handlePrintCard}
                      >
                        Cetak Kartu Peserta
                      </Button>
                    </Tooltip>
                    {/* <ReactToPrint
                      pageStyle="
                    @page {
                      size: a5;
                      margin: 0;? true : false
                    }
                    "
                      trigger={
                        this.state.progress <= 2
                          ? false
                          : () => (
                            <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                              <Button
                                type="primary"
                                loading={this.state.isLoading}
                                disabled={this.state.progress <= 2}
                                style={{
                                  borderRadius: "5%",
                                }}
                              >
                                Cetak Kartu Peserta
                              </Button>
                            </Tooltip>
                          )
                      }
                      content={() => this.componentRef}
                    />
                    <div
                      className="print-source"
                      ref={(el) => (this.componentRef = el)}
                    >
                      <React.Fragment>
                        <div className="print-source">
                          <Card
                          >
                            <Row align="middle" gutter={[16]}>
                              <Col
                                span={4}
                                style={{ textAlign: "right", marginBottom: 16 }}
                              >
                                <img
                                  src={
                                    window.location.protocol +
                                    "//" +
                                    window.location.host +
                                    "/logomts.png"
                                  }
                                  style={{ width: "45%" }}
                                  alt=""
                                />
                              </Col>
                              <Col span={14}>
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "9pt",
                                    fontWeight: "bold",
                                    marginLeft: "3%",
                                  }}
                                >
                                  KARTU PESERTA PPDB <br />
                                  MTs Negeri 1 Lamongan <br />
                                  {this.state.year}
                                </p>
                              </Col>
                            </Row>
                            <Divider
                              style={{
                                borderWidth: "3px",
                                borderColor: "#000000",
                                margin: 0,
                              }}
                            ></Divider>
                            <Row>
                              <Col
                                span={20}
                                style={{
                                  textAlign: "left",
                                  marginTop: "48px",
                                }}
                              >
                                <Row>
                                  <Col span={6}>
                                    <label>NISN</label>
                                  </Col>
                                  <Col span={17}>
                                    <Input
                                      value={
                                        this.state.data && this.state.data.nisn
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Row style={{ marginTop: "2%" }}>
                                  <Col span={6}>
                                    <label>Nama</label>
                                  </Col>
                                  <Col span={17}>
                                    <Input
                                      value={
                                        this.state.data && this.state.data.name
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Row style={{ marginTop: "2%" }}>
                                  <Col span={6}>
                                    <label>Nomor Peserta</label>
                                  </Col>
                                  <Col span={17}>
                                    <Input
                                      value={
                                        this.state.data &&
                                        this.state.data.registration_number
                                      }
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={3} style={{ marginTop: "4%" }}>
                                <img
                                  style={{
                                    width: "113.38582677px",
                                    height: "113.38582677px",
                                  }}
                                  src={`${mainConfig.apiUrl}/user/image/${this.state.user_id}`}
                                  alt="Foto Profil Peserta"
                                />
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "2%", textAlign: "left" }}>
                              <Col span={5}>
                                <label>Asal Sekolah</label>
                              </Col>
                              <Col span={19}>
                                <Input
                                  value={
                                    this.state.data && this.state.data.school
                                  }
                                />
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "2%", textAlign: "left" }}>
                              <Col span={5}>
                                <label>Jalur</label>
                              </Col>
                              <Col span={19}>
                                <Input
                                  value={`${this.state?.data?.line} (${this.state?.data?.line_category})`}
                                />
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "2%", textAlign: "left" }}>
                              <Col span={5}>
                                <label>Pilihan Kelas</label>
                              </Col>
                              <Col span={19}>
                                <Input value={classTypes[this.state?.data?.class_type]} />
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "2%", textAlign: "left" }}>
                              <Col span={5}>
                                <label>Pilihan Mahad</label>
                              </Col>
                              <Col span={19}>
                                <Input value={this.state?.data?.is_mahad ? 'Ya' : 'Tidak'} />
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "2%", textAlign: "left" }}>
                              <Col span={5}>
                                <label>Alamat</label>
                              </Col>
                              <Col span={19}>
                                <Input
                                  value={
                                    this.state &&
                                    this.state.data.district +
                                    ", " +
                                    this.state.data.city
                                  }
                                />
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "4%", textAlign: "left" }}>
                              <Col>
                                <p
                                  className="font-small-2"
                                  padding="0"
                                  margin="0"
                                >
                                  Gunakan username{" "}
                                  <b>
                                    CBT{this.state.data && this.state.data.nisn}
                                  </b>{" "}
                                  dan password{" "}
                                  <b>
                                    {this.state.data &&
                                      this.state.data.registration_number}
                                  </b>{" "}
                                  untuk login CBT.
                                </p>
                              </Col>
                            </Row>
                          </Card>
                        </div>
                      </React.Fragment>
                    </div> */}
                  </Col>
                )}
                <Col style={{ marginTop: 16 }}>
                  <Button
                    loading={this.state.isLoading}
                    key="login"
                    onClick={this.handleLogin}
                    disabled={!this.state.data}
                  >
                    Masuk Sebagai Peserta
                  </Button>
                </Col>
              </Row>
              <AddSchoolModal
                ref={this._modalManage}
                onUpdated={this.onUpdated}
              />
              <ReregistrationModal
                ref={this._modalReregistration}
                onUpdated={this.onUpdated}
                data={this.state.body}
              />
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default manageParticipants;
