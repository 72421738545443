import React from "react";
import { Card, Row, Col, Button, Input } from "antd";
import { SettingService } from "../../../../Service/setting.services";
import { toast } from "react-toastify";

class File extends React.Component {
  state = {
    data: this.props.data,
    document: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data)
        this.setState({
          isLoading: false,
          data: this.props.data,
          document: this.props.data.technical_document,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          document: "",
        });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    SettingService.editSetting({
      technical_document: this.state.document,
    }).then(
      (resp) => {
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      },
      (error) => {
        this.setState({ description: "", title: "" });
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };
  render() {
    return (
      <div>
        <Card
          style={{
            
            height: "100%",
            overflow: "hidden",
            marginTop: "2%",
          }}
        >
          <p className="font-medium-1 text-bold-700">
            Peraturan dan Petunjuk Teknis
          </p>
          <Row style={{ marginTop: 12 }} gutter={[4]}>
            <Col xs={24} sm={6} md={5} lg={5} style={{ marginLeft: "2%" }}>
              <p className="font-normal"> Dokumen Petunjuk Teknis </p>
            </Col>
            <Col xs={24} sm={17} md={18} lg={18} style={{display: "flex", alignItems:"center"}}>
              <Input
                type="text"
                placeholder="Masukkan link dokumen"
                onChange={(e) => this.setState({ document: e.target.value })}
                value={this.state.document}
                name="description"
              ></Input>
            </Col>
          </Row>
          <Button
            style={{ float: "right", marginTop: "2%" }}
            type="primary"
            onClick={this.handleSubmit}
          >
            Simpan
          </Button>
        </Card>
      </div>
    );
  }
}
export default File;
