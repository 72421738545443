import React from "react";

import { Card } from "antd";
import PaymentTable from "./PaymentTable";
import { toast } from "react-toastify";
import ManageModal from "./ManageModal";
class Payment extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }
  state = {
    reload: false,
    paymentStatus: [],
    payment: null,
  };

  toggleModalManage = (payment) => {
    if (payment) {
      this.setState({ payment: payment, reload: false });
    } else {
      this.setState({ payment: null, reload: false });
    }

    this._modalManage.current.toggleModal();
  };

  edit = (payment) => {
    this.toggleModalManage(payment);
  };

  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({ payment: null, reload: true });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ marginTop: 20 }}>
          <Card
            style={{
              
              height: "100%",
              overflow: "hidden",
            }}
          >
            <PaymentTable edit={this.edit} reload={this.state.reload} />
          </Card>
        </div>

        <ManageModal
          payment={this.state.payment}
          ref={this._modalManage}
          onUpdated={this.onUpdated}
        />
      </React.Fragment>
    );
  }
}

export default Payment;
