// import { authHeader } from '../_helpers';
import { mainConfig } from '../Config/mainConfig';
import { authHeader, handleResponse} from './auth.header';

export const categoryService = {
   getCategory,
   addCategory,
   editCategory,
   deleteCategory
};

function getCategory() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/line-category`, reqOpt).then(handleResponse)
}

function addCategory(data){
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/line-category`, reqOpt).then(handleResponse)
}
function editCategory(id, data){
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/line-category/${id}`, reqOpt).then(handleResponse)
}
function deleteCategory(id){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/line-category/${id}`, reqOpt).then(handleResponse)
}

