import React from "react";
import { Modal, Form, Input, Button, Row, Col } from "antd";
import { contactService } from "../../../../Service/contact.service";

class ManageModal extends React.Component {
  state = {
    data: this.props.category,
    isOpen: false,
    isLoading: false,
    name: "",
    content: "",
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  addContact = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.data) {
      contactService
        .editContact(this.state.data.id, {
          name: this.state.name,
          content: this.state.content,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              content: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              content: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      contactService
        .addContact({
          name: this.state.name,
          content: this.state.content,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              content: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              content: "",
            });
            this.props.onUpdated({
              isUpdated: false,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contact !== this.props.contact) {
      if (this.props.contact)
        this.setState({
          isLoading: false,
          data: this.props.contact,
          name: this.props.contact.name,
          content: this.props.contact.content,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          name: "",
          content: "",
        });
    }
  }

  render() {
    return (
      <Modal
        title={this.state.data ? "Edit Kontak" : "Tambah Kontak"}
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            loading={this.state.isLoading}
            key="submit"
            type="primary"
            onClick={(e) => this.addContact(e)}
          >
            {this.state.data ? "Edit Kontak" : "Tambah Kontak"}
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
          <Row style={{ marginTop: 16 }} gutter={[16]}>
            <Col xs={24} sm={5} md={5} lg={5}>
              <label>
                Nama <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Nama"
                type="text"
                value={this.state.name}
                id="name"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Col>
          </Row>

          <Row gutter={[12]} style={{ marginTop: 12 }}>
            <Col xs={24} sm={5} md={5} lg={5}>
              <label>
                Isi <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Isi"
                type="text"
                value={this.state.content}
                id="name"
                onChange={(e) => this.setState({ content: e.target.value })}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
