import React from "react";
import { Button, Row, Col } from "antd";

import "react-toastify/dist/ReactToastify.css";
import Banner from "./Banner/Index";
import Description from "./Description/Index";
import Phase from "./Phase/Index";
import WAGroup from "./WAGroup/Index";
import Category from "./Category/Category";
import Line from "./Line/Index";
import Document from "./Document/Index";
import Contact from "./Contact/Contact";
import PaymentSetting from "./Payment/PaymentSetting";
import ManageModal from "./ManageModal";
import ModalPpdb from "./ModalPpdb";
import { SettingService } from "../../../Service/setting.services";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalPpdb = React.createRef();
  }
  state = {
    data: [],
    isLoading: false,
  };
  showModal = () => {
    this._modalManage.current.toggleModal();
  };

  showModalOlimp = () => {
    this._modalPpdb.current.toggleModal();
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    SettingService.getSetting().then(
      (resp) => {
        if (resp.status) {
          this.setState({
            data: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ marginTop: 25 }}>
          <Banner data={this.state.data} isLoading={this.state.isLoading} />
        </div>
        <div style={{marginBottom: 25}}>
          <Description
            data={this.state.data}
            isLoading={this.state.isLoading}
          />
        </div>

        <Category />

        <Line />

        <Phase />

        <WAGroup />

        <div>
          <Document data={this.state.data} />
        </div>
        <Contact />
        <div>
        <div>
          <PaymentSetting
            data={this.state.data}
            isLoading={this.state.isLoading}
          />
        </div>
        </div>
        <Row justify="end" gutter={16} style={{ marginTop: 20 }}>
          {/* <Col xs={24} sm={18} md={18} lg={18}></Col> */}
          <Col>
            <Button
              type="primary"
              danger
              onClick={this.showModalOlimp}
            >
              Akhiri Sesi
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              // style={{ marginTop: "10%" }}
              onClick={(e) => this.showModal()}
            >
              Ganti Kata Sandi
            </Button>
          </Col>
        </Row>

        <ManageModal ref={this._modalManage} />
        <ModalPpdb ref={this._modalPpdb} />
        {/*  */}
      </React.Fragment>
    );
  }
}
export default Index;
