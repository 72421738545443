import { Card, Col, Row, Button, Input, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "../../font.css";
import { toast } from "react-toastify";
import { announcementService } from "../../../Service/announcement.service";
const { TextArea } = Input;

class Ringkasan extends React.Component {
  state = {
    title: "",
    content: "",
    totalParticipant: 0,
    totalParticipantAccepted: 0,
    totalFileWaiting: 0,
    totalFileVerified: 0,
    totalPaymentWaiting: 0,
    totalPaymentPaid: 0,
    reportDashboard: [],
    isLoading: true,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ showLoading: true });
    announcementService
      .addAnnouncement({ content: this.state.content, title: this.state.title })
      .then(
        (resp) => {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 999999999,
          });
          setTimeout(function () {
            window.location.assign("/profile/admin/manage-announcement");
          }, 3000);
          this.setState({ content: "", title: "" });
        },
        (error) => {
          this.setState({ content: "", title: "" });
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
        }
      );
  };

  componentDidMount() {
    announcementService.getReportDashboard().then(
      (resp) => {
        this.setState({
          isLoading: true,
        });
        if (resp.status) {
          this.setState({
            reportDashboard: resp.data,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    // participantService.getParticipant(1, 1, -1, "").then(
    //   (resp) => {
    //     if (resp.status) {
    //       this.setState({
    //         totalParticipant: resp.total,
    //       });
    //     }
    //   },
    //   (error) => {
    //     this.setState({ isLoading: false });
    //   }
    // );
    // scoreService.getScore(1, 1, "", -1, 1, -1, true).then(
    //   (resp) => {
    //     if (resp.status) {
    //       this.setState({
    //         totalParticipantAccepted: resp.total,
    //       });
    //     }
    //   },
    //   (error) => {
    //     this.setState({ isLoading: false });
    //   }
    // );
    // participantService.getListFile(1, 1, "waiting", "-1", "").then(
    //   (resp) => {
    //     if (resp.status) {
    //       this.setState({
    //         totalFileWaiting: resp.total,
    //       });
    //     }
    //   },
    //   (error) => {
    //     this.setState({ isLoading: false });
    //   }
    // );
    // participantService.getListFile(1, 1, "verified", "-1", "").then(
    //   (resp) => {
    //     if (resp.status) {
    //       this.setState({
    //         totalFileVerified: resp.total,
    //       });
    //     }
    //   },
    //   (error) => {
    //     this.setState({ isLoading: false });
    //   }
    // );
    // paymentService.getPayment(1, 1, "", "waiting", "", "").then(
    //   (resp) => {
    //     if (resp.status) {
    //       this.setState({
    //         totalPaymentWaiting: resp.total,
    //       });
    //     }
    //   },
    //   (error) => {
    //     this.setState({ isLoading: false });
    //   }
    // );
    // paymentService.getPayment(1, 1, "", "paid", "", "").then(
    //   (resp) => {
    //     if (resp.status) {
    //       this.setState({
    //         totalPaymentPaid: resp.total,
    //       });
    //     }
    //   },
    //   (error) => {
    //     this.setState({ isLoading: false });
    //   }
    // );
  }
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            backgroundColor: "white",
            overflow: "auto",
            whiteSpace: "nowrap",
            marginTop: 20,
            borderRadius: 5,
          }}
        >
          <p
            style={{
              marginTop: 20,
              fontSize: 18,
              paddingLeft: 15,
              fontWeight: 700,
            }}
          >
            Ringkasan Peserta
          </p>
          <Skeleton style={{padding: 15}} loading={this.state.isLoading} active />
          {this.state.reportDashboard?.participantSummary?.map((item) => (
            <div style={{ display: "inline-block", padding: "10px" }}>
              <Card
                style={{
                  
                  textAlign: "left",
                  height: "100%",
                  overflow: "hidden",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  marginTop: "3%",
                  width: 300,
                }}
                title={
                  <p style={{ textAlign: "center", overflow:"auto", paddingBottom: "10px"}}>
                    {`${item.name} (${item.line_category_name})`}
                  </p>
                }
              >
                <Row>
                  <Col span={12}>
                    <p
                      className="font-large-1 text-bold-700"
                      style={{ textAlign: "center" }}
                    >
                      {item.total_participant}
                    </p>
                    <p className="font-small" style={{ textAlign: "center" }}>
                      Terdaftar
                    </p>
                  </Col>
                  <Col span={12}>
                    <p
                      className="font-large-1 text-bold-700"
                      style={{ textAlign: "center" }}
                    >
                      {item.total_participant_passed}
                    </p>
                    <p className="font-small" style={{ textAlign: "center" }}>
                      Diterima
                    </p>
                  </Col>
                </Row>
              </Card>
            </div>
          ))}
        </div>
        <div
          style={{
            backgroundColor: "white",
            overflow: "auto",
            whiteSpace: "nowrap",
            marginTop: 20,
            borderRadius: 5,
          }}
        >
          <p
            style={{
              marginTop: 20,
              fontSize: 18,
              paddingLeft: 15,
              fontWeight: 700,
            }}
          >
            Ringkasan Berkas
          </p>
          <Skeleton style={{padding: 15}} loading={this.state.isLoading} active />
          {this.state.reportDashboard?.fileSummary?.map((item) => (
            <div style={{ display: "inline-block", padding: "10px" }}>
              <Card
                style={{
                  
                  textAlign: "left",
                  height: "100%",
                  overflow: "hidden",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  marginTop: "3%",
                  width: 300,
                }}
                title={
                  <p style={{ textAlign: "center", overflow:"auto", paddingBottom: "10px"}}>
                    {`${item.name} (${item.line_category_name})`}
                  </p>
                }
                loading={this.state.isLoading}
              >
                <Row>
                  <Col span={12}>
                    <p
                      className="font-large-1 text-bold-700"
                      style={{ textAlign: "center" }}
                    >
                      {item.file_status_waiting}
                    </p>
                    <p className="font-small" style={{ textAlign: "center" }}>
                      Menunggu
                    </p>
                  </Col>

                  <Col span={12}>
                    <p
                      className="font-large-1 text-bold-700"
                      style={{ textAlign: "center" }}
                    >
                      {item.file_status_verified}
                    </p>
                    <p className="font-small" style={{ textAlign: "center" }}>
                      Terverifikasi
                    </p>
                  </Col>
                </Row>
              </Card>
            </div>
          ))}
        </div>
        <div
          style={{
            backgroundColor: "white",
            overflow: "auto",
            whiteSpace: "nowrap",
            marginTop: 20,
            borderRadius: 5,
          }}
        >
          <p
            style={{
              marginTop: 20,
              fontSize: 18,
              paddingLeft: 15,
              fontWeight: 700,
            }}
          >
            Ringkasan Pembayaran
          </p>
          <Skeleton style={{padding: 15}} loading={this.state.isLoading} active />
          {this.state.reportDashboard?.paymentSummary?.map((item) => (
            <div style={{ display: "inline-block", padding: "10px" }}>
              <Card
                style={{
                  
                  textAlign: "left",
                  height: "100%",
                  overflow: "hidden",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  marginTop: "3%",
                  width: 300,
                }}
                title={
                  <p style={{ textAlign: "center", overflow:"auto", paddingBottom: "10px"}}>
                    {`${item.name} (${item.line_category_name})`}
                  </p>
                }
                loading={this.state.isLoading}
              >
                <Row>
                  <Col span={12}>
                    <p
                      className="font-large-1 text-bold-700"
                      style={{ textAlign: "center" }}
                    >
                      {item.total_payment_waiting}
                    </p>
                    <p className="font-small" style={{ textAlign: "center" }}>
                      Menunggu
                    </p>
                  </Col>
                  <Col span={12}>
                    <p
                      className="font-large-1 text-bold-700"
                      style={{ textAlign: "center" }}
                    >
                      {item.total_payment_paid}
                    </p>
                    <p className="font-small" style={{ textAlign: "center" }}>
                      Terverifikasi
                    </p>
                  </Col>
                </Row>
              </Card>
            </div>
          ))}
        </div>

        <div style={{ marginTop: 40 }}>
          <div>
            <Card
              style={{
                
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={16} sm={19} md={19} lg={19}>
                  <p
                    style={{
                      fontSize: 18,
                      paddingLeft: 15,
                      fontWeight: 700,
                    }}
                  >
                    Pengumuman
                  </p>
                </Col>
                <Col style={{textAlign: "right"}} xs={8} sm={5} md={5} lg={5}>
                  <Link to="/profile/admin/manage-announcement">
                    <Button type="link">Selengkapnya</Button>
                  </Link>
                </Col>
              </Row>

              <Input
                style={{ marginTop: "2%" }}
                onChange={(e) => this.setState({ title: e.target.value })}
                value={this.state.title}
              ></Input>
              <TextArea
                style={{ marginTop: "3%" }}
                autoSize={{ minRows: 11, maxRows: 10 }}
                onChange={(e) => this.setState({ content: e.target.value })}
                value={this.state.content}
              ></TextArea>
              <Button
                type="primary"
                style={{ marginTop: "2%", float: "right" }}
                onClick={this.handleSubmit}
              >
                Tambah Pengumuman
              </Button>
            </Card>
          </div>
        </div>
        {/* </Card> */}
        {/*  */}
      </React.Fragment>
    );
  }
}
export default Ringkasan;
