import React from "react";
import { Table, Empty } from "antd";

import "react-toastify/dist/ReactToastify.css";
import ActionSchedule from "./Action";
import { phaseService } from "../../../../Service/phase.services";

class ScheduleTable extends React.Component {
  phaseTypes = {
    registration: "Registrasi",
    administration: "Administrasi",
    test: "Tes",
    "re-registration": "Daftar Ulang"
  }
  state = {
    columns: [
      {
        title: "Tahapan",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Tipe",
        dataIndex: "type",
        key: "type",
        responsive: ["md"],
        render: (text) => {
          return (this.phaseTypes[text])
        }
      },
      {
        title: "Kategori Jalur",
        dataIndex: "line_category_name",
        key: "line_category_name",
        responsive: ["md"],
      },
      {
        title: "Tanggal Mulai",
        dataIndex: "start_date",
        key: "start_date",
      },
      {
        title: "Tanggal Selesai",
        dataIndex: "end_date",
        key: "end_date",
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => (
          <ActionSchedule
            schedule={row}
            edit={this.props.edit}
            delete={this.props.delete}
          />
        ),
      },
    ],
    data: [],
    page: 1,
    limit: 10,
  };
  componentDidMount() {
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    phaseService.getSchedule(this.state.page, this.state.limit).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            data: resp.data,
            pagination: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }
  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (prevProps.reload !== this.props.reload) {
      this.getData(false);
    }
  }
  render() {
    let { data, columns } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          locale={emptyData}
          scroll={{ x: true }}
        />
      </React.Fragment>
    );
  }
}
export default ScheduleTable;
