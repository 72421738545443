import React from 'react';
import ReactPDF, { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import CardTemplate from './CardTemplate';
import { Button } from 'antd';

class CardViewer extends React.Component {
  state = {
    isLoading: true
  }
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Button style={{ marginLeft: "auto", marginTop: "12px", marginBottom: "12px" }} type='primary' disabled={this.state.isLoading}>
          <PDFDownloadLink
            className='text-blue-500 underline'
            document={<CardTemplate  user_id={this.props?.location?.state?.user_id ?? -1}/>}
            fileName='Kartu Peserta PPDB.pdf'
          >
            {({ blob, url, loading, error }) => {
              if (this.state.isLoading != loading) {
                this.setState({
                  isLoading: loading
                });
              }
              return loading ? "Sedang diproses..." : "Unduh Kartu Peserta"
            }
            }
          </PDFDownloadLink>
        </Button>
        <PDFViewer style={{ height: "85vh" }}>
          <CardTemplate user_id={this.props?.location?.state?.user_id ?? -1} />
        </PDFViewer>
      </>
    );
  }
}

export default CardViewer;