import React from "react";

import { Row, Col, Button } from "antd";
import ManageModal from "./ManageModal";
import { toast } from "react-toastify";
import SchoolTable from "./SchoolTable";
import DeleteModal from "../../../../Component/Modal/DeleteModal";
import { participantService } from "../../../../Service/participant.service";

class School extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalDelete = React.createRef();
  }

  state = {
    school: null,
    reload: false,
    categories: [],
  };

  toggleModalManage = (school) => {
    if (school) {
      this.setState({ school: school, reload: false });
    } else {
      this.setState({ school: null, reload: false });
    }

    this._modalManage.current.toggleModal();
  };

  toggleModalDelete = () => {
    this._modalDelete.current.toggleModal();
  };

  edit = (school) => {
    this.toggleModalManage(school);
  };

  delete = (school) => {
    this.setState({ school: school, reload: false });
    this.toggleModalDelete();
  };
  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({ contact: null, reload: true });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };

  deleteParticipant = (id) => {
    participantService.deleteSchool(id).then(
      (resp) => {
        this.toggleModalDelete();
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
        this.setState({ school: null, reload: true });
      },
      (err) => {
        this.toggleModalDelete();
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Row style={{ marginTop: 16 }} gutter={[16]}>
          <Col xs={24} sm={24} md={19} lg={19}>
            <p className="font-medium-1 text-bold-700">Daftar Sekolah</p>
          </Col>
          <Col xs={24} sm={24} md={5} lg={5}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={(e) => this.toggleModalManage(null)}
            >
              Tambah Sekolah
            </Button>
          </Col>
        </Row>
        <SchoolTable
          reload={this.state.reload}
          edit={this.edit}
          delete={this.delete}
        />

        <ManageModal
          school={this.state.school}
          ref={this._modalManage}
          onUpdated={this.onUpdated}
        />

        <DeleteModal
          ref={this._modalDelete}
          id={this.state.school ? this.state.school.id : -1}
          name={this.state.school ? this.state.school.name : ""}
          onDeleted={this.deleteParticipant}
        />
      </React.Fragment>
    );
  }
}

export default School;
